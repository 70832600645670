import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Money from "./MoneyModify";
import Originator from "./OriginatorModify";
import Review from "./ReviewModify";

function Unipay() {
    const [step, setstep] = useState(1);
    const [token, setToken] = useState(1);
    const [value, setValue] = useState();
    let { pathname } = useLocation();
    const setdata = (data) => {
        setToken(data);
        setValue(data);
    };
    const cleandata = () => {
        setToken(1);
    };

    const nextstep = () => {
        if (step < 4) {
            setstep(step + 1);
        }
    };

    const renderstep = (step) => {
        switch (step) {
            case 1:
                return (
                    <Originator
                        nextstep={nextstep}
                        previous={previous}
                        setdata={setdata}
                        cleandata={cleandata}
                    />
                );
            case 2:
                return (
                    <Money
                        nextstep={nextstep}
                        previous={previous}
                        cleandata={cleandata}
                        setdata={setdata}
                        tokenData={token}
                        setValue={setValue}
                    />
                );
            case 3:
                return (
                    <Review
                        nextstep={nextstep}
                        previous={previous}
                        cleandata={cleandata}
                        setdata={setdata}
                        tokenData={token}
                        valueData={value}
                    />
                );
            default:
                return false;
        }
    };
    const previous = () => {
        if (step > 1) {
            setstep(step - 1);
        }
    };

    return (
        <>
            
                <div className="container-fluid p-0 ">
                <div className="row ms-lg-4 ms-xl-5">
                    <div className="col-12">
                        <div className="main-heading">
                            <h3>Tokens</h3>
                            <h6> {pathname === "/controlpanel/modifytoken"
                                ? "Modify Token"
                                : "Create Token"}</h6>
                            <p>
                                This function allows the updating of token descriptive information. 
                            </p>
                        </div>
                        <div className="deposit-funds-tabs m-0">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li
                                    className={
                                        step > 1
                                            ? "nav-item originator-mr-u originator-mr-t disableElement"
                                            : "nav-item originator-mr-u originator-mr-t"
                                    }
                                >
                                    <button
                                        className={step === 1 ? "nav-link active" : "nav-link"}
                                        id="profile-tab"
                                        type="button"
                                    >
                                        <div className="beneficiary">
                                            <h3>1</h3>
                                            <i className="bi bi-check-lg"></i>
                                        </div>
                                        <h6>Token Info</h6>
                                    </button>
                                </li>
                                <li
                                    className={
                                        step > 2
                                            ? "nav-item money-mr-t disableElement"
                                            : "nav-item money-mr-t "
                                    }
                                >
                                    <button
                                        className={step === 2 ? "nav-link active" : "nav-link"}
                                        id="contact-tab"
                                        type="button"
                                    >
                                        <div className="money">
                                            <h3>2</h3>
                                            <i className="bi bi-check-lg"></i>
                                        </div>
                                        <h6>Currencies</h6>
                                    </button>
                                </li>
                                <li
                                    className={
                                        step > 3 ? "nav-item  disableElement" : "nav-item  "
                                    }
                                >
                                    <button
                                        className={step === 3 ? "nav-link active" : "nav-link"}
                                        id="contact-tab"
                                        type="button"
                                    >
                                        <div className="money mony-last">
                                            <h3>3</h3>
                                            <i className="bi bi-check-lg"></i>
                                        </div>
                                        <h6>Review</h6>
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="home">
                                    {renderstep(step)}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                </div>
            
        </>
    );
}

export default Unipay;

import { Formik, Form, Field } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetCurrencycodesAPI } from "../../../../API/GetCurrencycodes";

function Money({ nextstep, setdata, tokenData, setToken, previous }) {
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const [GetCurrencycodes, setGetCurrencycodes] = useState([]);
  const publicKeys = useSelector((state) => state.keyReducer);

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      setisValidPublicAddress(true);
      fetchCurrencyCodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  const fetchCurrencyCodes = async () => {
    let LKTokenClass = await GetCurrencycodesAPI("LKTokenClass");
    setGetCurrencycodes(LKTokenClass);
  };

  return (
    <>
      {isValidPublicAddress && (
        <div className="row modify-token">
          <div className="col-xl-11 col-xxl-12">
            <div className="reject-page review-page ms-0 mt-5">
              <h3 className="mb-2">Currencies List</h3>
              <p>
                Select the national currencies that comprise this composite
                currency.
              </p>

              <div className="row">
                <div className="col-12">
                  <Formik
                    initialValues={{
                      flexCheckDefault: [],
                    }}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                      // debugger;
                      // let data = [tokenData, values];
                      // debugger;

                      setToken(values);

                      nextstep();
                    }}
                  >
                    {(formik) => {
                      return (
                        <Form>
                          <div className="review-box reject-box">
                            <div className="review-box-head reject-head">
                              <div className="modify-token-head mb-3 mb-sm-0">
                                <label>Currencies List</label>
                              </div>
                            </div>

                            <div className="row p-4">
                              {GetCurrencycodes &&
                                GetCurrencycodes.map((value, index) => {
                                  return (
                                      <div key={index} className="col-md-3">
                                        <div className="form-check">
                                          <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            id="flexCheckDefault"
                                            name="flexCheckDefault"
                                            key={index}
                                            value={
                                              value.keycode
                                              // " " +
                                              // value.displayname +
                                              // " " +
                                              // value.countrycode
                                            }
                                          />
                                          <label>
                                            {value.keycode} {value.displayname}{" "}
                                            ({value.countrycode})
                                          </label>
                                          <img
                                            alt=""
                                            // src={`../icons/flags/${value?.imageFN}`}
                                            src={`../public/icons/flags/+${value?.imageFN}`}
                                          />
                                        </div>
                                      </div>
                                  );
                                })}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12 p-0">
                              <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                                <button
                                  type="button"
                                  className="btn cancel-btn mb-3 mb-md-0 me-4"
                                  onClick={() => {
                                    previous();
                                  }}
                                >
                                  PREVIOUS
                                </button>
                                <button
                                  type="submit"
                                  className="btn next-btn mb-3 mb-md-0"
                                >
                                  NEXT
                                </button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Money;

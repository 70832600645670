import React from "react";

const Failure = ({ resultDatas }) => {
  /* console.log("Failuree====", resultDatas); */
  const initialValues = {
    Transaction: resultDatas.resultReducer.data.TransactionHash,
    NodeNetwork: resultDatas.EncryptDataReducer.data.config.baseURL,
    EndPoint: resultDatas.EncryptDataReducer.data.config.url,
    NetworkResponseCode: resultDatas.EncryptDataReducer.data.status,
    NetworkResponseMessage: resultDatas.EncryptDataReducer.data.status,
    CreationDate: resultDatas.resultReducer.data.CreationDate,
    ElapsedTime: resultDatas.resultReducer.data.ElapsedTime,
    PublicAddress: resultDatas.keyReducer.publicKey.publicAddress
      ? resultDatas.keyReducer.publicKey.publicAddress
      : "NULL",
    PrivateKey: resultDatas.keyReducer.privateKey.PrivateWalletAddress
      ? resultDatas.keyReducer.privateKey.PrivateWalletAddress
      : "NULL",
    ReturnMessage: resultDatas.resultReducer.data.ReturnMessage,
    ReturnKeycode: resultDatas.resultReducer.data.ReturnKeycode,
    ReturnCode: resultDatas.resultReducer.data.Returncode,
  };
/*   console.log("🚀 ~ file: Failure.js ~ line 23 ~ Failure ~ initialValues", initialValues) */
  return (
    
          <div className="col-xl-11">
            
      <div className="review-wallet-box deposit-funds-tabs ">
        <h6 className="my-5">Transaction Result</h6>
            <div className="review-wallet-info-box mb-5">
              <div className="row g-0">
                <div className="col-12">
                  <div className="heading">
                    <div className="money-heading">
                      <h3>
                        Transaction
                        <p>{initialValues?.Transaction}</p>
                      </h3>
                    </div>
                    {/* <i className="bi bi-pencil-square"></i> */}
                  </div>
                  <hr className="mb-4" />
                </div>
                <div className="col-12">
                  {initialValues?.NodeNetwork && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Node Network
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                            {
                              initialValues.NodeNetwork
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {initialValues?.EndPoint && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>End Point
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                            {initialValues?.EndPoint}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {initialValues?.NetworkResponseCode && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Network Response
                            Code
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>{initialValues?.NetworkResponseCode}</p>
                        </div>
                      </div>
                    </div>
                  )}
                  {initialValues?.NetworkResponseMessage && (
                    <div className="row mb-3">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Network Response
                            Message
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          {/* {resultDatas.resultReducer.data.returnMessage} */}
                          {initialValues?.NetworkResponseMessage ===
                            511 && <p>Network Authentication Required</p>}
                          {initialValues?.NetworkResponseMessage ===
                            401 && <p>Authentication Failure</p>}
                          {initialValues?.NetworkResponseMessage ===
                            403 && <p>Authorization Failure</p>}
                          {initialValues?.NetworkResponseMessage ===
                            404 && <p>Not Found</p>}
                          {initialValues?.NetworkResponseMessage ===
                            400 && <p>Bad Request</p>}
                          {initialValues?.NetworkResponseMessage ===
                            500 && <p>Unknown Error </p>}
                        </div>
                      </div>
                      {/* <hr className="mt-4" /> */}
                    </div>
                  )}
                  {initialValues?.CreationDate && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Creation Date
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                            {/* {resultDatas.EncryptDataReducer.data?.data.Returncode} */}
                            {initialValues?.CreationDate}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {initialValues?.ElapsedTime && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Elapsed Time
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                            {/* {resultDatas.EncryptDataReducer.data?.data.Returncode} */}
                            {initialValues?.ElapsedTime}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                      <hr className="mt-4" />
                </div>
                <div className="col-12">
                  {initialValues?.PublicAddress && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Public Address
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                            {initialValues?.PublicAddress}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  {initialValues?.ReturnMessage && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Return Message
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                            {/* {resultDatas.EncryptDataReducer.data?.data.ReturnMessage} */}
                            {initialValues?.ReturnMessage}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {initialValues?.ReturnKeycode && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Return Keycode
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                            {/* {resultDatas.EncryptDataReducer.data?.data.ReturnKeycode} */}
                            {initialValues?.ReturnKeycode}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {initialValues?.ReturnCode && (
                    <div className="row mb-3">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Return Code
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                            {/* {resultDatas.EncryptDataReducer.data?.data.Returncode} */}
                            {initialValues?.ReturnCode}
                          </p>
                        </div>
                      </div>
                      {/* <hr className="mt-4" /> */}
                    </div>
                  )}
                
                </div>
              </div>
            </div>
          </div>
    </div>
  );
};

export default Failure;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useSelector } from "react-redux";
// import * as Yup from "yup";
import {
  getCountryCodeDropDown,
  getDailingDropDown,
  getDropdownvalues,
} from "../../../../API/dropdown";
import { getIssuer } from "../../../../API/Issures";
import { decrypytdata } from "../../../../API/walletkey";

function ModifyIssuer({ nextstep, setValue, IssuerData }) {
  // const params = useParams()
  // const Location = useLocation()
  // console.log(Location.search, "params.id");

  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const [institutionTypeCode, setInstitutionTypeCode] = useState([]);
  const [officeTypeCode, setOfficeTypeCode] = useState([]);
  const [countryCode, setCountryCode] = useState([]);
  const [dailingCode, setDailingCode] = useState([]);
  // const [privateModal, setPrivateModal] = useState(false);
  const location = useLocation();

  const find = location.search.replace("?id=", "");

  const [initialValues, setInitialValues] = useState({
    accuityID: 0,
    postalcode: "",
    issuer: "",
    nativeKEY: "",
    displayname: "",
    issuerUUID: "",
    institutionTypecode: "",
    officeTypecode: "",
    countrycode: "",
    stateProvince: "",
    cityTown: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    neighborhood: "",
    incorporationNumber: "",
    federal_TaxID: "",
    isO_LEI: "",
    dialingcode: "",
    phoneNumber: "",
    issuercode: "",
    hqIssuercode: "",
  });

  const publicKeys = useSelector((state) => state.keyReducer);
  let APPID = "";
  let APIKEY = "";
  // useEffect(() => {
  //   if (publicKeys?.publicKey?.publicAddress) {
  //     if (!publicKeys?.privateKey?.PrivateWalletAddress) setPrivateModal(true);
  //   }
  //   fetchdata();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [publicKeys, privateModal]);
  const fetchdata = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
      /* console.log(
        "🚀 ~ file: ModifyIssuer.js ~ line 47 ~ fetchdata ~ APIKEY",
        APIKEY
      ); */
    }

    if (APPID && APIKEY) {
      const encryptedIssuer = await getIssuer(APPID, APIKEY);
      const Issuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      /* console.log(
        "🚀 ~ file: ModifyIssuer.js ~ line 55 ~ fetchdata ~ Issuer",
        Issuer
      ); */
      // console.log("🚀 ~ file: ModifyIssuer.js ~ line 70 ~ fetchdata ~ Issuer", Issuer)
      let data = JSON.parse(JSON.parse(Issuer).CommandResponse);
      /* console.log(data, "datadata"); */
      data.forEach((dataMain) => {
        /* console.log(
          "🚀 ~ file: ModifyIssuer.js ~ line 56 ~ data.forEach ~ dataMain",
          dataMain
        ); */
        // console.log("🚀 ~ file: ModifyIssuer.js ~ line 75 ~ fetchdata ~ dataMain", dataMain.PostalCode)

        if (dataMain.AccuityID === Number(find)) {
          return setInitialValues({
            accuityID: dataMain.AccuityID,
            postalcode: dataMain.PostalCode,
            issuer: dataMain?.Issuer ? dataMain.Issuer : "",
            nativeKEY: dataMain?.NativeKEY ? dataMain.NativeKEY : "",
            displayname: dataMain?.Displayname ? dataMain?.Displayname : "",
            issuerUUID: dataMain?.issuerUUID ? dataMain?.issuerUUID : "",
            institutionTypecode: dataMain?.InstitutionTypecode
              ? dataMain?.InstitutionTypecode
              : "",
            officeTypecode: dataMain?.OfficeTypecode
              ? dataMain?.OfficeTypecode
              : "",
            countrycode: dataMain?.Countrycode ? dataMain?.Countrycode : "",
            stateProvince: dataMain?.StateProvince
              ? dataMain?.StateProvince
              : "",
            cityTown: dataMain?.CityTown ? dataMain?.CityTown : "",
            addressLine1: dataMain?.AddressLine1 ? dataMain.AddressLine1 : "",
            addressLine2: dataMain?.AddressLine2 ? dataMain.AddressLine2 : "",
            addressLine3: dataMain?.AddressLine3 ? dataMain.AddressLine3 : "",
            neighborhood: dataMain?.Neighborhood ? dataMain.Neighborhood : "",
            incorporationNumber: dataMain?.IncorporationNumber
              ? dataMain?.IncorporationNumber
              : "",
            federal_TaxID: dataMain?.Federal_TaxID
              ? dataMain?.Federal_TaxID
              : "",
            isO_LEI: dataMain?.ISO_LEI ? dataMain?.ISO_LEI : "",
            dialingcode: dataMain?.Dialingcode ? dataMain?.Dialingcode : "",
            phoneNumber: dataMain?.PhoneNumber ? dataMain?.PhoneNumber : "",
            issuercode: dataMain?.Issuercode ? dataMain?.Issuercode : "",
            hqIssuercode: dataMain?.Issuercode ? dataMain?.Issuercode : "",
          });
        }
      });
    }
  };
  /* console.log(initialValues, "initialValuesinitialValuesinitialValues"); */
  // const validationSchema = Yup.object({
  //   issuer: Yup.string()
  //     .max(15, "Must be 15 characters or less")
  //     .required("Issuer required"),
  //   issuercode: Yup.string().required("IssuerCode is required!"),
  //   displayname: Yup.string()
  //     .max(20, "Must be 20 characters or less")
  //     .required("Displayname Required"),
  //   accuityID: Yup.number(),
  //   institutionTypecode: Yup.string().required(
  //     "InstitutionTypecode is required!"
  //   ),
  //   countrycode: Yup.string().required("CountryCode is required!"),
  //   stateProvince: Yup.string(),
  //   cityTown: Yup.string(),
  //   postalCode: Yup.string(),
  //   officeTypecode: Yup.string().required("officeTypecode is required!"),
  //   // nativeKEY: Yup.string(),
  //   // issuerUUID: Yup.string(),
  //   addressLine1: Yup.string(),
  //   addressLine2: Yup.string(),
  //   // addressLine3: Yup.string(),
  //   neighborhood: Yup.string(),
  //   dialingcode: Yup.string()
  //     .typeError("you must specify a number")
  //     .required("DialingCode is required!"),
  //   phoneNumber: Yup.number()
  //     .typeError("you must specify a number")
  //     .max(9999999999, "Must be 10 Digit")
  //     .min(1111111111, "Must be 10 Digit")
  //     .required("PhoneNumber is required!"),
  //   incorporationNumber: Yup.string(),
  //   federal_TaxID: Yup.string(),
  //   isO_LEI: Yup.string(),
  // });
  const fetchdropdwon = async () => {
    let insTypeCode = await getDropdownvalues("LKInstitutionType");
    setInstitutionTypeCode(insTypeCode.commandDataset);

    let OfficeTypeCode = await getDropdownvalues("LKOfficeType");
    setOfficeTypeCode(OfficeTypeCode.commandDataset);

    let countryType = await getCountryCodeDropDown("GetWorldCurrency");
    setCountryCode(countryType);

    let dialing = await getDailingDropDown("GetDialingcodes");
    setDailingCode(dialing);
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
        fetchdropdwon();

        fetchdata();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);
  // const initialValues1 = {
  //   accuityID: 0,
  //   issuercode: "EkDo",
  //   hqIssuercode: "EkDo",
  //   issuerTypecode: "",
  //   issuer: "EkDoCor",
  //   displayname: "EkDoCor",
  //   institutionTypecode: "",
  //   officeTypecode: "",
  //   nativeKEY: "BBDK",
  //   issuerUUID: "ISSUERuid8",
  //   incorporationNumber: "7777",
  //   federal_TaxID: "AB18",
  //   isO_LEI: "DAK",
  //   addressLine1: "260 SHWETA TOWN",
  //   addressLine2: "BH CHURCH Road",
  //   addressLine3: "Road no 4",
  //   neighborhood: "BEGU",
  //   cityTown: "SAURASTRA",
  //   stateProvince: "Gujarat",
  //   postalCode: "395052",
  //   countrycode: "",
  //   currencycode: "",
  //   timezoneID: "",
  //   username: "EkDo1",
  //   userUUID: "USERuid9",
  //   firstname: "EkDo2",
  //   secondFirstname: "EKD",
  //   lastname: "Corpo",
  //   secondLastname: "CO",
  //   jobTitle: "CA",
  //   email: "EkDo@GMAIL.COM",
  //   mobileDialingcode: "",
  //   mobilePhone: "9422816792",
  //   imageFN: "stk",
  // };
  return (
    <>
      {isValidPublicAddress && (
        <div className="ledger-information create-issuer-information">
          <div className="row mt-4">
            <div className="col-12">
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                // validationSchema={validationSchema}
                onSubmit={(values) => {
                  /* console.log(values, "values========="); */
                  setValue(values);
                  nextstep();
                  // debugger;
                  // CreateIssuersApi(values);
                }}
              >
                {(formik) => {
                  const { errors, touched, setFieldValue } = formik;
                  return (
                    <div className="wallet-information-box m-0">
                      <h4>Issuer Information</h4>

                      <Form className="wallet-information-form">
                        <div className="row">
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Basic Information</h6>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="issuer"
                                  id="issuer"
                                  placeholder="Issuer"
                                  className="form-control"
                                // value={initialValues.issuer}
                                />
                                <label className="ps-0 pb-0">Issuer</label>
                                <div
                                  className={
                                    errors.issuer && touched.issuer
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="issuer"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="displayname"
                                  id="displayname"
                                  placeholder="Display Name"
                                  className="form-control"
                                // value={initialValues.displayname}
                                />
                                <label className="ps-0 pb-0">
                                  Display Name
                                </label>
                                <div
                                  className={
                                    errors.displayname && touched.displayname
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="displayname"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <div className="mb-5">
                                                            <label>Issuer Type</label>
                                                            <Field
                                                                as="select"
                                                                className="form-select form-control"
                                                                id="issuerTypecode"
                                                                name="issuerTypecode"
                                                                onChange={(e) => {
                                                                    console.log(e.target.value, "Issuer Type");
                                                                    setFieldValue(
                                                                        "issuerTypecode",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                onBlur={formik.handleBlur}
                                                            >
                                                                {dropdown &&
                                                                    dropdown.map((value, index) => {
                                                                        return (
                                                                            <>
                                                                                <option
                                                                                    value={value.keycode}
                                                                                    key={index}
                                                                                >
                                                                                    {value.displayName}
                                                                                </option>
                                                                            </>
                                                                        );
                                                                    })}
                                                            </Field>
                                                            <div>
                                                                <ErrorMessage
                                                                    name="issuerTypecode"
                                                                    component="span"
                                                                    className="error"
                                                                    style={{ color: "red" }}
                                                                />
                                                            </div>
                                                        </div> */}
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="issuercode"
                                  id="issuercode"
                                  placeholder="Issuer Code"
                                  className="form-control"
                                // value={initialValues.issuercode}
                                />
                                <label className="ps-0 pb-0">Issuer Code</label>
                                <div
                                  className={
                                    errors.issuercode && touched.issuercode
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="issuercode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Institution </h6>
                            <div className="mb-5">
                              <label>Institution Typecode</label>
                              <div className="input-box">
                                {/* <Field
                                  type="text"
                                  name="institutionTypecode"
                                  id="institutionTypecode"
                                  placeholder="Institution Typecode"
                                  className="form-control"
                                  value={initialValues.institutionTypecode}
                                > */}
                                <Field
                                  as="select"
                                  className="form-select form-control py-2"
                                  id="institutionTypecode"
                                  name="institutionTypecode"
                                  onChange={(e) => {
                                    /* console.log(
                                      e.target.value,
                                      "Institution Typecode"
                                    ); */
                                    setFieldValue(
                                      "institutionTypecode",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                >
                                  {institutionTypeCode &&
                                    institutionTypeCode.map((value, index) => {
                                      return (
                                        <option
                                          value={value.Keycode}
                                          key={index}
                                        >
                                          {value.Displayname}
                                        </option>
                                      );
                                    })}
                                </Field>
                                <div
                                  className={
                                    errors.institutionTypecode &&
                                      touched.institutionTypecode
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <ErrorMessage
                                    name="institutionTypecode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <div className="mb-5">
                                                            <label>Department Typecode</label>
                                                            <div className="input-box">
                                                                <Field
                                                                    type="text"
                                                                    name="departmentTypecode"
                                                                    id="departmentTypecode"
                                                                    placeholder="Department Typecode"
                                                                    className="form-control"
                                                                // value={initialValues.departmentTypecode}
                                                                />
                                                                <div
                                                                    className={
                                                                        errors.departmentTypecode &&
                                                                            touched.departmentTypecode
                                                                            ? "input-error"
                                                                            : null
                                                                    }
                                                                >
                                                                    <ErrorMessage
                                                                        name="departmentTypecode"
                                                                        component="span"
                                                                        className="error"
                                                                        style={{ color: "red" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> */}
                            <div className="mb-5">
                              <div className="input-box">
                              <label className="ps-0 pb-0">
                                    Office Typecode
                                  </label>
                                <Field
                                  as="select"
                                  name="officeTypecode"
                                  id="officeTypecode"
                                  // placeholder="Office Typecode"
                                  className="form-control form-select py-2"
                                  onChange={(e) => {
                                    /* console.log(
                                      e.target.value,
                                      "Office Typecode"
                                    ); */
                                    setFieldValue(
                                      "officeTypecode",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                // value={initialValues.officeTypecode}
                                >
                                  {officeTypeCode &&
                                    officeTypeCode.map((value, index) => {
                                      return (
                                        <option
                                          value={value.Keycode}
                                          key={index}
                                        >
                                          {value.Displayname}
                                        </option>
                                      );
                                    })}
                                </Field>
                                <div
                                  className={
                                    errors.officeTypecode &&
                                      touched.officeTypecode
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <ErrorMessage
                                    name="officeTypecode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Identifier</h6>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="accuityID"
                                  id="accuityID"
                                  placeholder="Location Identifier"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Location Identifier
                                </label>
                                <i className="bi bi-check-lg"></i>
                                <div
                                  className={
                                    errors.accuityID && touched.accuityID
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <ErrorMessage
                                    name="accuityID"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <div className="mb-5">
                              <label>Native Identifier</label>
                              <div className="input-box">
                                <Field
                                  type="text"
                                  name="NativeIdentifier"
                                  id="NativeIdentifier"
                                  placeholder="Native Identifier"
                                  className="form-control"
                                />
                                <div
                                  className={
                                    errors.NativeIdentifier &&
                                    touched.NativeIdentifier
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="NativeIdentifier"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div> */}
                            {/* <div className="mb-5">
                                                            <label>UUID </label>
                                                            <div className="input-box">
                                                                <Field
                                                                    type="text"
                                                                    name="uuid"
                                                                    id="uuid"
                                                                    placeholder="UU ID"
                                                                    className="form-control"
                                                                // value={initialValues.uuid}
                                                                />
                                                                <i className="bi bi-check-lg"></i>
                                                                <div
                                                                    className={
                                                                        errors.uuid && touched.uuid
                                                                            ? "input-error"
                                                                            : null
                                                                    }
                                                                >
                                                                    <ErrorMessage
                                                                        name="uuid"
                                                                        component="span"
                                                                        className="error"
                                                                        style={{ color: "red" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> */}
                          </div>

                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Additional Informaiton</h6>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="incorporationNumber"
                                  id="incorporationNumber"
                                  placeholder="Incorporation Number"
                                  className="form-control"
                                // value={initialValues.incorporationNumber}
                                />
                                <label className="ps-0 pb-0">
                                  Incorporation Number{" "}
                                </label>
                                <div
                                  className={
                                    errors.incorporationNumber &&
                                      touched.incorporationNumber
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="incorporationNumber"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="federal_TaxID"
                                  id="federal_TaxID"
                                  placeholder="Tax ID"
                                  className="form-control"
                                // value={initialValues.federal_TaxID}
                                />
                                <label className="ps-0 pb-0">
                                  Federal TaxID{" "}
                                </label>
                                <div
                                  className={
                                    errors.federal_TaxID &&
                                      touched.federal_TaxID
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="federal_TaxID"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="isO_LEI"
                                  id="isO_LEI"
                                  placeholder="ISO LEI"
                                  className="form-control"
                                // value={initialValues.isO_LEI}
                                />
                                <label className="ps-0 pb-0">ISO LEI </label>
                                <div
                                  className={
                                    errors.isO_LEI && touched.isO_LEI
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="isO_LEI"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Basic Address Information</h6>
                            <div className="mb-5">
                              <label>Country Code</label>
                              <Field
                                as="select"
                                className="form-select form-control py-2"
                                id="countrycode"
                                name="countrycode"
                                onChange={(e) => {
                                  /* console.log(e.target.value, "Country Code"); */
                                  setFieldValue("countrycode", e.target.value);
                                }}
                                onBlur={formik.handleBlur}
                              >
                                {countryCode &&
                                  countryCode.map((value, index) => {
                                    return (
                                        <option
                                          value={value.countrycode}
                                          key={index}
                                        >
                                          {value.isoCountryname}
                                        </option>
                                    );
                                  })}
                              </Field>
                              <div>
                                <ErrorMessage
                                  name="countrycode"
                                  component="span"
                                  className="error"
                                  style={{ color: "red" }}
                                />
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="stateProvince"
                                  id="stateProvince"
                                  placeholder="State Province"
                                  className="form-control"
                                // value={initialValues.stateProvince}
                                />
                                <label className="ps-0 pb-0">
                                  State Province{" "}
                                </label>
                                <div
                                  className={
                                    errors.stateProvince &&
                                      touched.stateProvince
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="stateProvince"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="cityTown"
                                  id="cityTown"
                                  placeholder="City Town"
                                  className="form-control"
                                // value={initialValues.cityTown}
                                />
                                <label className="ps-0 pb-0">City Town </label>
                                <div
                                  className={
                                    errors.cityTown && touched.cityTown
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="cityTown"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Address</h6>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="postalcode"
                                  id="postalcode"
                                  placeholder="Postal code"
                                  className="form-control"
                                // value={initialValues.postalcode}
                                />
                                <label className="ps-0 pb-0">Postalcode</label>
                                <div
                                  className={
                                    errors.postalcode && touched.postalcode
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="postalcode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="addressLine1"
                                  id="addressLine1"
                                  placeholder="Address Line1"
                                  className="form-control"
                                // value={initialValues.addressLine1}
                                />
                                <label className="ps-0 pb-0">
                                  AddressLine1{" "}
                                </label>
                                <div
                                  className={
                                    errors.addressLine1 && touched.addressLine1
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="addressLine1"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="addressLine2"
                                  id="addressLine2"
                                  placeholder="Address Line2"
                                  className="form-control"
                                // value={initialValues.addressLine2}
                                />
                                <label className="ps-0 pb-0">
                                  AddressLine2{" "}
                                </label>
                                <div
                                  className={
                                    errors.addressLine2 && touched.addressLine2
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="addressLine2"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h4>Primary Contact Information</h4>
                        <div className="row mb-xl-5">
                          {/* <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                        <h6>Primary Contact</h6>
                                                        <div className="mb-5">
                                                            <label>First Name</label>
                                                            <div className="input-box">
                                                                <Field
                                                                    type="text"
                                                                    name="firstname"
                                                                    id="firstname"
                                                                    placeholder="First Name"
                                                                    className="form-control"
                                                                />
                                                                <div
                                                                    className={
                                                                        errors.firstname && touched.firstname
                                                                            ? "input-error"
                                                                            : null
                                                                    }
                                                                >
                                                                    <i className="bi bi-check-lg"></i>
                                                                    <ErrorMessage
                                                                        name="firstName"
                                                                        component="span"
                                                                        className="error"
                                                                        style={{ color: "red" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <label>Middle Name</label>
                                                            <div className="input-box">
                                                                <Field
                                                                    type="text"
                                                                    name="secondFirstname"
                                                                    id="secondFirstname"
                                                                    placeholder="Middle Name"
                                                                    className="form-control"
                                                                />
                                                                <div
                                                                    className={
                                                                        errors.secondFirstname &&
                                                                            touched.secondFirstname
                                                                            ? "input-error"
                                                                            : null
                                                                    }
                                                                >
                                                                    <i className="bi bi-check-lg"></i>
                                                                    <ErrorMessage
                                                                        name="secondFirstname"
                                                                        component="span"
                                                                        className="error"
                                                                        style={{ color: "red" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <label>Last Name</label>
                                                            <div className="input-box">
                                                                <Field
                                                                    type="text"
                                                                    name="lastname"
                                                                    id="lastname"
                                                                    placeholder="Last Name"
                                                                    className="form-control"
                                                                />
                                                                <div
                                                                    className={
                                                                        errors.lastname && touched.lastname
                                                                            ? "input-error"
                                                                            : null
                                                                    }
                                                                >
                                                                    <i className="bi bi-check-lg"></i>
                                                                    <ErrorMessage
                                                                        name="lastName"
                                                                        component="span"
                                                                        className="error"
                                                                        style={{ color: "red" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <label>Second Last Name</label>
                                                            <div className="input-box">
                                                                <Field
                                                                    type="text"
                                                                    name="secondLastname"
                                                                    id="secondLastname"
                                                                    placeholder="Second Last Name"
                                                                    className="form-control"
                                                                />
                                                                <div
                                                                    className={
                                                                        errors.secondLastname &&
                                                                            touched.secondLastname
                                                                            ? "input-error"
                                                                            : null
                                                                    }
                                                                >
                                                                    <i className="bi bi-check-lg"></i>
                                                                    <ErrorMessage
                                                                        name="secondLastname"
                                                                        component="span"
                                                                        className="error"
                                                                        style={{ color: "red" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Contact Information</h6>
                            <div className="mb-5">
                              <label>Dialing Code</label>
                              <div className="input-box">
                                <Field
                                  as="select"
                                  name="dialingcode"
                                  id="dialingcode"
                                  className="form-select form-control py-2"
                                  onChange={(e) => {
                                    /* console.log(e.target.value, "Dialing Code"); */
                                    setFieldValue(
                                      "dialingcode",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                >
                                  {dailingCode &&
                                    dailingCode.map((value, index) => {
                                      return (
                                          <option
                                            value={value.keycode}
                                            key={index}
                                          >
                                            {value.displayname}
                                          </option>
                                      );
                                    })}
                                </Field>
                                {/* <i className="bi bi-check-lg"></i> */}
                                <div
                                  className={
                                    errors.dialingcode && touched.dialingcode
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <ErrorMessage
                                    name="dialingcode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="phoneNumber"
                                  id="phoneNumber"
                                  placeholder="Phone Number"
                                  className="form-control"
                                // value={initialValues.phoneNumber}
                                />
                                <label className="ps-0 pb-0">
                                  Phone Number
                                </label>
                                <div
                                  className={
                                    errors.phoneNumber && touched.phoneNumber
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="phoneNumber"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <div className="mb-5">
                                                            <label>Extension </label>
                                                            <div className="input-box">
                                                                <Field
                                                                    type="text"
                                                                    name="extension"
                                                                    id="extension"
                                                                    placeholder="Extension"
                                                                    className="form-control"
                                                                // value={initialValues.extension}
                                                                />
                                                                <i className="bi bi-check-lg"></i>
                                                                <div
                                                                    className={
                                                                        errors.extension && touched.extension
                                                                            ? "input-error"
                                                                            : null
                                                                    }
                                                                >
                                                                    <ErrorMessage
                                                                        name="extension"
                                                                        component="span"
                                                                        className="error"
                                                                        style={{ color: "red" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> */}
                            {/* <div className="mb-5">
                                                            <label>Email Address </label>
                                                            <div className="input-box">
                                                                <Field
                                                                    type="text"
                                                                    name="email"
                                                                    id="email"
                                                                    placeholder="Email Address"
                                                                    className="form-control"
                                                                />
                                                                <i className="bi bi-check-lg"></i>
                                                                <div
                                                                    className={
                                                                        errors.email && touched.email
                                                                            ? "input-error"
                                                                            : null
                                                                    }
                                                                >
                                                                    <ErrorMessage
                                                                        name="email"
                                                                        component="span"
                                                                        className="error"
                                                                        style={{ color: "red" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> */}
                          </div>
                          {/* <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                        <h6>Primary Contact Account Information</h6>
                                                        <div className="mb-5">
                                                            <label>Job Title</label>
                                                            <div className="input-box">
                                                                <Field
                                                                    type="text"
                                                                    name="jobTitle"
                                                                    id="jobTitle"
                                                                    placeholder="Job Title"
                                                                    className="form-control"
                                                                />
                                                                <div
                                                                    className={
                                                                        errors.jobTitle && touched.jobTitle
                                                                            ? "input-error"
                                                                            : null
                                                                    }
                                                                >
                                                                    <i className="bi bi-check-lg"></i>
                                                                    <ErrorMessage
                                                                        name="jobTitle"
                                                                        component="span"
                                                                        className="error"
                                                                        style={{ color: "red" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <label>Username</label>
                                                            <div className="input-box">
                                                                <Field
                                                                    type="text"
                                                                    name="username"
                                                                    id="username"
                                                                    placeholder="Username"
                                                                    className="form-control"
                                                                />
                                                                <div
                                                                    className={
                                                                        errors.username && touched.username
                                                                            ? "input-error"
                                                                            : null
                                                                    }
                                                                >
                                                                    <i className="bi bi-check-lg"></i>
                                                                    <ErrorMessage
                                                                        name="username"
                                                                        component="span"
                                                                        className="error"
                                                                        style={{ color: "red" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <label>Password</label>
                                                            <div className="input-box">
                                                                <Field
                                                                    type="Password"
                                                                    name="password"
                                                                    id="password"
                                                                    placeholder="Password"
                                                                    className="form-control"
                                                                />
                                                                <div
                                                                    className={
                                                                        errors.password && touched.password
                                                                            ? "input-error"
                                                                            : null
                                                                    }
                                                                >
                                                                    <i className="bi bi-check-lg"></i>
                                                                    <ErrorMessage
                                                                        name="password"
                                                                        component="span"
                                                                        className="error"
                                                                        style={{ color: "red" }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                        </div>
                        <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5">
                          <button
                            type="button"
                            className="btn cancel-btn mb-3 mb-md-0 me-4"
                          >
                            CANCEL
                          </button>
                          <button
                            type="submit"
                            className="btn next-btn mb-3 mb-md-0"
                          >
                            NEXT
                          </button>
                        </div>
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ModifyIssuer;

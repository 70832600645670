import React, { useState } from "react";
import {
  setPrivateId,
  setResponseAPI,
  setResult,
  showLoader,
} from "../../../../../action/index";
import { useSelector, useDispatch } from "react-redux";
import { CreateTokenApi } from "../../../../../API/Token";
import { decrypytdata } from "../../../../../API/walletkey";
// import PrivateModal from "./../../../../../Components/PrivateModal";
import { success } from "./../../../../../Components/alert";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function Review({ tokenData, valueData, previous }) {
  // console.log("🚀 ~ file: Review.js ~ line 15 ~ Review ~ valueData",Object.keys(valueData?.flexCheckDefault).forEach((key)=>{
  //   console.log("fdssdfsdfsdf=-=-==-=->", key.includes("keycode") && key.includes("imageFN"), valueData.flexCheckDefault[key].keycode ,valueData.flexCheckDefault[key].imageFN);
  //   keycode.push(valueData.flexCheckDefault[key].keycode)
  //   image.push(valueData.flexCheckDefault[key].imageFN)
  // }) )
  const [keycodeData, setkeycodeData] = useState([])
  const [imageFNData, setimageFNData] = useState([])

  useEffect(() => {
    let keycode = [];
    let image = [];
    Object.keys(valueData?.flexCheckDefault).forEach((key) => {
      key.includes("keycode") && key.includes("imageFN");
      keycode.push(valueData.flexCheckDefault[key].keycode);
      image.push(valueData.flexCheckDefault[key].imageFN);
      setkeycodeData(keycode)
      setimageFNData(image)
    });
  }, [valueData.flexCheckDefault]);

  const currencycodeList = keycodeData.toString();
  const imageFN = imageFNData.toString();
  // const [privateModal, setPrivateModal] = useState(false);
  // const [keyData, setKeyData] = useState([]);
  const publicKeys = useSelector((state) => state.keyReducer);
  let APPID = "";
  let APIKEY = "";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedIssuer = await CreateTokenApi(APPID, APIKEY, {
        ...tokenData,
        currencycodeList, imageFN
      });
      dispatch(showLoader(false));
      const devryptedIssuer = await decrypytdata(encryptedIssuer.data, APIKEY);
      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        // dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      }
      /* console.log(
        "🚀 ~ file: Review.js ~ line 36 ~ closeModal ~ jsonData",
        jsonData
      ); */
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
      // nextstep();
    }

    // setPrivateModal(false);
  };

  return (
    <>
      {/* {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
      )} */}

      <div className="review-wallet review-new-token">
        <div className="container-fluid p-0 ">
          <div className="row m-0">
            <div className="col-md-9 col-lg-9 col-xxl-12 mt-5">
              <div className="review-wallet-box ms-0">
                <div className="row">
                  <div className="col-xxl-12">
                    <div className="review-wallet-info-box mb-5">
                      <div className="row">
                        <div className="col-12">
                          <div className="heading">
                            <h3>Token Information</h3>
                            <i className="bi bi-pencil-square"></i>
                          </div>
                          <hr className="mb-4" />
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3">
                          <h6>Basic Information</h6>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Token Class</h4>
                              <p>{tokenData?.tokenClassKeycode}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Token Type</h4>
                              <p>{tokenData?.tokenTypeKeycode}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Token Symbol</h4>
                              <p>{tokenData?.tokenSymbol}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Token Name</h4>
                              <p>{tokenData?.token}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Display Name (Alias)</h4>
                              <p>{tokenData?.displayname}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3">
                          <h6>Supply</h6>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Supply Type</h4>
                              <p>{tokenData?.supplyTypecode}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Maximum Supply</h4>
                              <p>{tokenData?.maximumSupply}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Initial Supply</h4>
                              <p>{tokenData?.initialSupply}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Decimal Price</h4>
                              <p>{tokenData?.decimalPlaces}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="review-wallet-info-box">
                  <div className="row">
                    <div className="col-12">
                      <div className="heading">
                        <h3>Selected Currencies</h3>
                        <i className="bi bi-pencil-square"></i>
                      </div>
                      <hr className="mb-4" />
                    </div>
                    {valueData?.flexCheckDefault.map((value, key) => {
                      return (
                        <div
                          className="col-sm-6 col-lg-4 col-xl-3 mb-4"
                          key={key}
                        >
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h5>{value?.keycode}</h5>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-11 p-0">
          <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
            <button
              type="button"
              className="btn cancel-btn mb-3 mb-md-0 me-4"
              onClick={() => {
                previous();
              }}
            >
              PREVIOUS
            </button>
            <button
              type="button"
              className="btn next-btn mb-3 mb-md-0"
              onClick={() => {
                // setPrivateModal(true);
                closeModal();
              }}
            >
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Review;

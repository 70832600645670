import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Grid from "../../../Components/Grid";
import { getIssuer } from "../../../API/Issures";
import Header from '../../../Views/admin/SysAdmin/Header'
import Footer from '../../../Views/admin/SysAdmin/Footer'
import { Issuercolumns } from "../../../Components/GridHeaders";
import { decrypytdata } from "../../../API/walletkey";
import { useDispatch, useSelector } from 'react-redux'
import { showLoader } from '../../../action/index'

const GetIssuers = () => {
  const [rows, setRows] = useState([]);

  const dispatch = useDispatch()
  const {
    privateKey: { PrivateWalletAddress: PrivateKey },
    publicKey: { publicAddress: PublicAddress }
  } = useSelector(state => state.keyReducer)

  const getIssuers = async () => {
    dispatch(showLoader(true))

    const response = await getIssuer(PublicAddress, PrivateKey);
    const data = await decrypytdata(response.data, PrivateKey);
    const issuers = JSON.parse(JSON.parse(data).CommandResponse);

    setRows(
      issuers.map((issuer) => ({
        ...issuer,
        rowid: issuer.Issuercode,
      }))
    );

    dispatch(showLoader(false))
  };

  useEffect(() => {
    getIssuers()

    return () => {
      dispatch(showLoader(false))
      setRows([])
    }
  }, []);

  return (
    <>
        <div className="row  ms-lg-4 ms-xl-5">
          <div className="col-xxl-10">
            <div className="main-heading">
              <h3> Issuers</h3>
              <h6> Get Issuers</h6>
              <p>
                This function lists all the issuers associated with a wallet.
              </p>
            </div>
            <div className="wallet-balances-box">
              <div className="d-md-flex justify-content-between ">
                <div className="search-box d-md-flex">
                  <div className="input-group mb-3"></div>
                </div>
                <Link to={{ pathname: "/controlpanel/createissuer" }}>
                  <button
                    type="button"
                    className="btn btn-primary "
                    style={{ marginBottom: "10px" }}
                  >
                    + ADD Issuer
                  </button>
                </Link>
              </div>
              <div style={{ height: 800, width: "100%" }}>
                <Grid row={rows} coloums={Issuercolumns} />
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default GetIssuers;

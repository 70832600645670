import React, { useEffect, useState } from "react";
// import PrivateModal from "../../../Components/PrivateModal";
import { useDispatch, useSelector } from "react-redux";
import { decrypytdata } from "../../../API/walletkey";
import { Entitycolumns } from "../../../Components/GridHeaders";
import Grid from "../../../Components/Grid";
import { getEntity } from "../../../API/Entity";
import { Link } from "react-router-dom";
import { showLoader } from "../../../action";
function EntityBalances() {
  const [privateModal, setPrivateModal] = useState(false);
  const [rows, setRows] = React.useState([]);
  const publicKeys = useSelector((state) => state.keyReducer);

  let APPID = "";
  let APIKEY = "";
  const dispatch = useDispatch();
  const closeModal = () => {
    setPrivateModal(false);
  };
  const fetchdata = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedLedger = await getEntity(APPID, APIKEY);
      dispatch(showLoader(false));
      const Entity = await decrypytdata(encryptedLedger.data, APIKEY);
      let data = JSON.parse(JSON.parse(Entity).CommandResponse);
      /* console.log(
        "🚀 ~ file: EntityBalances.js ~ line 31 ~ fetchdata ~ data",
        data
      ); */

      /* console.log('Entities', data); */

      // setRows(JSON.parse(Ledgers));
      if (data) {
        let dataArr = [];
        data?.forEach((dataMain, index) => {
          // multiple data
          dataArr.push({ ...dataMain, rowid: index });
          // console.log(dataMain, "dataMain")
        });
        setRows(dataArr);
      }
    }
    
  };

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (!publicKeys?.privateKey?.PrivateWalletAddress) setPrivateModal(true);
    }
    fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys, privateModal]);
  return (
    <>
      {/* {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
      )} */}
      
        <div className="row ms-lg-4 ms-xl-5 m-0">
          <div className="col-xxl-10">
            <div className="main-heading">
              <h3>KYC Entity</h3>
              <h6>Get Entities</h6>
              <p>
                This function lists all of the entities registered with a
                wallet.
              </p>
            </div>
            <div className="wallet-balances-box">
              <div className="d-md-flex justify-content-between">
                <div className="search-box d-md-flex">
                  <div className="input-group mb-3"></div>
                </div>
                <Link to={`/controlpanel/createentity`}>
                  <button
                    type="button"
                    className="btn btn-primary "
                    style={{ marginBottom: "10px" }}
                  >
                    + ADD Entity
                  </button>
                </Link>
              </div>
              <div style={{ height: 800, width: "100%" }}>
                <Grid row={rows} coloums={Entitycolumns} />
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default EntityBalances;

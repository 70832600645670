import React, { useState } from "react";
// import { useLocation } from "react-router-dom";
import Originator from "../PeopleKYC/Create/Originator";
import Review from "../PeopleKYC/Create/Review";
import { setPrivateId } from "../../../../src/action";
import { useDispatch, useSelector } from "react-redux";
import { success } from "../../../Components/alert";
import { decrypytdata } from "../../../API/walletkey";
import { ImportPeopleBatch } from "../../../API/People";

function CreatePeople() {
  const [step, setstep] = useState(1);
  const [people, setPeople] = useState(1);
  const [PeopleBranch, setPeopleBranch] = useState({});
  const publicKeys = useSelector((state) => state.keyReducer);

  // let { hash } = useLocation();
  let APPID = "";
  let APIKEY = "";
  const dispatch = useDispatch();

  const image = PeopleBranch;
  const formData = new FormData();
  formData.append("File", image);
  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
      /* console.log(
        "🚀 ~ file: ReviewIssuer.js ~ line 29 ~ closeModal ~ APIKEY",
        APIKEY
      ); */
    }
    if (APPID && APIKEY) {
      /* console.log(
        "🚀 ~ file: ReviewIssuer.js ~ line 24 ~ closeModal ~ APPID",
        APPID
      ); */

      const encryptedIssuer = await ImportPeopleBatch(APPID, APIKEY, formData);
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      // debugger;
      const jsonData = JSON.parse(devryptedIssuer);
      /* console.log(
        "🚀 ~ file: ReviewIssuer.js ~ line 42 ~ closeModal ~ jsonData",
        jsonData
      ); */
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
      // dispatch(setPrivateId(""));
      // debugger;
    }
    // setPrivateModal(false);
  };
  const nextstep = () => {
    // debugger;
    if (step < 2) {
      setstep(step + 1);
    }
    // formData();
  };
  const cleandata = () => {
    setPeople(1);
  };
  const previous = () => {
    if (step > 1) {
      setstep(step - 1);
    }
  };
  const setdata = (data) => {
    setPeople(data);
  };

  // const formData = (data) => {
  //   console.log("🚀 ~ file: CreatePeople.js ~ line 37 ~ formData ~ data", data);
  // };
  const renderstep = (step) => {
    switch (step) {
      case 1:
        return (
          <Originator
            nextstep={nextstep}
            previous={previous}
            setdata={setdata}
            cleandata={cleandata}
            people={people}
            // formData={formData}
          />
        );
      case 2:
        return (
          <Review
            nextstep={nextstep}
            previous={previous}
            setdata={setdata}
            cleandata={cleandata}
            people={people}
          />
        );
      default:
        return false;
    }
  };

  return (
    <>
      <div className="col-md  ms-lg-4 ms-xl-5">
        <div className="main-heading">
          <h3>KYC People</h3>
          <h6>Create</h6>
          <p>
            This function creates an individual customer with KYC information.
          </p>
        </div>
        <div className="transfer-rates-page">
          <div className="transfer-rates">
            <ul
              className="nav nav-tabs justify-content-around"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  <h3 className="m-0 ">Single </h3>
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  <h3 className="m-0">Batch </h3>
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#contact"
                  type="button"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="false"
                >
                  <h3 className="m-0">File Format</h3>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="tab-content mt-4" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div className="container-fluid p-0 ">
              <div className="col-12">
                <div className="deposit-funds-tabs ms-0">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li
                      className={
                        step > 1
                          ? "nav-item money-mr-w disableElement"
                          : "nav-item money-mr-w"
                      }
                    >
                      <button
                        className={step === 1 ? "nav-link active" : "nav-link"}
                        id="profile-tab"
                        type="button"
                      >
                        <div className="beneficiary">
                          <h3>1</h3>
                          <i className="bi bi-check-lg"></i>
                        </div>
                        <h6>Owner</h6>
                      </button>
                    </li>

                    <li
                      className={
                        step > 2 ? "nav-item disableElement" : "nav-item"
                      }
                    >
                      <button
                        className={step === 2 ? "nav-link active" : "nav-link"}
                        id="cot-tab"
                        type="button"
                      >
                        <div className="review">
                          <h3>2</h3>
                          <i className="bi bi-check-lg"></i>
                        </div>
                        <h6>Review</h6>
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home">
                      {/* <Originator /> */}
                      {renderstep(step)}
                    </div>
                  </div>
                </div>

                {/* <div className="col-xxl-11">
              <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                <button
                  type="button"
                  className="btn cancel-btn mb-3 mb-md-0 me-4"
                  onClick={previous}
                >
                  {step === 1 ? "Skip" : "Previous"}
                </button>
                <button
                  type="button"
                  className="btn cancel-btn mb-3 mb-md-0 me-4"
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn next-btn mb-3 mb-md-0"
                  onClick={nextstep}
                >
                  {step === 2 ? "Submit" : "Next"}
                </button>
              </div>
            </div> */}
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div className="row">
              {/*<h5 className="mb-3">*/}
              {/*    Upload a Limit Rules File in the proper*/}
              {/*    file format for batched processing*/}
              {/*</h5>*/}
              <div className="col-sm-6 col-xl-4">
                <div className="mb-5">
                  <label className="mb-3">Batch Upload</label>
                  <div className="input-box">
                    <div className="input-group custom-file-button">
                      <input
                        type="file"
                        className="form-control"
                        id="inputGroupFile"
                        onChange={(e) => {
                          setPeopleBranch(e.target.files[0]);
                        }}
                      />
                      <label className="input-group-text" htmlFor="inputGroupFile">
                        BROWSE
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-11">
              <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                {/* <button
                type="button"
                className="btn cancel-btn mb-3 mb-md-0 me-4"
                onClick={previous}
              >
                PREVIOUS
              </button> */}
                <button
                  type="submit"
                  className="btn next-btn mb-3 mb-md-0"
                  onClick={closeModal}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="contact"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            <div className="row">
              <h5 className="mb-3">
                Upload a pipe delimited ("|") file with the following fields in
                sequential order. The first row is the field names header and is
                ignored.
              </h5>
              <div className="wallet-balances-box m-0">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Field Name</th>
                        <th>Field Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/*<tr>*/}
                      {/*    <td>First Name</td>*/}
                      {/*    <td>*/}
                      {/*        The first name of the user{" "}*/}
                      {/*    </td>*/}
                      {/*</tr>*/}
                      {/*<tr>*/}
                      {/*    <td>Middle Name</td>*/}
                      {/*    <td>*/}
                      {/*        The middle name of the user{" "}*/}
                      {/*    </td>*/}
                      {/*</tr>*/}
                      {/*<tr>*/}
                      {/*    <td>Email</td>*/}
                      {/*    <td>The email address </td>*/}
                      {/*</tr>*/}
                      {/*<tr>*/}
                      {/*    <td>Password</td>*/}
                      {/*    <td>The login password </td>*/}
                      {/*</tr>*/}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
export default CreatePeople;

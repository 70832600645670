import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
// import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getDropdownvalues } from "../../../../../src/API/dropdown";
import { getTokenBySymbol } from "../../../../../src/API/Token";
import { decrypytdata } from "../../../../../src/API/walletkey";
// import PrivateModal from "../../../../../src/Components/PrivateModal";
import { showLoader } from "../../../../action";

function Originator({ nextstep, setdata }) {
    const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
    const [tokenClassdropdown, setTokenClassdropdown] = useState([]);
    const [Supplydropdown, setSupplydropdown] = useState([]);
    const [tokenTypedropdown, setTokenTypedropdown] = useState([]);
    const publicKeys = useSelector((state) => state.keyReducer);
    const [privateModal, setPrivateModal] = useState(false);
    const [searchParams] = useSearchParams();
    const [tokensData, settokensData] = useState([]);

    const dispatch = useDispatch();
    const closeModal = () => {
        setPrivateModal(false);
    };
    useEffect(() => {
        let APPID = "";
        let APIKEY = "";
        const fetchdata = async () => {
            if (publicKeys.publicKey) {
                APPID = publicKeys.publicKey.publicAddress;
            }
            if (publicKeys.privateKey) {
                APIKEY = publicKeys.privateKey.PrivateWalletAddress;
            }

            if (APPID && APIKEY) {
                // const encryptedLedger = await getTokenBySymbol(APPID, APIKEY);
                dispatch(showLoader(true));
                const encryptedLedger = await getTokenBySymbol(APPID, APIKEY, searchParams.get("id"));
                const tokens = await decrypytdata(encryptedLedger.data, APIKEY);
                dispatch(showLoader(false));
                // console.log("🚀 ~ file: OriginatorModify.js ~ line 40 ~ fetchdata ~ tokens", tokens)
                // setRows(JSON.parse(tokens));
                let data = JSON.parse(JSON.parse(tokens).CommandResponse)
                if (data) {
                    let dataArr = []
                    data?.forEach((dataMain, index) => {
                        // multiple data
                        dataArr.push({ ...dataMain, rowid: index })
                    })
                    const dataTokens = (Object.assign({}, ...dataArr))
                    settokensData(dataTokens)
                }
            }
        };
        if (publicKeys?.publicKey?.publicAddress) {
            if (!publicKeys?.privateKey?.PrivateWalletAddress) setPrivateModal(true);
        }
        fetchdata();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicKeys, privateModal, searchParams]);

    const fetchdropdwon = async () => {
        let LKTokenClass = await getDropdownvalues("LKTokenClass");
        setTokenClassdropdown(LKTokenClass.commandDataset);
        let LKSupplyType = await getDropdownvalues("LKSupplyType");
        setSupplydropdown(LKSupplyType.commandDataset);
        let LKTokenType = await getDropdownvalues("LKTokenType");
        setTokenTypedropdown(LKTokenType.commandDataset);
        // let LKTokenType = await getDropdownvalues("LKTokenType");
        // setTokenTypedropdown(LKTokenType);
    };
    useEffect(() => {
        if (publicKeys?.publicKey?.publicAddress) {
            // const encryptedLedger = await getTokenBySymbol({APPID, APIKEY,} );
            //   const tokens = await decrypytdata(encryptedLedger.data, APIKEY);
            setisValidPublicAddress(true);
            fetchdropdwon();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicKeys]);

    let initialValues = {
        token: tokensData.Token ? tokensData.Token : "",
        currencycodeList: tokensData.CurrencycodeList ? tokensData.CurrencycodeList : "",
        decimalPlaces: tokensData.DecimalPlaces ? tokensData.DecimalPlaces : "",
        displayname: tokensData.Displayname ? tokensData.Displayname : 0,
        tokenSymbol: tokensData.TokenSymbol ? tokensData.TokenSymbol : "",

        // only read values
        tokenClassKeycode: tokensData.TokenClasscode ? tokensData.TokenClasscode : "",
        supplyTypecode: tokensData.SupplyTypecode ? tokensData.SupplyTypecode : "",
        tokenTypeKeycode: tokensData.TokenTypecode ? tokensData.TokenTypecode : "",
        maximumSupply: tokensData.MaximumSupply ? tokensData.MaximumSupply : 0,
        initialSupply: tokensData.InitialSupply ? tokensData.InitialSupply : 0,

        // tokenSymbol: tokensData.TokenSymbol,
        // tokenName: "",
        // issuedSupply: tokensData.IssuedSupply,
        // frozenSupply: 1,
        // circulatingSupply: tokensData.CirculatingSupply,
        // description: tokensData.Description,
        // imageFN: tokensData.ImageFN,
    };
    useEffect(() => {
    }, [tokensData])
    return (
        <>
            {/* {privateModal && (
                <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
            )} */}
            {isValidPublicAddress && (
                <div className="row">
                    <div className="col-12">
                        <div className="originating-account link-wallet-box create-page ms-0">

                            <Formik
                                initialValues={initialValues}
                                // validationSchema={validationSchema}
                                enableReinitialize={true}
                                onSubmit={(values) => {
                                    // debugger;
                                    setdata(values);
                                    //   CreateLedgerApi(values);
                                    // debugger;
                                    nextstep();
                                    //   nextstep();
                                }}
                            >
                                {(formik) => {
                                    const { setFieldValue } = formik;
                                    return (
                                        <div className="wallet-information-box mt-4  m-0">
                                            <Form className="wallet-information-form">
                                                <div className="row mb-xl-5">
                                                    <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                        <h6>Basic Information</h6>
                                                        <div className="mb-5">
                                                            <label>Token Class</label>
                                                            <Field
                                                                as="select"
                                                                id="tokenClassKeycode"
                                                                name="tokenClassKeycode"
                                                                className="form-select form-control p-2"
                                                                disabled={true}
                                                                onChange={(e) => {
                                                                    setFieldValue(
                                                                        "tokenClassKeycode",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.tokenClassKeycode}
                                                            >
                                                                {tokenClassdropdown &&
                                                                    tokenClassdropdown.map((value, index) => {
                                                                        return (
                                                                                <option
                                                                                    value={value.keycode}
                                                                                    key={index}
                                                                                >
                                                                                    {value.displayName}
                                                                                </option>
                                                                        );
                                                                    })}
                                                            </Field>
                                                            <div style={{ color: "red" }}>
                                                                <ErrorMessage
                                                                    name="tokenClassKeycode"
                                                                    component="span"
                                                                    className="error"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <label>Token Type</label>
                                                            <Field
                                                                as="select"
                                                                id="tokenTypeKeycode"
                                                                className="form-select form-control p-2"
                                                                name="tokenTypeKeycode"
                                                                disabled={true}
                                                                onChange={(e) => {
                                                                    setFieldValue(
                                                                        "tokenTypeKeycode",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.tokenTypeKeycode}
                                                            >
                                                                {tokenTypedropdown &&
                                                                    tokenTypedropdown.map((value, index) => {
                                                                        return (
                                                                                <option
                                                                                    value={value.keycode}
                                                                                    key={index}
                                                                                >
                                                                                    {value.displayName}
                                                                                </option>
                                                                        );
                                                                    })}
                                                            </Field>
                                                            <ErrorMessage
                                                                name="tokenTypeKeycode"
                                                                component="span"
                                                                className="error"
                                                            />
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="tokenSymbol"
                                                                    id="tokenSymbol"
                                                                    placeholder="Token Symbol"
                                                                    className="form-control"
                                                                // disabled={true}
                                                                />
                                                                <label className="ps-0 pb-0">Token Symbol</label>
                                                                <i className="bi bi-check-lg"></i>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="tokenSymbol"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="token"
                                                                    id="token"
                                                                    placeholder="Token"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">Token Name</label>
                                                                <i className="bi bi-check-lg"></i>
                                                                <ErrorMessage
                                                                    name="token"
                                                                    component="span"
                                                                    className="error"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="displayname"
                                                                    id="displayname"
                                                                    placeholder="Display Name"
                                                                    className="form-control"
                                                                />
                                                                <label className="ps-0 pb-0">Display Name (Alias)</label>
                                                                <i className="bi bi-check-lg"></i>
                                                                <ErrorMessage
                                                                    name="displayname"
                                                                    component="span"
                                                                    className="error"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                        <h6>Supply </h6>
                                                        <div className="mb-5">
                                                            <label>Supply Type</label>
                                                            <Field
                                                                as="select"
                                                                id="supplyTypecode"
                                                                name="supplyTypecode"
                                                                disabled={true}
                                                                onChange={(e) => {
                                                                    setFieldValue(
                                                                        "supplyTypecode",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.supplyTypecode}
                                                                className="form-select form-control p-2"
                                                            >
                                                                {Supplydropdown &&
                                                                    Supplydropdown.map((value, index) => {
                                                                        return (
                                                                                <option
                                                                                    value={value.keycode}
                                                                                    key={index}
                                                                                >
                                                                                    {value.displayName}
                                                                                </option>
                                                                        );
                                                                    })}
                                                            </Field>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="maximumSupply"
                                                                    id="maximumSupply"
                                                                    placeholder="Maximum Supply"
                                                                    className="form-control"
                                                                    disabled={true}
                                                                />
                                                                <label className="ps-0 pb-0">Maximum Supply</label>
                                                                <i className="bi bi-check-lg"></i>
                                                                <ErrorMessage
                                                                    name="maximumSupply"
                                                                    component="span"
                                                                    className="error"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <div className="input-box form-floating">
                                                                <Field
                                                                    type="text"
                                                                    name="initialSupply"
                                                                    id="initialSupply"
                                                                    placeholder="Initial Supply"
                                                                    className="form-control"
                                                                    disabled={true}
                                                                />
                                                                <label className="ps-0 pb-0">Initial Supply</label>
                                                                <i className="bi bi-check-lg"></i>
                                                                <ErrorMessage
                                                                    name="initialSupply"
                                                                    component="span"
                                                                    className="error"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-5">
                                                            <label>Decimal Place</label>
                                                            <Field
                                                                as="select"
                                                                id="decimalPlaces"
                                                                name="decimalPlaces"
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.decimalPlaces}
                                                                className="form-select form-control p-2"
                                                                onChange={(e) => {
                                                                    setFieldValue(
                                                                        "decimalPlaces",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                            >
                                                                <option defaultValue>--Select--</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                                <option value="4">Four</option>
                                                                <option value="5">Five</option>
                                                                <option value="6">six</option>
                                                            </Field>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5">
                                                    <button
                                                        type="button"
                                                        className="btn cancel-btn mb-3 mb-md-0 me-4"
                                                    >
                                                        PREVIOUS
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn next-btn mb-3 mb-md-0"
                                                    // onClick={nextstep}
                                                    >
                                                        NEXT
                                                    </button>
                                                </div>
                                            </Form>
                                        </div>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Originator;

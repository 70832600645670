import React from "react";
import {
  setPrivateId,
  setResponseAPI,
  setResult,
  showLoader,
} from "../../../../action/index";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { insertIssuer } from "../../../../API/Issures";
import { decrypytdata } from "../../../../API/walletkey";
import { success } from "../../../../Components/alert";

function ReviewIssuer({ previous, cleandata, value, token }) {
  /* console.log(
    "🚀 ~ file: ReviewIssuer.js ~ line 14 ~ ReviewIssuer ~ valueData",
    value
  );
  console.log(
    "🚀 ~ file: ReviewIssuer.js ~ line 14 ~ ReviewIssuer ~ token",
    token
  ); */
  let { pathname } = useLocation();
  // const [privateModal, setPrivateModal] = useState(false);
  const { publicKey: { publicAddress }, privateKey: { PrivateWalletAddress } } = useSelector((state) => state.keyReducer);
  let APPID = "";
  let APIKEY = "";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currencycodeList = (token?.flexCheckDefault).toString();

  const closeModal = async () => {
    if (publicAddress) {
      APPID = publicAddress;
    }
    if (PrivateWalletAddress) {
      APIKEY = PrivateWalletAddress;
      /* console.log(
        "🚀 ~ file: ReviewIssuer.js ~ line 29 ~ closeModal ~ APIKEY",
        APIKEY
      ); */
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      /* console.log(
        "🚀 ~ file: ReviewIssuer.js ~ line 24 ~ closeModal ~ APPID",
        APPID
      );
      console.log(pathname, "pathnamepathname"); */

      const encryptedIssuer = await insertIssuer(APPID, APIKEY, {
        ...value,
        currencycodeList,
      });
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      // debugger;
      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        /* dispatch(setPrivateId("")); */
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      } else {
        /* dispatch(setPrivateId("")); */
        // dispatch(setResult(jsonData))
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      }
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
        // console.log("🚀 ~ file: Review.js ~ line 33 ~ closeModal ~ jsonData", jsonData)
      }

      // debugger;
    }
    dispatch(showLoader(false));
  };

  return (
    <>
      <div className="container-fluid p-0 ">
        {/* <div className="row m-0"> */}
        {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
            </div> */}

        <div className="row mt-4">
          <div className="col-12">
            {/* <div className="main-heading">
                <h3>Issuer</h3>
                <h6>
                  {pathname === "/controlpanel/modifyissuer"
                    ? "Modify Issuer"
                    : "Create Issuer"}
                </h6>
                <p>
                  This function establishes a financial institution on the
                  Unicoin network capable of modify customer wallets.
                </p>
              </div> */}
            <div className="review-wallet-box m-0">
              <div className="row">
                <div className="col-xxl-11">
                  <div className="review-wallet-info-box mb-5">
                    <div className="row">
                      <div className="col-12">
                        <div className="heading">
                          <h3>Issuer Information</h3>
                          <i
                            className="bi bi-pencil-square"
                            onClick={() => {
                              previous(2);
                              /* dispatch(setPrivateId("")); */
                            }}
                          ></i>
                        </div>
                        <hr className="mb-4" />
                      </div>

                      <div className="col-sm-6 col-lg-4 col-xl-3">
                        <h6>Basic Information</h6>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Issuer</h4>
                            <p>{value?.issuer}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Display Name</h4>
                            <p>{value?.displayname}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Issuer Type</h4>
                            <p>{value?.issuerTypecode}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Issuer Code</h4>
                            <p>{value?.issuercode}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 col-xl-3">
                        <h6>Institution </h6>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Institution Typecode</h4>
                            <p>{value?.institutionTypecode}</p>
                          </div>
                        </div>
                        {/* <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Department Typecode</h4>
                            <p>{value?.departmentTypecode}</p>
                          </div>
                        </div> */}
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Office Typecode</h4>
                            <p>{value?.officeTypecode}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 col-xl-3">
                        <h6>Identifier</h6>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Location Identifier</h4>
                            <p>{value?.accuityID}</p>
                          </div>
                        </div>
                        {/* <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Native Identifier</h4>
                            <p>{value?.NativeIdentifier}</p>
                          </div>
                        </div> */}
                        {/* <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>UUID</h4>
                            <p>{value?.uuid}</p>
                          </div>
                        </div> */}
                      </div>
                      <div className="col-sm-6 col-lg-4 col-xl-3">
                        <h6>Additional Informaiton</h6>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Incorporation Number</h4>
                            <p>{value?.incorporationNumber}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Federal Tax ID</h4>
                            <p>{value?.federal_TaxID}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>ISO LEI</h4>
                            <p>{value?.isO_LEI}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 col-xl-3">
                        <h6>Basic Address Information</h6>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Country Code</h4>
                            <p>{value?.countrycode}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>State Province</h4>
                            <p>{value?.stateProvince}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>City Town</h4>
                            <p>{value?.cityTown}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 col-xl-3">
                        <h6>Address</h6>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Postalcode</h4>
                            <p>{value?.postalCode}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Address Line 1</h4>
                            <p>{value?.addressLine1}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Address Line 2</h4>
                            <p>{value?.addressLine2}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="review-wallet-info-box mb-5">
                    <div className="row">
                      <div className="col-12">
                        <div className="heading">
                          <h3>Primary Contact Information</h3>
                          <i className="bi bi-pencil-square" onClick={() => {
                              previous(2);
                              /* dispatch(setPrivateId("")); */
                            }}></i>
                        </div>
                        <hr className="mb-4" />
                      </div>
                      <div className="col-sm-6 col-lg-4 col-xl-3">
                        <h6>Primary Contact</h6>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>First Name</h4>
                            <p>{value?.firstname}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Middle Name</h4>
                            <p>{value?.secondFirstname}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Last Name</h4>
                            <p>{value?.lastname}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Second Last Name</h4>
                            <p>{value?.secondLastname}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 col-xl-3">
                        <h6>Contact Information </h6>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Dialing Code</h4>
                            <p>{value?.mobileDialingcode}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Phone Number</h4>
                            <p>{value?.mobilePhone}</p>
                          </div>
                        </div>
                        {/* <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Extension</h4>
                            <p>{value?.extension}</p>
                          </div>
                        </div> */}
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Email Address</h4>
                            <p>{value?.email}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-lg-4 col-xl-3">
                        <h6>Primary Contact Account Information</h6>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Job Title</h4>
                            <p>{value?.jobTitle}</p>
                          </div>
                        </div>
                        <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Username</h4>
                            <p>{value?.username}</p>
                          </div>
                        </div>

                        {/* <div className="wallet-list">
                          <i className="bi bi-check"></i>
                          <div>
                            <h4>Password</h4>
                            <p>Password</p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xxl-12">
                      <div className="review-wallet-info-box">
                        <div className="row">
                          <div className="col-12">
                            <div className="heading">
                              <h3>Selected Currencies</h3>
                              <i className="bi bi-pencil-square" onClick={() => {
                              previous();
                              /* dispatch(setPrivateId("")); */
                            }}></i>
                            </div>
                            <hr className="mb-4" />
                          </div>
                          {token?.flexCheckDefault.map((value, key) => {
                            return (
                              <div
                                className="col-sm-6 col-lg-4 col-xl-3 mb-4"
                                key={key}
                              >
                                <div className="wallet-list">
                                  <i className="bi bi-check"></i>
                                  <div>
                                    <h5>{value}</h5>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-11 p-0">
                <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                  <button
                    type="button"
                    className="btn cancel-btn mb-3 mb-md-0 me-4"
                    onClick={() => {
                      cleandata();
                      /* dispatch(setPrivateId("")); */
                      previous();
                    }}
                  >
                    CANCEL
                  </button>
                  <button
                    type="button"
                    className="btn next-btn mb-3 mb-md-0"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default ReviewIssuer;

import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { getDropdownvalues } from "../../../../API/dropdown";
import { getLedgerByCode, GetTokenSymbols } from "../../../../API/LedgerAPI";
import { decrypytdata } from "../../../../API/walletkey";
// import { CreateLedgerApi } from "../../../../api/LedgerAPI";
function CreateLedger({ nextstep, setdata }) {
    let { pathname } = useLocation();
    const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
    const [ledgerTypedropdown, setLedgerTypedropdown] = useState([]);
    const [tokenType, setTokenTypedropdown] = useState([]);
    const [interestratedropdown, setInterestrateropdown] = useState([]);
    const [TokenSymbol, setTokenSymbol] = useState([]);
    const publicKeys = useSelector((state) => state.keyReducer);
    const [privateModal, setPrivateModal] = useState(false);
    const [searchParams] = useSearchParams();
    const [LedgerData, setLedgerData] = useState([]);

    const fetchdropdwon = async () => {
        let dropdownvalues = await getDropdownvalues("LKLedgerType");
        setLedgerTypedropdown(dropdownvalues.commandDataset);
        let tokendropdownvalues = await getDropdownvalues("LKTokenType");
        setTokenTypedropdown(tokendropdownvalues.commandDataset);
        let interestratedropdownvalues = await getDropdownvalues("LKInterestType");
        setInterestrateropdown(interestratedropdownvalues.commandDataset);
        let tokenSymboldropdownvalues = await GetTokenSymbols("GetTokenSymbols");
        setTokenSymbol(tokenSymboldropdownvalues);
    };

    // let APPID = "";
    // let APIKEY = "";
    // const fetchdata = async () => {
    //   if (publicKeys.publicKey) {
    //     APPID = publicKeys.publicKey.publicAddress;
    //   }
    //   if (publicKeys.privateKey) {
    //     APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    //   }

    //   if (APPID && APIKEY) {
    //     // const encryptedLedger = await getTokenBySymbol(APPID, APIKEY);
    //     const encryptedLedger = await getLedgerByCode(APPID, APIKEY, searchParams.get("id"));
    //     const tokens = await decrypytdata(encryptedLedger.data, APIKEY);
    //     // setRows(JSON.parse(tokens));
    //     let data = JSON.parse(JSON.parse(tokens).jsonOutput)
    //     // console.log(JSON.parse(tokens), "token---output");
    //     // settokensData(JSON.parse(tokens))
    //     if (data) {
    //       let dataArr = []
    //       data?.forEach((dataMain,index) => {
    //         dataArr.push({...dataMain ,rowid:index})
    //         console.log("dataArr", dataArr)
    //       })
    //       const dataKLedger = (Object.assign({},...dataArr))
    //       setLedgerData(dataKLedger)
    //     }
    //   }
    // };

    // const closeModal = () => {
    //   setPrivateModal(false);
    // };


    useEffect(() => {
        let APPID = "";
        let APIKEY = "";
        if (publicKeys?.publicKey?.publicAddress) {
            if (!publicKeys?.privateKey?.PrivateWalletAddress) setPrivateModal(true);
        }
        const fetchdata = async () => {
            if (publicKeys.publicKey) {
                APPID = publicKeys.publicKey.publicAddress;
            }
            if (publicKeys.privateKey) {
                APIKEY = publicKeys.privateKey.PrivateWalletAddress;
            }

            if (APPID && APIKEY) {
                // const encryptedLedger = await getTokenBySymbol(APPID, APIKEY);
                const encryptedLedger = await getLedgerByCode(APPID, APIKEY, searchParams.get("id"));
                const tokens = await decrypytdata(encryptedLedger.data, APIKEY);

                // setRows(JSON.parse(tokens));
                let data = JSON.parse(JSON.parse(tokens).CommandResponse)

                // console.log(data);

                // console.log(JSON.parse(tokens), "token---output");
                // settokensData(JSON.parse(tokens))
                if (data) {
                    let dataArr = []
                    data?.forEach((dataMain, index) => {
                        dataArr.push({ ...dataMain, rowid: index })
                        // console.log("dataArr", dataArr)
                    })
                    const dataKLedger = (Object.assign({}, ...dataArr))
                    setLedgerData(dataKLedger)
                }
            }
        };
        fetchdata();
    }, [publicKeys, privateModal, searchParams]);

    useEffect(() => {
        if (publicKeys?.publicKey?.publicAddress) {
            setisValidPublicAddress(true);
            fetchdropdwon();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicKeys]);
    const initialValues = {
        ledger: LedgerData.Ledger ? LedgerData.Ledger : "",
        displayname: LedgerData.Displayname ? LedgerData.Displayname : "",
        minimumDeposit: LedgerData.MinimumDeposit ? LedgerData.MinimumDeposit : 0,
        minimumBalance: LedgerData.MinimumBalance ? LedgerData.MinimumBalance : 0,
        maximumDeposit: LedgerData.MaximumDeposit ? LedgerData.MaximumDeposit : 0,
        maximumBalance: LedgerData.MaximumBalance ? LedgerData.MaximumBalance : 0,
        interestTypecode: LedgerData.InterestTypecode ? LedgerData.InterestTypecode : "",
        annualInterestRate: LedgerData.AnnualInterestRate ? LedgerData.AnnualInterestRate : 0,
        compoundPeriodInDays: LedgerData.CompoundPeriodInDays ? LedgerData.CompoundPeriodInDays : 0,
        graceDaysForDeployment: LedgerData.GraceDaysForDeployment ? LedgerData.GraceDaysForDeployment : 0,
        minimumSubscriptionDays: LedgerData.MinimumSubscriptionDays ? LedgerData.MinimumSubscriptionDays : 0,
        monthlyFee: LedgerData.MonthlyFee ? LedgerData.MonthlyFee : 0,
        belowMinimumDefaultFee: LedgerData.BelowMinimumDefaultFee ? LedgerData.BelowMinimumDefaultFee : 0,
        overdraftProtection: LedgerData.OverdraftProtection ? LedgerData.OverdraftProtection : false,
        overdraftMaximumAmount: LedgerData.OverdraftMaximumAmount ? LedgerData.OverdraftMaximumAmount : 0,
        overdraftFee: LedgerData.OverdraftFee ? LedgerData.OverdraftFee : 0,
        userDirectDepositAllowed: LedgerData.UserDirectDepositAllowed ? LedgerData.UserDirectDepositAllowed : false,
        userDirectWithdrawAllowed: LedgerData.UserDirectWithdrawAllowed ? LedgerData.UserDirectWithdrawAllowed : false,
        userTransferWithdrawAllowed: LedgerData.UserTransferWithdrawAllowed ? LedgerData.UserTransferWithdrawAllowed : false,
        ownerDirectDepositAllowed: LedgerData.OwnerDirectDepositAllowed ? LedgerData.OwnerDirectDepositAllowed : false,
        ownerDirectWithdrawAllowed: LedgerData.OwnerDirectWithdrawAllowed ? LedgerData.OwnerDirectWithdrawAllowed : false,
        ownerTransferDepositAllowed: LedgerData.OwnerTransferDepositAllowed ? LedgerData.OwnerTransferDepositAllowed : false,
        ownerTransferWithdrawAllowed: LedgerData.OwnerTransferWithdrawAllowed ? LedgerData.OwnerTransferWithdrawAllowed : false,
        ledgercode: LedgerData.Ledgercode ? LedgerData.Ledgercode : "",

        // Read only fields
        ledgerTypeKeycode: LedgerData.ledgerTypeKeycode ? LedgerData.ledgerTypeKeycode : "",
        tokenSymbol: LedgerData.TokenSymbol ? LedgerData.TokenSymbol : "",
        userTransferDepositAllowed: LedgerData.UserTransferDepositAllowed ? LedgerData.UserTransferDepositAllowed : false,


    };

    // eslint-disable-next-line no-unused-vars
    // const validationSchema = Yup.object({
    //   ledger: Yup.string().required("Ledger Name is required!"),
    //   displayname: Yup.string().required("Display Name is required!"),
    //   // ledgerTypeKeycode: Yup.string().required("Ledger Type is required!"),
    //   ledgercode: Yup.string().required("Ledger Code is required!"),
    //   // TokenSystem: Yup.string().required("Token System is required!"),
    //   currencycode: Yup.string().required("Currency Code is required!"),
    //   overdraftMaximumAmount: Yup.string().required(
    //     "Maximum Overdraft Amount is required!"
    //   ),
    //   overdraftFee: Yup.string().required("Overdraft Free is required!"),
    //   minimumDeposit: Yup.string().required("Minimum Deposit is required!"),
    //   maximumDeposit: Yup.string().required("Maximum Deposit is required!"),
    //   minimumBalance: Yup.string().required("Minimum Balance is required!"),
    //   maximumBalance: Yup.string().required("Maximum Balance is required!"),
    //   interestTypecode: Yup.string().required(
    //     "Annualized Interest Rate Type is required!"
    //   ),
    //   annualInterestRate: Yup.string().required(
    //     "Annualized Interest Rate is required!"
    //   ),
    //   compoundPeriodInDays: Yup.string().required(
    //     "Compound Interest Period is required!"
    //   ),
    //   graceDaysForDeployment: Yup.string().required(
    //     "Grace Days for Interest Accrual is required!"
    //   ),
    //   minimumSubscriptionDays: Yup.string().required(
    //     "Minimum Subscription Days is required!"
    //   ),
    //   monthlyFee: Yup.string().required("Monthly Account Fee is required!"),
    //   belowMinimumDefaultFee: Yup.string().required(
    //     "Minimum Balance Default Fee is required!"
    //   ),
    // });
    return (
        <>
            {/* <Header /> */}
            {isValidPublicAddress && (
                    <div className="container-fluid p-0 ml-3">
                        <div className="row ms-lg-4 ms-xl-5 m-0">
                            <div className="col-12">
                                <div className="main-heading">
                                    <h3>Ledger</h3>
                                    <h6>
                                        {pathname === "/controlpanel/modifyledger"
                                            ? "Modify Ledger"
                                            : "Create Ledger"}
                                    </h6>
                                    <p>
                                        This function allows the updating of ledger descriptive information.
                                    </p>
                                </div>
                                <Formik
                                    initialValues={initialValues}
                                    // validationSchema={validationSchema}
                                    enableReinitialize={true}
                                    onSubmit={(values) => {
                                        setdata(values);
                                        //   CreateLedgerApi(values);
                                        nextstep();
                                    }}
                                >
                                    {(formik) => {
                                        const { setFieldValue } = formik;
                                        return (
                                            <div className="wallet-information-box m-0">
                                                <h4>Ledger Information</h4>
                                                <Form className="wallet-information-form">
                                                    <div className="row mb-xl-5">
                                                        <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                            <h6>Basic Information</h6>
                                                            <div className="mb-5">
                                                                <div className="input-box form-floating">
                                                                    <Field
                                                                        type="text"
                                                                        name="ledger"
                                                                        id="ledger"
                                                                        placeholder="Ledger Name"
                                                                        className="form-control"
                                                                    />
                                                                <label className="ps-0 pb-0">Ledger Name</label>
                                                                    <i className="bi bi-check-lg"></i>
                                                                </div>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="ledger"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <div className="input-box form-floating">
                                                                    <Field
                                                                        type="text"
                                                                        name="displayname"
                                                                        id="displayname"
                                                                        placeholder="Display Name"
                                                                        className="form-control"
                                                                        value={formik.values.displayname}
                                                                    />
                                                                    <label className="ps-0 pb-0">Display Name</label>
                                                                    <i className="bi bi-check-lg"></i>
                                                                </div>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="displayname"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <label>Ledger Type</label>
                                                                <Field
                                                                    as="select"
                                                                    className="form-select form-control p-2"
                                                                    id="ledgerTypeKeycode"
                                                                    disabled={true}
                                                                    onChange={(e) => {
                                                                        setFieldValue(
                                                                            "ledgerTypeKeycode",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.ledgerTypeKeycode}
                                                                >
                                                                    {ledgerTypedropdown &&
                                                                        ledgerTypedropdown.map((value, index) => {
                                                                            return (
                                                                                    <option
                                                                                        value={value.Keycode}
                                                                                        key={index}
                                                                                    >
                                                                                        {value.Displayname}
                                                                                    </option>
                                                                            );
                                                                        })}
                                                                </Field>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="ledgerTypeKeycode"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <div className="input-box form-floating">
                                                                    <Field
                                                                        type="text"
                                                                        name="ledgercode"
                                                                        id="ledgercode"
                                                                        placeholder="Ledger Code"
                                                                        className="form-control"
                                                                        value={formik.values.ledgercode}
                                                                    />
                                                                    <label className="ps-0 pb-0">Ledger Code</label>
                                                                    <i className="bi bi-check-lg"></i>
                                                                </div>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="ledgercode"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <label>Token Symbol</label>
                                                                <Field
                                                                    as="select"
                                                                    className="form-select form-control p-2"
                                                                    id="tokenSymbol"
                                                                    disabled={true}
                                                                    onChange={(e) => {
                                                                        setFieldValue("tokenSymbol", e.target.value);
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    value={formik.values.tokenSymbol}
                                                                >
                                                                    {TokenSymbol &&
                                                                        TokenSymbol.map((value, index) => {
                                                                            return (
                                                                                    <option
                                                                                        value={value.keycode}
                                                                                        key={index}
                                                                                    >
                                                                                        {value.tokenSymbol}
                                                                                    </option>
                                                                            );
                                                                        })}
                                                                </Field>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="TokenSystem"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                            <h6>Range</h6>
                                                            <div className="mb-5">
                                                                <div className="input-box form-floating">
                                                                    <Field
                                                                        type="text"
                                                                        name="minimumDeposit"
                                                                        id="minimumDeposit"
                                                                        placeholder="Minimum Deposit"
                                                                        className="form-control"
                                                                        value={formik.values.minimumDeposit}
                                                                    />
                                                                    <label className="ps-0 pb-0">Minimum Deposit</label>
                                                                    <i className="bi bi-check-lg"></i>
                                                                </div>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="minimumDeposit"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <div className="input-box form-floating">
                                                                    <Field
                                                                        type="text"
                                                                        name="maximumDeposit"
                                                                        id="maximumDeposit"
                                                                        placeholder="Maximum Deposit"
                                                                        className="form-control"
                                                                        value={formik.values.maximumDeposit}
                                                                    />
                                                                    <label className="ps-0 pb-0">Maximum Deposit</label>
                                                                    <i className="bi bi-check-lg"></i>
                                                                </div>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="maximumDeposit"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <div className="input-box form-floating">
                                                                    <Field
                                                                        type="text"
                                                                        name="minimumBalance"
                                                                        id="minimumBalance"
                                                                        placeholder="Minimum Balance"
                                                                        className="form-control"
                                                                        value={formik.values.minimumBalance}
                                                                    />
                                                                    <label className="ps-0 pb-0">Minimum Balance </label>
                                                                    <i className="bi bi-check-lg"></i>
                                                                </div>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="minimumBalance"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <div className="input-box form-floating">
                                                                    <Field
                                                                        type="text"
                                                                        name="maximumBalance"
                                                                        id="maximumBalance"
                                                                        placeholder="Maximum Balance"
                                                                        className="form-control"
                                                                        value={formik.values.maximumBalance}
                                                                    />
                                                                    <label className="ps-0 pb-0">Maximum Balance </label>
                                                                    <i className="bi bi-check-lg"></i>
                                                                </div>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="maximumBalance"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                            <h6>Interest Rate</h6>
                                                            <div className="mb-5">
                                                                <label>Annualized Interest Rate Type</label>
                                                                <div className="input-box">
                                                                    <Field
                                                                        as="select"
                                                                        name="interestTypecode"
                                                                        id="interestTypecode"
                                                                        placeholder="Annualized Interest Rate Type"
                                                                        className="form-control p-2"
                                                                        onChange={(e) => {
                                                                            setFieldValue(
                                                                                "interestTypecode",
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                        onBlur={formik.handleBlur}
                                                                        value={formik.values.interestTypecode}
                                                                    >
                                                                        {interestratedropdown &&
                                                                            interestratedropdown.map((value, index) => {
                                                                                return (
                                                                                        <option
                                                                                            value={value.Keycode}
                                                                                            key={index}
                                                                                        >
                                                                                            {value.Displayname}
                                                                                        </option>
                                                                                );
                                                                            })}
                                                                    </Field>

                                                                    <i className="bi bi-check-lg"></i>
                                                                </div>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="interestTypecode"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <div className="input-box form-floating">
                                                                    <Field
                                                                        type="text"
                                                                        name="annualInterestRate"
                                                                        id="annualInterestRate"
                                                                        placeholder="Annualized Interest Rate"
                                                                        className="form-control"
                                                                        value={formik.values.annualInterestRate}
                                                                    />
                                                                    <label className="ps-0 pb-0">Annualized Interest Rate</label>
                                                                    <i className="bi bi-check-lg"></i>
                                                                </div>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="annualInterestRate"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <div className="input-box form-floating">
                                                                    <Field
                                                                        type="text"
                                                                        name="compoundPeriodInDays"
                                                                        id="compoundPeriodInDays"
                                                                        placeholder="Compound Interest Periodin Days"
                                                                        className="form-control"
                                                                        value={formik.values.compoundPeriodInDays}
                                                                    />
                                                                    <label className="ps-0 pb-0">Compound Interest Period in Days </label>
                                                                    <i className="bi bi-check-lg"></i>
                                                                </div>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="compoundPeriodInDays"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <div className="input-box form-floating">
                                                                    <Field
                                                                        type="text"
                                                                        name="graceDaysForDeployment"
                                                                        id="graceDaysForDeployment"
                                                                        placeholder="Grace Days for Interest Accrual"
                                                                        className="form-control"
                                                                        value={formik.values.graceDaysForDeployment}
                                                                    />
                                                                    <label className="ps-0 pb-0">Grace Days for Interest Accrual </label>
                                                                    <i className="bi bi-check-lg"></i>
                                                                </div>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="graceDaysForDeployment"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                            <h6>Subscription & Fee</h6>
                                                            <div className="mb-5">
                                                                <div className="input-box form-floating">
                                                                    <Field
                                                                        type="text"
                                                                        name="minimumSubscriptionDays"
                                                                        id="minimumSubscriptionDays"
                                                                        placeholder="Minimum Subscription Days"
                                                                        className="form-control"
                                                                    />
                                                                    <label className="ps-0 pb-0">Minimum Subscription Days</label>
                                                                    <i className="bi bi-check-lg"></i>
                                                                    <div style={{ color: "red" }}>
                                                                        <ErrorMessage
                                                                            name="minimumSubscriptionDays"
                                                                            component="span"
                                                                            className="error"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <div className="input-box form-floating">
                                                                    <Field
                                                                        type="text"
                                                                        name="monthlyFee"
                                                                        id="monthlyFee"
                                                                        placeholder="Monthly Account Fee"
                                                                        className="form-control"
                                                                        value={formik.values.monthlyFee}
                                                                    />
                                                                    <label className="ps-0 pb-0">Monthly Account Fee</label>
                                                                    <i className="bi bi-check-lg"></i>
                                                                </div>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="monthlyFee"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <div className="input-box form-floating">
                                                                    <Field
                                                                        type="text"
                                                                        name="belowMinimumDefaultFee"
                                                                        id="belowMinimumDefaultFee"
                                                                        placeholder="Minimum Balance Default Fee"
                                                                        className="form-control"
                                                                        value={formik.values.belowMinimumDefaultFee}
                                                                    />
                                                                    <label className="ps-0 pb-0">Minimum Balance Default Fee </label>
                                                                    <i className="bi bi-check-lg"></i>
                                                                </div>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="belowMinimumDefaultFee"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                            <h6>Overdraft</h6>
                                                            <div className="mb-5">
                                                                <label>Overdraft Protection Enabled</label>
                                                                <div className="form-check form-switch">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id="flexSwitchCheckChecked"
                                                                        onChange={(e) => {
                                                                            setFieldValue(
                                                                                "overdraftProtection",
                                                                                e.target.checked
                                                                            );
                                                                        }}
                                                                        value={formik.values.overdraftProtection}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <div className="input-box form-floating">
                                                                    <Field
                                                                        type="text"
                                                                        name="overdraftMaximumAmount"
                                                                        id="overdraftMaximumAmount"
                                                                        placeholder="Maximum Overdraft Amount"
                                                                        className="form-control"
                                                                        value={formik.values.overdraftMaximumAmount}
                                                                    />
                                                                    <label className="ps-0 pb-0">Maximum Overdraft Amount</label>
                                                                    <i className="bi bi-check-lg"></i>
                                                                </div>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="overdraftMaximumAmount"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <div className="input-box form-floating">
                                                                    <Field
                                                                        type="text"
                                                                        name="overdraftFee"
                                                                        id="overdraftFee"
                                                                        placeholder="Overdraft Fee"
                                                                        className="form-control"
                                                                        value={formik.values.overdraftFee}
                                                                    />
                                                                <label className="ps-0 pb-0">Overdraft Fee </label>
                                                                    <i className="bi bi-check-lg"></i>
                                                                </div>
                                                                <div style={{ color: "red" }}>
                                                                    <ErrorMessage
                                                                        name="overdraftFee"
                                                                        component="span"
                                                                        className="error"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                            <h6>User Action Settings</h6>
                                                            <div className="mb-5">
                                                                <label>User Direct Deposits Allowed</label>
                                                                <div className="form-check form-switch">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id="flexSwitchCheckChecked"
                                                                        onChange={(e) => {
                                                                            setFieldValue(
                                                                                "userDirectDepositAllowed",
                                                                                e.target.checked
                                                                            );
                                                                        }}
                                                                        value={formik.values.userDirectDepositAllowed}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <label>User Direct Withdrawals Allowed</label>
                                                                <div className="form-check form-switch">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id="flexSwitchCheckChecked"
                                                                        onChange={(e) => {
                                                                            setFieldValue(
                                                                                "userDirectWithdrawAllowed",
                                                                                e.target.checked
                                                                            );
                                                                        }}
                                                                        value={
                                                                            formik.values.userDirectWithdrawAllowed
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <label>User Transfer Deposits Allowed</label>
                                                                <div className="form-check form-switch">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id="flexSwitchCheckChecked"
                                                                        disabled={true}
                                                                        onChange={(e) => {
                                                                            setFieldValue(
                                                                                "userTransferDepositAllowed",
                                                                                e.target.checked
                                                                            );
                                                                        }}
                                                                        value={
                                                                            formik.values.userTransferDepositAllowed
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <label>User Transfer Withdrawals Allowed</label>
                                                                <div className="form-check form-switch">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id="flexSwitchCheckChecked"
                                                                        onChange={(e) => {
                                                                            setFieldValue(
                                                                                "userTransferWithdrawAllowed",
                                                                                e.target.checked
                                                                            );
                                                                        }}
                                                                        value={
                                                                            formik.values.userTransferWithdrawAllowed
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                            <h6>Owner Action Settings</h6>
                                                            <div className="mb-5">
                                                                <label>Owner Direct Deposits Allowed</label>
                                                                <div className="form-check form-switch">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id="flexSwitchCheckChecked"
                                                                        onChange={(e) => {
                                                                            setFieldValue(
                                                                                "ownerDirectDepositAllowed",
                                                                                e.target.checked
                                                                            );
                                                                        }}
                                                                        value={
                                                                            formik.values.ownerDirectDepositAllowed
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <label>Owner Direct Withdrawals Allowed</label>
                                                                <div className="form-check form-switch">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id="flexSwitchCheckChecked"
                                                                        onChange={(e) => {
                                                                            setFieldValue(
                                                                                "ownerDirectWithdrawAllowed",
                                                                                e.target.checked
                                                                            );
                                                                        }}
                                                                        value={
                                                                            formik.values.ownerDirectWithdrawAllowed
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <label>Owner Transfer Deposits Allowed</label>
                                                                <div className="form-check form-switch">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id="flexSwitchCheckChecked"
                                                                        onChange={(e) => {
                                                                            setFieldValue(
                                                                                "ownerTransferDepositAllowed",
                                                                                e.target.checked
                                                                            );
                                                                        }}
                                                                        value={
                                                                            formik.values.ownerTransferDepositAllowed
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mb-5">
                                                                <label>Owner Transfer Withdrawals Allowed</label>
                                                                <div className="form-check form-switch">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id="flexSwitchCheckChecked"
                                                                        onChange={(e) => {
                                                                            setFieldValue(
                                                                                "ownerTransferWithdrawAllowed",
                                                                                e.target.checked
                                                                            );
                                                                        }}
                                                                        value={
                                                                            formik.values.ownerTransferWithdrawAllowed
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5">
                                                        <button
                                                            type="button"
                                                            className="btn cancel-btn mb-3 mb-md-0 me-4"
                                                        >
                                                            CANCEL
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className="btn next-btn mb-3 mb-md-0"
                                                        // onClick={nextstep}
                                                        >
                                                            NEXT
                                                        </button>
                                                    </div>
                                                </Form>
                                            </div>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
            )}
            {/* </div> */}
        </>
    );
}

export default CreateLedger;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ledgerSuspend } from "../../../API/LedgerAPI";
import { decrypytdata } from "../../../API/walletkey";
import { success } from "../../../Components/alert";
// import PrivateModal from "../../../Components/PrivateModal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { setPrivateId, setResponseAPI, setResult, showLoader } from "../../../action";
// import TokenConfirm from "../Token/TokenConfirm";

import SuspendLedgerConfirm from '../../../Components/SuspendConfirm'

function LedgersSuspend() {
  const location = useLocation()

  const publicKeys = useSelector((state) => state.keyReducer);
  // const [privateModal, setPrivateModal] = useState(false);
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const [data, setdata] = useState("");
  const [searchParams] = useSearchParams();
  const [popUp, setPopUp] = useState(false);

  const ledgercodeData = searchParams.get("id");

  const initialValues = {
    sourcePublicAddress: '',
    // ledgercode: ledgercodeData || ''
    ledgercode: location.state.Ledgercode || ''
  };
  let APPID = "";
  let APIKEY = "";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const suspendData = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedIssuer = await ledgerSuspend(APPID, APIKEY, data);
      dispatch(showLoader(false));
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      // const jsonData = JSON.parse(devryptedIssuer);

      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        // dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      }
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
    }

    // setPrivateModal(false);
  };

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      setisValidPublicAddress(true);
    }
    // fetchdropdwon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);
  return (
    <>
      {/* {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={suspendData} />
      )} */}
      {isValidPublicAddress && (
        <div className="col-md-9 col-lg-9 col-xxl-10">
          <div className="row ms-lg-4 ms-xl-5 m-0">
            <div className="col-12">
              <div className="main-heading">
                <h3>Ledger</h3>
                <h6>Suspend Ledgers</h6>
                <p>
                  This function suspends a ledger from sending or receiving
                  digital currencies.
                </p>
              </div>
              <div className="wallet-information-box m-0">
                <div className="suspend-text wallet-information-form">
                  <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => {
                      /* console.log("values", values); */
                      setdata(values);
                      setPopUp(true);
                    }}
                  >
                    {(formik) => {
                      const { errors, touched } = formik;
                      return (
                        <Form className="wallet-information-form">
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <div className="input-box  form-floating mb-5">
                              <Field
                                type="text"
                                name="ledgercode"
                                id="ledgercode"
                                placeholder="Public Ledger Address"
                                className="form-control"
                              />
                              <label className="ps-0 pb-0">
                                Ledger Code
                              </label>
                              <div
                                className={
                                  errors.ledgercode &&
                                    touched.ledgercode
                                    ? "input-error"
                                    : null
                                }
                              >
                                <ErrorMessage
                                  name="ledgercode"
                                  component="span"
                                  className="error"
                                  style={{ color: "red" }}
                                />
                              </div>
                            </div>

                            <div className="input-box  form-floating mb-5">
                              <Field
                                type="text"
                                name="sourcePublicAddress"
                                id="sourcePublicAddress"
                                placeholder="Public Ledger Address"
                                className="form-control"
                              />
                              <label className="ps-0 pb-0">
                                Public Wallet Address
                              </label>
                              <div
                                className={
                                  errors.sourcePublicAddress &&
                                    touched.sourcePublicAddress
                                    ? "input-error"
                                    : null
                                }
                              >
                                <ErrorMessage
                                  name="sourcePublicAddress"
                                  component="span"
                                  className="error"
                                  style={{ color: "red" }}
                                />
                              </div>
                            </div>

                          </div>
                          <div class="d-flex me-0 me-md-4 me-xxl-5">
                            <button
                              type="button"
                              class="btn cancel-btn mb-3 mb-md-0 me-4"
                            >
                              CANCEL
                            </button>
                            <button
                              // onClick={() => {
                              //   // setPrivateModal(true);
                              //   suspendData();
                              // }}
                              type="submit"
                              class="btn next-btn mb-3 mb-md-0"
                            >
                              SUSPEND
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {popUp && (
        <SuspendLedgerConfirm
          heading={"Suspend Ledger"}
          body={{ uniqueCode: data.ledgercode, sourcePublicAddress: data.sourcePublicAddress }}
          suspendData={suspendData}
          setPopUp={setPopUp}
        />
      )}
    </>
  );
}

export default LedgersSuspend;

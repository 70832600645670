import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPrivateId, setResponseAPI, setResult, showLoader } from "../../../../action";
import { ModifyPeople } from "../../../../API/PeopleApi";
import { decrypytdata } from "../../../../API/walletkey";
import { success } from "../../../../Components/alert";

function ModifyReview({ people, previous, cleandata }) {
  // const [privateModal, setPrivateModal] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  let APPID = "";
  let APIKEY = "";

  const values = {
    globalPeopleTypeID: people.globalPeopleTypeID
      ? people.globalPeopleTypeID
      : "",
    nativeID: people.nativeID ? people.nativeID : "",
    globalPeople: people.globalPeople ? people.globalPeople : "",
    displayname: people.displayname,
    firstname: people.firstname,
    secondFirstname: people.secondFirstname,
    lastname: people.lastname,
    secondLastname: people.secondLastname,
    dob: people.dob.replaceAll("-", ""),
    birthCountrycode: people.birthCountrycode,
    birthGovernmentNumber: people.birthGovernmentNumber,
    passportCountrycode: people.passportCountrycode,
    passportNumber: people.passportNumber,
    driversLicenseCountrycode: people.driversLicenseCountrycode,
    driversLicenseNumber: people.driversLicenseNumber,
    governmentIDCountrycode: people.governmentIDCountrycode,
    governmentIDNumber: people.governmentIDNumber,
    otherIDCountrycode: people.otherIDCountrycode,
    otherIDNumber: people.otherIDNumber,
    primaryCountrycode: people.primaryCountrycode,
    primaryStateProvince: people.primaryStateProvince,
    primaryCityTown: people.primaryCityTown,
    primaryPostalcode: people.primaryPostalcode,
    primaryAddressLine1: people.primaryAddressLine1,
    primaryAddressLine2: people.primaryAddressLine2,
    primaryAddressLine3: people.primaryAddressLine3,
    primaryDialingcode: people.primaryDialingcode,
    primaryPhoneNumber: people.primaryPhoneNumber,
    primaryEmail: people.primaryEmail,
    primaryDIDKeycode: people.primaryDIDKeycode,
    primaryDID: people.primaryDID,
    primaryEntity: people.primaryEntity,
    primaryRegistrationNumber: people.primaryRegistrationNumber,
    secondaryCountrycode: people.secondaryCountrycode,
    secondaryStateProvince: people.secondaryStateProvince,
    secondaryCityTown: people.secondaryCityTown,
    secondaryPostalcode: people.secondaryPostalcode,
    secondaryAddressLine1: people.secondaryAddressLine1,
    secondaryAddressLine2: people.secondaryAddressLine2,
    secondaryAddressLine3: people.secondaryAddressLine3,
    secondaryDialingcode: people.secondaryDialingcode,
    secondaryPhoneNumber: people.secondaryPhoneNumber,
    secondaryEmail: people.secondaryEmail,
    secondaryDIDKeycode: people.secondaryDIDKeycode,
    secondaryDID: people.secondaryDID,
    secondaryEntity: people.secondaryEntity,
    secondaryRegistrationNumber: people.secondaryRegistrationNumber,
    tertiaryCountrycode: people.tertiaryCountrycode,
    tertiaryStateProvince: people.tertiaryStateProvince,
    tertiaryCityTown: people.tertiaryCityTown,
    tertiaryPostalcode: people.tertiaryPostalcode,
    tertiaryAddressLine1: people.tertiaryAddressLine1,
    tertiaryAddressLine2: people.tertiaryAddressLine2,
    tertiaryAddressLine3: people.tertiaryAddressLine3,
    tertiaryDialingcode: people.tertiaryDialingcode,
    tertiaryPhoneNumber: people.tertiaryPhoneNumber,
    tertiaryEmail: people.tertiaryEmail,
    tertiaryDIDKeycode: people.tertiaryDIDKeycode,
    tertiaryDID: people.tertiaryDID,
    tertiaryEntity: people.tertiaryEntity,
    tertiaryRegistrationNumber: people.tertiaryRegistrationNumber,
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedIssuer = await ModifyPeople(APPID, APIKEY, values);
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      // debugger;
      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        // dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      } else {
        // dispatch(setPrivateId(""));
        // dispatch(setResult(jsonData))
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      }
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
        // console.log("🚀 ~ file: Review.js ~ line 33 ~ closeModal ~ jsonData", jsonData)
      }
      // debugger;
    }
    dispatch(showLoader(false));
  };

  return (
    <>
      <div className="row">
        <div className="col-xxl-11">
          <div className="review-wallet review-new-token">
            <div className="container-fluid p-0 ">
              <div className="row m-0">
                <div className="col-md-12 mt-5">
                  <div className="review-wallet-box ms-0">
                    <div className="row">
                      <div className="col-xxl-12">
                        <div className="review-wallet-info-box mb-5">
                          <div className="row">
                            <div className="col-12">
                              <div className="heading">
                                <h3>Wallet Information</h3>
                                <i className="bi bi-pencil-square"></i>
                              </div>
                              <hr className="mb-4" />
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                              <h6>Basic Information</h6>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>First Name</h4>
                                  <p>{values.firstname}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Second First Name</h4>
                                  <p>{values.secondFirstname}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Last Name</h4>
                                  <p>{values.lastname}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Second Last Name</h4>
                                  <p>{values.secondLastname}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Business Name</h4>
                                  <p></p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Industry</h4>
                                  <p></p>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                              <h6>Home Address</h6>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Birth Country Code</h4>
                                  <p>{values.birthCountrycode}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>State</h4>
                                  <p>{values.primaryStateProvince}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>City</h4>
                                  <p>{values.primaryCityTown}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Address Line 1</h4>
                                  <p>{values.primaryAddressLine1}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Address Line 2</h4>
                                  <p>{values.primaryAddressLine2}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Postal Code</h4>
                                  <p>{values.primaryPostalcode}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                              <h6>Identification</h6>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Date of Birth</h4>
                                  <p>{values.dob}</p>
                                  {/* <p>{date}</p> */}
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Government Birth Number</h4>
                                  <p>{values.birthGovernmentNumber}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Passport Country</h4>
                                  <p>{values.passportCountrycode}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Passport Number</h4>
                                  <p>{values.passportNumber}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Driver's License Number</h4>
                                  <p>{values.driversLicenseNumber}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Other Government ID Number</h4>
                                  <p>{values.governmentIDNumber}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                              <h6>Contact Information</h6>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Email Address</h4>
                                  <p>{values.primaryEmail}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Mobile Dialing Country Code</h4>
                                  <p>{values.primaryDialingcode}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Mobile Number</h4>
                                  <p>{values.primaryPhoneNumber}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-11 p-0">
          <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
            <button
              type="button"
              className="btn cancel-btn mb-3 mb-md-0 me-4"
              onClick={() => {
                cleandata();
                // dispatch(setPrivateId(""));
                previous();
              }}
            >
              Previous
            </button>
            <button type="button" className="btn cancel-btn mb-3 mb-md-0 me-4">
              CANCEL
            </button>
            <button
              type="button"
              className="btn next-btn mb-3 mb-md-0"
              onClick={() => {
                closeModal();
              }}
            >
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModifyReview;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Header from "../../../Components/Header";
// import Sidebar from "../../../Components/Sidebar";
import { suspendEntityAPI } from "../../../API/Entity";
import { decrypytdata } from "../../../API/walletkey";
import { success } from "../../../Components/alert";
// import PrivateModal from "../../../Components/PrivateModal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { setPrivateId, setResponseAPI, setResult, showLoader } from "../../../action";
// import TokenConfirm from "../Token/TokenConfirm";

import SuspendEntityConfirm from '../../../Components/SuspendConfirm'

function EntitySuspend() {
  const location = useLocation()
  
  const publicKeys = useSelector((state) => state.keyReducer);
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  // const [privateModal, setPrivateModal] = useState(false);
  const [data, setdata] = useState("");
  /* console.log("🚀 ~ file: EntitySuspend.js ~ line 20 ~ EntitySuspend ~ data", data) */

  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [popUp, setPopUp] = useState(false);

  const initialValues = {
    // entitycode: searchParams.get("id") ? searchParams.get("id") : "",
    entitycode: String(location.state.Objectcode) || '',
    sourcePublicAddress: ''
  };

  let APPID = "";
  let APIKEY = "";

  const suspendData = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedIssuer = await suspendEntityAPI(APPID, APIKEY, data);
      dispatch(showLoader(false));
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        // dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      }
      /* console.log(
        "🚀 ~ file: Review.js ~ line 36 ~ closeModal ~ jsonData",
        jsonData
      ); */
      // const jsonData = JSON.parse(devryptedIssuer);
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
    }

    // setPrivateModal(false);
  };

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      setisValidPublicAddress(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);
  return (
    <>
      {/* {privateModal && (
                <PrivateModal
                    modalpriate={privateModal} closeModal={suspendData} />
            )} */}
      {isValidPublicAddress && (
          <div className="row ms-lg-4 ms-xl-5  m-0">
            <div className="col-12">
              <div className="main-heading">
                <h3>KYC Entity</h3>
                <h6>Suspend</h6>
                <p>
                  This function suspends a customer business entity from
                  transacting on the Unicoin network.
                </p>
              </div>
              <div className="wallet-information-box m-0">
                <div className="suspend-text wallet-information-form">
                  <Formik
                    initialValues={initialValues}
                    // validationSchema={validationSchema}
                    onSubmit={(values) => {
                      /* console.log("values", values); */
                      setdata(values);
                      setPopUp(true)
                    }}
                  >
                    {(formik) => {
                      const { errors, touched } = formik;
                      return (
                        <div className="">
                          <Form className="wallet-information-form">
                            <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                              {/* <label>Issuer Code</label> */}

                              <div className="input-box form-floating mb-5">
                                <Field
                                  type="text"
                                  name="entitycode"
                                  id="entitycode"
                                  placeholder="Public Entity Address"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Public Entity Address
                                </label>
                                <div
                                  className={
                                    errors.entitycode && touched.entitycode
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <ErrorMessage
                                    name="entitycode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>

                              <div className="input-box form-floating mb-5">
                                <Field
                                  type="text"
                                  name="sourcePublicAddress"
                                  id="sourcePublicAddress"
                                  placeholder="Public Wallet Address"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Public Wallet Address
                                </label>
                                <div
                                  className={
                                    errors.sourcePublicAddress && touched.sourcePublicAddress
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <ErrorMessage
                                    name="sourcePublicAddress"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>

                            </div>
                            <div className="d-flex me-0 me-md-4 me-xxl-5">
                              <button
                                type="button"
                                className="btn cancel-btn mb-3 mb-md-0 me-4"
                              >
                                CANCEL
                              </button>
                              <button

                                type="submit"
                                className="btn next-btn mb-3 mb-md-0"
                              >
                                SUSPEND
                              </button>
                            </div>
                          </Form>
                        </div>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
      )}

      {popUp && (
        <SuspendEntityConfirm
          heading={"Suspend Entity"}
          body={{ uniqueCode: data.entitycode, sourcePublicAddress: data.sourcePublicAddress }}

          suspendData={suspendData}
          setPopUp={setPopUp}
        />
      )}
    </>
  );
}

export default EntitySuspend;

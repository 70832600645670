import "./assets/css/bootstrap.min.css";
import "./assets/css/main.min.css";
import "./assets/css/styles.css";
/* import './styles/Sidebar.css' */
import "./App.css";
import "react-telephone-input/css/default.css";
// import Home from "../src/Views/admin/SysAdmin/index";
// import GetIssures from "./Views/admin/Issures/GetIssures";

import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import routes from "./routes";
import MoneyTransfer from "./Pages/money-transfer/wallettowallet";
import Result from "./Components/Result";

import { useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom';

import Loader from './Components/Loader'
import Header from './Views/admin/SysAdmin/Header'
import Footer from './Views/admin/SysAdmin/Footer'

import GetIssuers from "./Pages/WebWallet/Issuers/GetIssuers";
import CreateIssuer from './Pages/WebWallet/Issuers/MainCreateIssuer/IndexCrtIssuer'
import IndexMdfyIssuer from './Pages/WebWallet/Issuers/ModifyIssuer/IndexMdfyIssuer'
import IssuresSuspend from './Pages/WebWallet/Issuers/IssuresSuspend'

import BranchesBalances from "./Pages/WebWallet/Branches/BranchesBalances";
import CreateBranch from "./Pages/WebWallet/Branches/CreateBranch";
import ModifyBranch from './Pages/WebWallet/Branches/ModifyBranch'
import SuspendBranch from "./Pages/WebWallet/Branches/BranchesSuspend";

import AgentsBalances from './Pages/WebWallet/Agents/AgentsBalances'
import CreateAgents from './Pages/WebWallet/Agents/CreateAgent'
import ModifyAgent from "./Pages/WebWallet/Agents/ModifyAgent";
import AgentSuspend from "./Pages/WebWallet/Agents/AgentSuspend";

import GetLedger from "./Pages/WebWallet/Ledger/GetLedger";
import CreateLedger from "./Pages/WebWallet/Ledger/CreateLeder";
import ModifyLedger from './Pages/WebWallet/Ledger/ModifyLedger';
import LedgersSuspend from "./Pages/WebWallet/Ledger/LedgersSuspend";

import TokensBalances from "./Pages/WebWallet/Token/TokensBalances";
import CreateTokens from './Pages/WebWallet/Token/CreateToken'
import ModifyToken from './Pages/WebWallet/Token/ModifyToken'
import TokenSuspend from './Pages/WebWallet/Token/TokenSuspend'

import WalletsBalances from "./Pages/WebWallet/Wallet/WalletsBalances";
import CreateWallets from './Pages/WebWallet/Wallet/CreateWallets'
import ModiyWallets from './Pages/WebWallet/Wallet/ModifyWallets'
import WalletSuspend from './Pages/WebWallet/Wallet/WalletSuspend'

import PeopleBalances from "./Pages/WebWallet/PeopleKYC/PeopleBalances";
import CreatePeople from './Pages/WebWallet/PeopleKYC/index';
import ModifyPeople from './Pages/WebWallet/PeopleKYC/ModifyPeople';
import PeopleSuspend from './Pages/WebWallet/PeopleKYC/PeopleSuspend';

import EntityBalances from './Pages/WebWallet/EntityKYC/EntityBalances'
import CreateEntityKYC from './Pages/WebWallet/EntityKYC/CreateEntity'
import ModifyEntity from './Pages/WebWallet/EntityKYC/ModifyEntity'
import EntitySuspend from './Pages/WebWallet/EntityKYC/EntitySuspend'

import DepositFunds from "./Pages/WebWallet/Ledger/DepositFunds";
import WithdrawFunds from './Pages/WebWallet/Ledger/WithdrawFunds';

function App() {
  const location = useLocation()

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Loader />

      <Routes>
        <Route
          path="/controlpanel"
          element={
            <>
              <Header />

              <div className="container-fluid">
                <div className="row">
                  <Outlet />
                </div>
              </div>

              <Footer />
            </>
          }
        >
          <Route path="getissuers" element={<GetIssuers />} />
          <Route path="createissuer" element={<CreateIssuer />} />
          <Route path="modifyissuer" element={<IndexMdfyIssuer />} />
          <Route path="suspendissuer" element={<IssuresSuspend />} />

          <Route path="getbranches" element={<BranchesBalances />} />
          <Route path="createbranch" element={<CreateBranch />} />
          <Route path="modifybranch" element={<ModifyBranch />} />
          <Route path="suspendbranch" element={<SuspendBranch />} />

          <Route path="getagents" element={<AgentsBalances />} />
          <Route path="createagent" element={<CreateAgents />} />
          <Route path="modifyagent" element={<ModifyAgent />} />
          <Route path="suspendagent" element={<AgentSuspend />} />

          <Route path="getledgers" element={<GetLedger />} />
          <Route path="createledger" element={<CreateLedger />} />
          <Route path="modifyledger" element={<ModifyLedger />} />
          <Route path="suspendledger" element={<LedgersSuspend />} />

          <Route path="gettokens" element={<TokensBalances />} />
          <Route path="createtoken" element={<CreateTokens />} />
          <Route path="modifytoken" element={<ModifyToken />} />
          <Route path="suspendtoken" element={<TokenSuspend />} />

          <Route path="getwallets" element={<WalletsBalances />} />
          <Route path="createwallet" element={<CreateWallets />} />
          <Route path="modifywallet" element={<ModiyWallets />} />
          <Route path="suspendwallet" element={<WalletSuspend />} />

          <Route path="getpeople" element={<PeopleBalances />} />
          <Route path="createpeople" element={<CreatePeople />} />
          <Route path="modifypeople" element={<ModifyPeople />} />
          <Route path="suspendpeople" element={<PeopleSuspend />} />

          <Route path="getentities" element={<EntityBalances />} />
          <Route path="createentity" element={<CreateEntityKYC />} />
          <Route path="modifyentity" element={<ModifyEntity />} />
          <Route path="suspendentity" element={<EntitySuspend />} />

          <Route path="deposit" element={<DepositFunds />} />
          <Route path="withdraw" element={<WithdrawFunds />} />
        </Route>

        {routes.map((el, i) => {
          return (
            <Route exact path={el.path} element={<el.element />} key={i} />
          );
        })}

        {/* <Route path="/admin/get-issures" exact element={<GetIssures />} /> */}
        {/* <Route path="/sysadmin" exact element={<Home />} /> */}
        <Route path="/money-transfer-demo" exact element={<MoneyTransfer />} />
        <Route path="/result" exact element={<Result />} />

        {/* <Route path="*" element={<Navigate replace to="/" />} /> */}
      </Routes>
    </>
  );
}

export default App;

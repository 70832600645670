import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useSelector } from "react-redux";
// import * as Yup from "yup";
import {
  getCountryCodeDropDown,
  getDailingDropDown,
  getDropdownvalues,
} from "../../../../API/dropdown";

function CreateIssuer({ nextstep, setValue, IssuerData }) {
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const [dropdown, setDropdown] = useState([]);
  const [institutionTypeCode, setInstitutionTypeCode] = useState([]);
  const [officeTypeCode, setOfficeTypeCode] = useState([]);
  const [countryCode, setCountryCode] = useState([]);
  const [dailingCode, setDailingCode] = useState([]);
  const [currencyCode, setCurrencyCode] = useState("");
  const { publicKey: { publicAddress } } = useSelector((state) => state.keyReducer);

  const initialValues = {
    accuityID: 0,
    issuercode: "",
    hqIssuercode: "",
    issuerTypecode: "",
    issuer: "",
    displayname: "",
    institutionTypecode: "",
    officeTypecode: "",
    nativeKEY: "",
    issuerUUID: "",
    incorporationNumber: "",
    federal_TaxID: "",
    isO_LEI: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    neighborhood: "",
    cityTown: "",
    stateProvince: "",
    postalCode: "",
    countrycode: "",
    currencycode: "",
    timezoneID: "",
    username: "",
    userUUID: "",
    firstname: "",
    secondFirstname: "",
    lastname: "",
    secondLastname: "",
    jobTitle: "",
    email: "",
    mobileDialingcode: "",
    mobilePhone: "",
    imageFN: "",
  };
  // eslint-disable-next-line
  const initialValues1 = {
    accuityID: 0,
    issuercode: "DD1",
    hqIssuercode: "DD1",
    issuerTypecode: "",
    issuer: "EFG",
    displayname: "EFG",
    institutionTypecode: "",
    officeTypecode: "",
    nativeKEY: "BBDK",
    issuerUUID: "ISSUERuid8",
    incorporationNumber: "7777",
    federal_TaxID: "AB18",
    isO_LEI: "DAK",
    addressLine1: "260 SHWETA TOWN",
    addressLine2: "BH CHURCH Road",
    addressLine3: "Road no 4",
    neighborhood: "BEGU",
    cityTown: "SAURASTRA",
    stateProvince: "Gujarat",
    postalCode: "395052",
    countrycode: "",
    currencycode: "",
    timezoneID: "",
    username: "DD01",
    userUUID: "usDD01",
    firstname: "Raj",
    secondFirstname: "",
    lastname: "Dixit",
    secondLastname: "",
    jobTitle: "CA",
    email: "DD1x@GMAIL.COM",
    mobileDialingcode: "",
    mobilePhone: "9422816792",
    imageFN: "",
  };

  // const validationSchema = Yup.object({
  //   issuer: Yup.string()
  //     .max(15, "Must be 15 characters or less")
  //     .required("Issuer required"),
  //   issuercode: Yup.string().required("IssuerCode is required!"),
  //   nativeKEY: Yup.string(),
  //   issuerUUID: Yup.string(),
  //   hqIssuercode: Yup.string(),
  //   displayname: Yup.string()
  //     .max(20, "Must be 20 characters or less")
  //     .required("Displayname Required"),
  //   issuerTypecode: Yup.string().required("IssuerType is required!"),
  //   accuityID: Yup.number(),
  //   institutionTypecode: Yup.string().required(
  //     "InstitutionTypecode is required!"
  //   ),
  //   countrycode: Yup.string().required("CountryCode is required!"),
  //   stateProvince: Yup.string(),
  //   cityTown: Yup.string(),
  //   postalCode: Yup.string(),
  //   officeTypecode: Yup.string().required("officeTypecode is required!"),
  //   firstName: Yup.string().required("firstName is required!"),
  //   secondFirstname: Yup.string(),
  //   lastName: Yup.string().required("lastName is required!"),
  //   secondLastname: Yup.string(),
  //   email: Yup.string().required("email is required!"),
  //   jobTitle: Yup.string().required("jobTitle is required!"),
  //   username: Yup.string().required("username is required!"),
  //   addressLine1: Yup.string(),
  //   addressLine2: Yup.string(),
  //   addressLine3: Yup.string(),
  //   neighborhood: Yup.string(),
  //   userUUID: Yup.string(),
  //   mobileDialingcode: Yup.string()
  //     .typeError("you must specify a number")
  //     .required("DialingCode is required!"),
  //   mobilePhone: Yup.number()
  //     .typeError("you must specify a number")
  //     .max(9999999999, "Must be 10 Digit")
  //     .min(1111111111, "Must be 10 Digit")
  //     .required("PhoneNumber is required!"),
  //   incorporationNumber: Yup.string(),
  //   federal_TaxID: Yup.string(),
  //   isO_LEI: Yup.string(),
  //   imageFN: Yup.string(),
  // });
  const fetchdropdwon = async () => {
    let dropdownvalues = await getDropdownvalues("LKIssuerType");
    setDropdown(dropdownvalues.commandDataset);

    let insTypeCode = await getDropdownvalues("LKInstitutionType");
    setInstitutionTypeCode(insTypeCode.commandDataset);

    let OfficeTypeCode = await getDropdownvalues("LKOfficeType");
    setOfficeTypeCode(OfficeTypeCode.commandDataset);

    let countryType = await getCountryCodeDropDown("GetWorldCurrency");
    setCountryCode(countryType);

    let dialing = await getDailingDropDown("GetDialingcodes");
    setDailingCode(dialing);
  };

  useEffect(() => {
    if (publicAddress) {
      setisValidPublicAddress(true);
      fetchdropdwon();

      return () => {
        setisValidPublicAddress(false)
        setDropdown([])
        setInstitutionTypeCode([])
        setOfficeTypeCode([])
        setCountryCode([])
        setDailingCode([])
        setCurrencyCode("")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicAddress]);

  return (
    <>
      {isValidPublicAddress && (
        <div className="ledger-information create-issuer-information">
          <div className="row mt-4">
            <div className="col-12">
              <Formik
                initialValues={initialValues}
                // validationSchema={validationSchema}
                onSubmit={(values) => {
                  /* console.log(
                    "🚀 ~ file: CreateIssuer.js ~ line 174 ~ CreateIssuer ~ values",
                    values
                  ); */
                  // debugger;
                  // CreateIssuersApi(values);
                  setValue({
                    accuityID: values.accuityID,
                    issuercode: values.issuercode,
                    hqIssuercode: values.hqIssuercode,
                    issuerTypecode: values.issuerTypecode,
                    issuer: values.issuer,
                    displayname: values.displayname,
                    institutionTypecode: values.institutionTypecode,
                    officeTypecode: values.officeTypecode,
                    nativeKEY: values.nativeKEY,
                    issuerUUID: values.issuerUUID,
                    incorporationNumber: values.incorporationNumber,
                    federal_TaxID: values.federal_TaxID,
                    isO_LEI: values.isO_LEI,
                    addressLine1: values.addressLine1,
                    addressLine2: values.addressLine2,
                    addressLine3: values.addressLine3,
                    neighborhood: values.neighborhood,
                    cityTown: values.cityTown,
                    stateProvince: values.stateProvince,
                    postalCode: values.postalCode,
                    countrycode: values.countrycode,
                    currencycode: currencyCode.keycode,
                    timezoneID: values.timezoneID,
                    username: values.username,
                    userUUID: values.userUUID,
                    firstname: values.firstname,
                    secondFirstname: values.secondFirstname,
                    lastname: values.lastname,
                    secondLastname: values.secondLastname,
                    jobTitle: values.jobTitle,
                    email: values.email,
                    mobileDialingcode: values.mobileDialingcode,
                    mobilePhone: values.mobilePhone,
                    imageFN: values.imageFN,
                  });
                  nextstep();
                }}
              >
                {(formik) => {
                  const { errors, touched, setFieldValue } = formik;
                  return (
                    <div className="wallet-information-box m-0">
                      <h4>Issuer Information</h4>

                      <Form className="wallet-information-form">
                        <div className="row">
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Basic Information</h6>
                            <div className="mb-5">
                              <div className="input-box  form-floating">
                                <Field
                                  type="text"
                                  name="issuer"
                                  id="issuer"
                                  placeholder="Issuer"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">Issuer</label>
                                <div
                                  className={
                                    errors.issuer && touched.issuer
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="issuer"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box  form-floating">
                                <Field
                                  type="text"
                                  name="displayname"
                                  id="displayname"
                                  placeholder="Display Name"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Display Name
                                </label>
                                <div
                                  className={
                                    errors.displayname && touched.displayname
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="displayname"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Issuer Type</label>
                              <Field
                                as="select"
                                className="form-select form-control py-2"
                                id="issuerTypecode"
                                name="issuerTypecode"
                                onChange={(e) => {
                                  // console.log(e.target.value, "Issuer Type");
                                  setFieldValue(
                                    "issuerTypecode",
                                    e.target.value
                                  );
                                }}
                                onBlur={formik.handleBlur}
                              >
                                {dropdown &&
                                  dropdown.map((value, index) => {
                                    return (
                                      <option
                                        value={value.Keycode}
                                        key={index}
                                      >
                                        {value.Displayname}
                                      </option>
                                    );
                                  })}
                              </Field>
                              <div>
                                <ErrorMessage
                                  name="issuerTypecode"
                                  component="span"
                                  className="error"
                                  style={{ color: "red" }}
                                />
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box  form-floating">
                                <Field
                                  type="text"
                                  name="issuercode"
                                  id="issuercode"
                                  placeholder="Issuer Code"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">Issuer Code</label>
                                <div
                                  className={
                                    errors.issuercode && touched.issuercode
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="issuercode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Institution </h6>
                            <div className="mb-5">
                              <label>Institution Typecode</label>
                              <div className="input-box">
                                <Field
                                  as="select"
                                  className="form-select form-control py-2"
                                  id="institutionTypecode"
                                  name="institutionTypecode"
                                  onChange={(e) => {
                                    // console.log(
                                    //   e.target.value,
                                    //   "Institution Typecode"
                                    // );
                                    setFieldValue(
                                      "institutionTypecode",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                >
                                  {institutionTypeCode &&
                                    institutionTypeCode.map((value, index) => {
                                      return (
                                        <option
                                          value={value.Keycode}
                                          key={index}
                                        >
                                          {value.Displayname}
                                        </option>
                                      );
                                    })}
                                </Field>
                                <div
                                  className={
                                    errors.institutionTypecode &&
                                      touched.institutionTypecode
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <ErrorMessage
                                    name="institutionTypecode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mb-5">
                              <label>Office Typecode</label>
                              <div className="input-box">
                                <Field
                                  as="select"
                                  name="officeTypecode"
                                  id="officeTypecode"
                                  // placeholder="Office Typecode"
                                  className="form-select form-control py-2"
                                  onChange={(e) => {
                                    // console.log(
                                    //   e.target.value,
                                    //   "Office Typecode"
                                    // );
                                    setFieldValue(
                                      "officeTypecode",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                >
                                  {officeTypeCode &&
                                    officeTypeCode.map((value, index) => {
                                      return (
                                        <option
                                          value={value.Keycode}
                                          key={index}
                                        >
                                          {value.Displayname}
                                        </option>
                                      );
                                    })}
                                </Field>
                                <div
                                  className={
                                    errors.officeTypecode &&
                                      touched.officeTypecode
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <ErrorMessage
                                    name="officeTypecode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Identifier</h6>
                            <div className="mb-5">
                              <div className="input-box  form-floating">
                                <Field
                                  type="text"
                                  name="accuityID"
                                  id="accuityID"
                                  placeholder="Location Identifier"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Location Identifier
                                </label>
                                <i className="bi bi-check-lg"></i>
                                <div
                                  className={
                                    errors.accuityID && touched.accuityID
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <ErrorMessage
                                    name="accuityID"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Additional Informaiton</h6>
                            <div className="mb-5">
                              <div className="input-box  form-floating">
                                <Field
                                  type="text"
                                  name="incorporationNumber"
                                  id="incorporationNumber"
                                  placeholder="Incorporation Number"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Incorporation Number{" "}
                                </label>
                                <div
                                  className={
                                    errors.incorporationNumber &&
                                      touched.incorporationNumber
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="incorporationNumber"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mb-5">
                              <div className="input-box  form-floating">
                                <Field
                                  type="text"
                                  name="federal_TaxID"
                                  id="federal_TaxID"
                                  placeholder="Tax ID"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Federal TaxID{" "}
                                </label>
                                <div
                                  className={
                                    errors.federal_TaxID &&
                                      touched.federal_TaxID
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="federal_TaxID"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mb-5">
                              <div className="input-box  form-floating">
                                <Field
                                  type="text"
                                  name="isO_LEI"
                                  id="isO_LEI"
                                  placeholder="ISO LEI"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">ISO LEI </label>
                                <div
                                  className={
                                    errors.isO_LEI && touched.isO_LEI
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="isO_LEI"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Basic Address Information</h6>
                            <div className="mb-5">
                              <label>Country Code</label>
                              <Field
                                as="select"
                                className="form-select form-control py-2"
                                id="countrycode"
                                name="countrycode"
                                onChange={(e) => {
                                  // console.log(e.target.value, "Country Code");
                                  setFieldValue("countrycode", e.target.value);
                                  countryCode &&
                                    countryCode.forEach((value) => {
                                      if (
                                        e.target.value === value.countrycode
                                      ) {
                                        setCurrencyCode(value);
                                      }
                                    });
                                }}
                                onBlur={formik.handleBlur}
                              >
                                {countryCode &&
                                  countryCode.map((value, index) => {
                                    return (
                                      <option
                                        value={value.countrycode}
                                        key={index}
                                      >
                                        {value.isoCountryname}
                                      </option>
                                    );
                                  })}
                              </Field>
                              <div>
                                <ErrorMessage
                                  name="countrycode"
                                  component="span"
                                  className="error"
                                  style={{ color: "red" }}
                                />
                              </div>
                            </div>

                            <div className="mb-5">
                              <label>Currency Code</label>
                              <Field
                                as="select"
                                disabled={true}
                                className="form-select form-control py-2"
                                id="currencycode"
                                name="currencycode"
                                value={currencyCode.keycode}
                                // onChange={(e) => {
                                //   setFieldValue("currencycode", e.target.value);
                                //   console.log(e.target.value, "Currency Code");
                                // }}
                                onBlur={formik.handleBlur}
                              >
                                <option value={currencyCode.keycode}>
                                  {currencyCode.worldcurrency}
                                </option>
                                {/* {countryCode &&
                                  countryCode.map((value, index) => {
                                    return (
                                      <>
                                        <option
                                          value={value.keycode}
                                          key={index}
                                        >
                                          {value.worldcurrency}
                                        </option>
                                      </>
                                    );
                                  })} */}
                              </Field>
                              <div>
                                <ErrorMessage
                                  name="currencycode"
                                  component="span"
                                  className="error"
                                  style={{ color: "red" }}
                                />
                              </div>
                            </div>

                            <div className="mb-5">
                              <div className="input-box  form-floating">
                                <Field
                                  type="text"
                                  name="stateProvince"
                                  id="stateProvince"
                                  placeholder="State Province"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  State Province{" "}
                                </label>
                                <div
                                  className={
                                    errors.stateProvince &&
                                      touched.stateProvince
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="stateProvince"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mb-5">
                              <div className="input-box  form-floating">
                                <Field
                                  type="text"
                                  name="cityTown"
                                  id="cityTown"
                                  placeholder="City Town"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">City Town </label>
                                <div
                                  className={
                                    errors.cityTown && touched.cityTown
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="cityTown"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Address</h6>
                            <div className="mb-5">
                              <div className="input-box  form-floating">
                                <Field
                                  type="text"
                                  name="postalCode"
                                  id="postalCode"
                                  placeholder="Postal code"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">Postalcode </label>
                                <div
                                  className={
                                    errors.postalCode && touched.postalCode
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="postalCode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mb-5">
                              <div className="input-box  form-floating">
                                <Field
                                  type="text"
                                  name="addressLine1"
                                  id="addressLine1"
                                  placeholder="Address Line1"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  AddressLine1{" "}
                                </label>
                                <div
                                  className={
                                    errors.addressLine1 && touched.addressLine1
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="addressLine1"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mb-5">
                              <div className="input-box  form-floating">
                                <Field
                                  type="text"
                                  name="addressLine2"
                                  id="addressLine2"
                                  placeholder="Address Line2"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  AddressLine2{" "}
                                </label>
                                <div
                                  className={
                                    errors.addressLine2 && touched.addressLine2
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="addressLine2"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <h4>Primary Contact Information</h4>
                        <div className="row mb-xl-5">
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Primary Contact</h6>
                            <div className="mb-5">
                              <div className="input-box  form-floating">
                                <Field
                                  type="text"
                                  name="firstname"
                                  id="firstname"
                                  placeholder="First Name"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">First Name</label>
                                <div
                                  className={
                                    errors.firstname && touched.firstname
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="firstName"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mb-5">
                              <div className="input-box  form-floating">
                                <Field
                                  type="text"
                                  name="secondFirstname"
                                  id="secondFirstname"
                                  placeholder="Second First name"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Second First Name
                                </label>
                                <div
                                  className={
                                    errors.secondFirstname &&
                                      touched.secondFirstname
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="secondFirstname"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mb-5">
                              <div className="input-box  form-floating">
                                <Field
                                  type="text"
                                  name="lastname"
                                  id="lastname"
                                  placeholder="Last Name"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">Last Name</label>
                                <div
                                  className={
                                    errors.lastname && touched.lastname
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="lastName"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mb-5">
                              <div className="input-box  form-floating">
                                <Field
                                  type="text"
                                  name="secondLastname"
                                  id="secondLastname"
                                  placeholder="Second Last Name"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Second Last Name
                                </label>
                                <div
                                  className={
                                    errors.secondLastname &&
                                      touched.secondLastname
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="secondLastname"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Contact Information</h6>
                            <div className="mb-5">
                              <label>Dialing Code</label>
                              <Field
                                as="select"
                                className="form-select form-control py-2"
                                id="mobileDialingcode"
                                name="mobileDialingcode"
                                onChange={(e) => {
                                  // console.log(e.target.value, "Dialing Code");
                                  setFieldValue(
                                    "mobileDialingcode",
                                    e.target.value
                                  );
                                }}
                                onBlur={formik.handleBlur}
                              >
                                {dailingCode &&
                                  dailingCode.map((value, index) => {
                                    return (
                                      <option
                                        value={value.keycode}
                                        key={index}
                                      >
                                        {value.displayname}
                                      </option>
                                    );
                                  })}
                              </Field>
                              <div>
                                <ErrorMessage
                                  name="mobileDialingcode"
                                  component="span"
                                  className="error"
                                  style={{ color: "red" }}
                                />
                              </div>
                            </div>

                            <div className="mb-5">
                              <div className="input-box  form-floating">
                                <Field
                                  type="text"
                                  name="mobilePhone"
                                  id="mobilePhone"
                                  placeholder="Phone Number"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Phone Number
                                </label>
                                <div
                                  className={
                                    errors.mobilePhone && touched.mobilePhone
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="mobilePhone"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mb-5">
                              <div className="input-box  form-floating">
                                <Field
                                  type="text"
                                  name="email"
                                  id="email"
                                  placeholder="Email Address"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Email Address{" "}
                                </label>
                                <i className="bi bi-check-lg"></i>
                                <div
                                  className={
                                    errors.email && touched.email
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <ErrorMessage
                                    name="email"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Primary Contact Account Information</h6>
                            <div className="mb-5">
                              <div className="input-box  form-floating">
                                <Field
                                  type="text"
                                  name="jobTitle"
                                  id="jobTitle"
                                  placeholder="Job Title"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">Job Title</label>
                                <div
                                  className={
                                    errors.jobTitle && touched.jobTitle
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="jobTitle"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mb-5">
                              <div className="input-box  form-floating">
                                <Field
                                  type="text"
                                  name="username"
                                  id="username"
                                  placeholder="Username"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">Username</label>
                                <div
                                  className={
                                    errors.username && touched.username
                                      ? "input-error"
                                      : null
                                  }
                                >
                                  <i className="bi bi-check-lg"></i>
                                  <ErrorMessage
                                    name="username"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5">
                          <button
                            type="button"
                            className="btn cancel-btn mb-3 mb-md-0 me-4"
                          >
                            CANCEL
                          </button>
                          <button
                            type="submit"
                            className="btn next-btn mb-3 mb-md-0"
                          // onClick={() => {
                          //   nextstep();
                          // }}
                          >
                            NEXT
                          </button>
                        </div>
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CreateIssuer;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import {
  getCountryCodeDropDown,
  getDailingDropDown,
  getDropdownvalues,
} from "../../../../API/dropdown";
import { importBranchBatch } from "../../../../API/branches";
import { setPrivateId } from "../../../../action";
import { decrypytdata } from "../../../../API/walletkey";
import { success } from "../../../../Components/alert";

function CreateIssuer({ nextstep, setdata }) {
  let { pathname } = useLocation();
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const [dropdown, setDropdown] = useState([]);
  const [institutionTypeCode, setInstitutionTypeCode] = useState([]);
  const [officeTypeCode, setOfficeTypeCode] = useState([]);
  const [countryCode, setCountryCode] = useState([]);
  const [dailingCode, setDailingCode] = useState([]);
  const [BranchData, setBranchData] = useState({});
  const publicKeys = useSelector((state) => state.keyReducer);


  
  // const initialValues = {
  //   group: "",
  //   nativeID: "",
  //   displayname: "Aditya",
  //   addressLine1: "25 jyoti villa",
  //   addressLine2: "Nr. Station",
  //   addressLine3: "",
  //   neighborhood: "jain School",
  //   cityTown: "Mumbai",
  //   stateProvince: "MH",
  //   postalCode: "49007",
  //   countrycode: "IN",
  //   statecode: "",
  //   citycode: "",
  //   issuerTypecode: "",
  //   issuercode: "Unicoin",
  //   uuid: "",
  //   username: "Rajnish",
  //   firstname: "Raj",
  //   secondFirstname: "",
  //   lastname: "Sardar",
  //   secondLastname: "",
  //   jobTitle: "Teacher",
  //   imageFN: "",
  //   email: "Aditya@gmail.com",
  //   mobileDialingcode: "91",
  //   mobilePhone: "8673582190",
  // };

  // const initialValues4 = {
  //   accuityID: 0,
  //   branchcode: "",
  //   issuercode: "Unicoin",
  //   hqIssuercode: "Unicoin",
  //   issuerTypecode: "",
  //   branchname: "AmbhujaCo.Head",
  //   displayname: "AmbhujaCo.Head",
  //   institutionTypecode: "",
  //   officeTypecode: "",
  //   nativeKEY: "",
  //   issuerUUID: "",
  //   incorporationNumber: "",
  //   federal_TaxID: "",
  //   isO_LEI: "",
  //   addressLine1: "29 Someshwar",
  //   addressLine2: "Gate no 2",
  //   addressLine3: "",
  //   neighborhood: "",
  //   cityTown: "Kutch",
  //   stateProvince: "Gujarat",
  //   postalCode: "59009",
  //   countrycode: "",
  //   username: "Dilip",
  //   userUUID: "",
  //   firstname: "DilipBhai",
  //   secondFirstname: "",
  //   lastname: "Tomar",
  //   secondLastname: "",
  //   jobTitle: "Teacher",
  //   email: "AdityaBirla@gmail.com",
  //   mobileDialingcode: "",
  //   mobilePhone: "5482673195",
  //   imageFN: "",
  // };
  // const initialValues1 = {
  //   accuityID: 0,
  //   branchcode: "Coin815",
  //   issuercode: "",
  //   hqIssuercode: "Coin815",
  //   issuerTypecode: "",
  //   branchname: "",
  //   displayname: "",
  //   institutionTypecode: "",
  //   officeTypecode: "",
  //   nativeKEY: "",
  //   issuerUUID: "",
  //   incorporationNumber: "",
  //   federal_TaxID: "",
  //   isO_LEI: "",
  //   addressLine1: "",
  //   addressLine2: "",
  //   addressLine3: "",
  //   neighborhood: "",
  //   cityTown: "",
  //   stateProvince: "",
  //   postalCode: "",
  //   countrycode: "",
  //   username: "",
  //   userUUID: "",
  //   firstname: "",
  //   secondFirstname: "",
  //   lastname: "",
  //   secondLastname: "",
  //   jobTitle: "",
  //   email: "",
  //   mobileDialingcode: "",
  //   mobilePhone: "",
  //   imageFN: "",
  // };
  // const initialValues2 = {
  //   accuityID: 0,
  //   branchcode: "Coin815",
  //   issuercode: "Unicoin.",
  //   hqIssuercode: "Coin815",
  //   issuerTypecode: "BANK",
  //   branchname: "Coin",
  //   displayname: "AmbhujaCo.",
  //   institutionTypecode: "02",
  //   officeTypecode: "05",
  //   nativeKEY: "",
  //   issuerUUID: "",
  //   incorporationNumber: "791",
  //   federal_TaxID: "EXE44",
  //   isO_LEI: "SDF",
  //   addressLine1: "201 Mega road",
  //   addressLine2: "202 road",
  //   addressLine3: "",
  //   neighborhood: "",
  //   cityTown: "Baroda",
  //   stateProvince: "Gujarat",
  //   postalCode: "4561789",
  //   countrycode: "IN",
  //   username: "mehul001",
  //   userUUID: "",
  //   firstname: "mehul",
  //   secondFirstname: "kumar",
  //   lastname: "Patel",
  //   secondLastname: "",
  //   jobTitle: "Teacher",
  //   email: "gaxy@mailinator.com",
  //   mobileDialingcode: "91",
  //   mobilePhone: "1929891839",
  //   imageFN: "",
  //   issuer: "Coin815",
  // };

  // const temp = {
  //   accuityID: 0,
  //   branchcode: "KevinBr",
  //   issuercode: "Unicoin",
  //   hqIssuercode: "Unicoin",
  //   issuerTypecode: "",
  //   branchname: "KevinBr",
  //   displayname: "Kevin",
  //   institutionTypecode: "",
  //   officeTypecode: "",
  //   nativeID: "",
  //   issuerUUID: "",
  //   incorporationNumber: "",
  //   federal_TaxID: "",
  //   isO_LEI: "",
  //   addressLine1: "29 Someshwar",
  //   addressLine2: "Gate no 2",
  //   addressLine3: "",
  //   neighborhood: "",
  //   cityTown: "Kutch",
  //   stateProvince: "Gujarat",
  //   statecode: "",
  //   citycode: "",
  //   postalCode: "59009",
  //   countrycode: "",
  //   timezoneID: "",
  //   username: "Kevin01",
  //   userUUID: "",
  //   firstname: "Kevin",
  //   secondFirstname: "",
  //   lastname: "Tom",
  //   secondLastname: "",
  //   jobTitle: "Teacher",
  //   email: "Kevin01@gmail.com",
  //   mobileDialingcode: "",
  //   mobilePhone: "5482673195",
  //   imageFN: "",
  //   password: "",
  // };

  let APPID = "";
  let APIKEY = "";
  const dispatch = useDispatch();

  const image = BranchData;
  const formData = new FormData();
  formData.append("image", image);
  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
      /* console.log(
        "🚀 ~ file: ReviewIssuer.js ~ line 29 ~ closeModal ~ APIKEY",
        APIKEY
      ); */
    }
    if (APPID && APIKEY) {
      /* console.log(
        "🚀 ~ file: ReviewIssuer.js ~ line 24 ~ closeModal ~ APPID",
        APPID
      );
 */
      const encryptedIssuer = await importBranchBatch(APPID, APIKEY, formData);
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      // debugger;
      const jsonData = JSON.parse(devryptedIssuer);
      /* console.log(
        "🚀 ~ file: ReviewIssuer.js ~ line 42 ~ closeModal ~ jsonData",
        jsonData
      ); */
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
      // dispatch(setPrivateId(""));
      // debugger;
    }
    // setPrivateModal(false);
  };

  const initialValuesNew = {
    accuityID: 0,
    branchcode: "",
    issuercode: "",
    hqIssuercode: "",
    issuerTypecode: "",
    branchname: "",
    displayname: "",
    institutionTypecode: "",
    officeTypecode: "",
    nativeID: "",
    issuerUUID: "",
    incorporationNumber: "",
    federal_TaxID: "",
    isO_LEI: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    neighborhood: "",
    cityTown: "",
    stateProvince: "",
    statecode: "",
    citycode: "",
    postalCode: "",
    countrycode: "",
    timezoneID: "",
    username: "",
    userUUID: "",
    firstname: "",
    secondFirstname: "",
    lastname: "",
    secondLastname: "",
    jobTitle: "",
    email: "",
    mobileDialingcode: "",
    mobilePhone: "",
    imageFN: "",
    password: "",
  };
  const validationSchema = Yup.object({
    // issuer: Yup.string()
    //   .max(15, "Must be 15 characters or less")
    //   .required("Issuer required"),
    // issuercode: Yup.string().required("IssuerCode is required!"),
    // nativeKEY: Yup.string(),
    // issuerUUID: Yup.string(),
    // hqIssuercode: Yup.string(),
    // displayname: Yup.string()
    //   .max(20, "Must be 20 characters or less")
    //   .required("Displayname Required"),
    // issuerTypecode: Yup.string().required("IssuerType is required!"),
    // accuityID: Yup.number().required("accuityID is required!"),
    institutionTypecode: Yup.string().required(
      "InstitutionTypecode is required!"
    ),
    countrycode: Yup.string().required("CountryCode is required!"),
    stateProvince: Yup.string().required("State Province is required!"),
    cityTown: Yup.string().required("City Town is required!"),
    postalCode: Yup.string().required("Postal Code is required!"),
    officeTypecode: Yup.string().required("officeTypecode is required!"),
    firstname: Yup.string().required("First Name is required!"),
    // secondFirstname: Yup.string(),
    lastname: Yup.string().required("Last Name is required!"),
    // secondLastname: Yup.string(),
    email: Yup.string().required("email is required!"),
    jobTitle: Yup.string().required("jobTitle is required!"),
    username: Yup.string().required("username is required!"),
    addressLine1: Yup.string().required("AddressLine1 is required!"),
    addressLine2: Yup.string().required("AddressLine2 is required!"),
    // addressLine3: Yup.string().required("AddressLine3 is required!"),
    // neighborhood: Yup.string().required("cityTown is required!"),
    // userUUID: Yup.string(),
    mobileDialingcode: Yup.string()
      .typeError("you must specify a number")
      .required("DialingCode is required!"),
    mobilePhone: Yup.number()
      .typeError("you must specify a number")
      .max(9999999999, "Must be 10 Digit")
      .min(1111111111, "Must be 10 Digit")
      .required("PhoneNumber is required!"),
    // incorporationNumber: Yup.string().required("cityTown is required!"),
    // federal_TaxID: Yup.string(),
    // isO_LEI: Yup.string(),
    // imageFN: Yup.string(),
  });
  const fetchdropdwon = async () => {
    let dropdownvalues = await getDropdownvalues("LKIssuerType");
    setDropdown(dropdownvalues.commandDataset);

    let insTypeCode = await getDropdownvalues("LKInstitutionType");
    setInstitutionTypeCode(insTypeCode.commandDataset);

    let OfficeTypeCode = await getDropdownvalues("LKOfficeType");
    setOfficeTypeCode(OfficeTypeCode.commandDataset);

    let countryType = await getCountryCodeDropDown("GetWorldCurrency");
    setCountryCode(countryType);

    let dialing = await getDailingDropDown("GetDialingcodes");
    setDailingCode(dialing);
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
        fetchdropdwon();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  return (
    <>
      {isValidPublicAddress && (
        <div className="col-md  ms-lg-4 ms-xl-5">
          <div className="main-heading">
            {/* <i className="bi bi-arrow-left"></i> */}
            <h3>Branches</h3>
            <h6>
              {pathname === "/controlpanel/modifybranch"
                ? "Modify Branch"
                : "Create Branch"}
            </h6>
            <p>This function associates a branch location with an issuer.</p>
          </div>
          <div className="transfer-rates-page">
            <div className="transfer-rates">
              <ul
                className="nav nav-tabs justify-content-around"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    <h3 className="m-0 ">Single </h3>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    <h3 className="m-0">Batch </h3>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#contact"
                    type="button"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    <h3 className="m-0">File Format</h3>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="tab-content mt-4" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div className="ledger-information create-issuer-information">
                <div className="row">
                  <div className="col-12">
                    <div className="sub-heading"></div>
                    <Formik
                      initialValues={initialValuesNew}
                      validationSchema={validationSchema}
                      enableReinitialize={true}
                      onSubmit={(values) => {
                        setdata(values);
                        /* console.log(
                          "🚀 ~ file: Originator.js ~ line 121 ~ Originator ~ values",
                          values
                        ); */
                        //   CreateLedgerApi(values);
                        nextstep();
                      }}
                    >
                      {(formik) => {
                        const { errors, touched, setFieldValue } = formik;
                        return (
                          <div className="wallet-information-box m-0">
                            <h4>Branches Information</h4>

                            <Form className="wallet-information-form">
                              <div className="row">
                                <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                  <h6>Basic Information</h6>
                                  <div className="mb-5">
                                    <div className="input-box form-floating">
                                      <Field
                                        type="text"
                                        name="branchname"
                                        id="branchname"
                                        placeholder="branchname"
                                        className="form-control"
                                      />
                                      <label className="ps-0 pb-0">
                                        Branches
                                      </label>
                                      <div
                                        className={
                                          errors.branchname && touched.branchname
                                            ? "input-error"
                                            : null
                                        }
                                      >
                                        <i className="bi bi-check-lg"></i>
                                        <ErrorMessage
                                          name="branchname"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mb-5">
                                    <div className="input-box form-floating">
                                      <Field
                                        type="text"
                                        name="displayname"
                                        id="displayname"
                                        placeholder="Display Name"
                                        className="form-control"
                                      />
                                      <label className="ps-0 pb-0">
                                        Display Name
                                      </label>
                                      <div
                                        className={
                                          errors.displayname &&
                                          touched.displayname
                                            ? "input-error"
                                            : null
                                        }
                                      >
                                        <i className="bi bi-check-lg"></i>
                                        <ErrorMessage
                                          name="displayname"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mb-5">
                                    <label>Branch Type</label>
                                    <Field
                                      as="select"
                                      className="form-select form-control p-2"
                                      id="issuerTypecode"
                                      name="issuerTypecode"
                                      onChange={(e) => {
                                        // console.log(e.target.value, "Issuer Type");
                                        setFieldValue(
                                          "issuerTypecode",
                                          e.target.value
                                        );
                                      }}
                                      onBlur={formik.handleBlur}
                                    >
                                      the data grid, so could
                                      {dropdown &&
                                        dropdown.map((value, index) => {
                                          return (
                                              <option
                                                value={value.Keycode}
                                                key={index}
                                              >
                                                {value.Displayname}
                                              </option>
                                          );
                                        })}
                                    </Field>
                                    <div>
                                      <ErrorMessage
                                        name="issuerTypecode"
                                        component="span"
                                        className="error"
                                        style={{ color: "red" }}
                                      />
                                    </div>
                                  </div>
                                  <div className="mb-5">
                                    <div className="input-box form-floating">
                                      <Field
                                        type="text"
                                        name="branchcode"
                                        id="branchcode"
                                        placeholder="Branch Code"
                                        className="form-control"
                                      />
                                      <label className="ps-0 pb-0">
                                        Branch Code
                                      </label>
                                      <div
                                        className={
                                          errors.branchcode &&
                                          touched.branchcode
                                            ? "input-error"
                                            : null
                                        }
                                      >
                                        <i className="bi bi-check-lg"></i>
                                        <ErrorMessage
                                          name="branchcode"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                  <h6>Institution </h6>
                                  <div className="mb-5">
                                    <label>Institution Typecode</label>
                                    <div className="input-box">
                                      <Field
                                        as="select"
                                        className="form-select form-control p-2"
                                        id="institutionTypecode"
                                        name="institutionTypecode"
                                        onChange={(e) => {
                                          // console.log(
                                          //   e.target.value,
                                          //   "Institution Typecode"
                                          // );
                                          setFieldValue(
                                            "institutionTypecode",
                                            e.target.value
                                          );
                                        }}
                                        onBlur={formik.handleBlur}
                                      >
                                        {institutionTypeCode &&
                                          institutionTypeCode.map(
                                            (value, index) => {
                                              return (
                                                  <option
                                                    value={value.Keycode}
                                                    key={index}
                                                  >
                                                    {value.Displayname}
                                                  </option>
                                              );
                                            }
                                          )}
                                      </Field>
                                      <div
                                        className={
                                          errors.institutionTypecode &&
                                          touched.institutionTypecode
                                            ? "input-error"
                                            : null
                                        }
                                      >
                                        <ErrorMessage
                                          name="institutionTypecode"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="mb-5">
                                    <label>Office Typecode</label>
                                    <div className="input-box">
                                      <Field
                                        as="select"
                                        name="officeTypecode"
                                        id="officeTypecode"
                                        // placeholder="Office Typecode"
                                        className="form-select form-control p-2"
                                        onChange={(e) => {
                                          // console.log(
                                          //   e.target.value,
                                          //   "Office Typecode"
                                          // );
                                          setFieldValue(
                                            "officeTypecode",
                                            e.target.value
                                          );
                                        }}
                                        onBlur={formik.handleBlur}
                                      >
                                        {officeTypeCode &&
                                          officeTypeCode.map((value, index) => {
                                            return (
                                                <option
                                                  value={value.Keycode}
                                                  key={index}
                                                >
                                                  {value.Displayname}
                                                </option>
                                            );
                                          })}
                                      </Field>
                                      <div
                                        className={
                                          errors.officeTypecode &&
                                          touched.officeTypecode
                                            ? "input-error"
                                            : null
                                        }
                                      >
                                        <ErrorMessage
                                          name="officeTypecode"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                  <h6>Identifier</h6>
                                  <div className="mb-5">
                                    <div className="input-box form-floating">
                                      <Field
                                        type="number"
                                        name="accuityID"
                                        id="accuityID"
                                        placeholder="Location Identifier"
                                        className="form-control"
                                      />
                                      <label className="ps-0 pb-0">
                                        Location Identifier
                                      </label>
                                      <div
                                        className={
                                          errors.accuityID && touched.accuityID
                                            ? "input-error"
                                            : null
                                        }
                                      >
                                        <i className="bi bi-check-lg"></i>
                                        <ErrorMessage
                                          name="accuityID"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                  <h6>Additional Informaiton</h6>
                                  <div className="mb-5">
                                    <div className="input-box form-floating">
                                      <Field
                                        type="text"
                                        name="incorporationNumber"
                                        id="incorporationNumber"
                                        placeholder="Incorporation Number"
                                        className="form-control"
                                      />
                                      <label className="ps-0 pb-0">
                                        Incorporation Number{" "}
                                      </label>
                                      <div
                                        className={
                                          errors.incorporationNumber &&
                                          touched.incorporationNumber
                                            ? "input-error"
                                            : null
                                        }
                                      >
                                        <i className="bi bi-check-lg"></i>
                                        <ErrorMessage
                                          name="incorporationNumber"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mb-5">
                                    <div className="input-box form-floating">
                                      <Field
                                        type="text"
                                        name="federal_TaxID"
                                        id="federal_TaxID"
                                        placeholder="Tax ID"
                                        className="form-control"
                                      />
                                      <label className="ps-0 pb-0">
                                        Federal TaxID{" "}
                                      </label>
                                      <div
                                        className={
                                          errors.federal_TaxID &&
                                          touched.federal_TaxID
                                            ? "input-error"
                                            : null
                                        }
                                      >
                                        <i className="bi bi-check-lg"></i>
                                        <ErrorMessage
                                          name="federal_TaxID"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mb-5">
                                    <div className="input-box form-floating">
                                      <Field
                                        type="text"
                                        name="isO_LEI"
                                        id="isO_LEI"
                                        placeholder="ISO LEI"
                                        className="form-control"
                                      />
                                      <label className="ps-0 pb-0">
                                        ISO LEI{" "}
                                      </label>
                                      <div
                                        className={
                                          errors.isO_LEI && touched.isO_LEI
                                            ? "input-error"
                                            : null
                                        }
                                      >
                                        <i className="bi bi-check-lg"></i>
                                        <ErrorMessage
                                          name="isO_LEI"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                  <h6>Basic Address Information</h6>
                                  <div className="mb-5">
                                    <label>Country Code</label>
                                    <Field
                                      as="select"
                                      className="form-select form-control  p-2"
                                      id="countrycode"
                                      name="countrycode"
                                      onChange={(e) => {
                                        // console.log(e.target.value, "Country Code");
                                        setFieldValue(
                                          "countrycode",
                                          e.target.value
                                        );
                                      }}
                                      onBlur={formik.handleBlur}
                                    >
                                      {countryCode &&
                                        countryCode.map((value, index) => {
                                          return (
                                              <option
                                                value={value.countrycode}
                                                key={index}
                                              >
                                                {value.isoCountryname}
                                              </option>
                                          );
                                        })}
                                    </Field>
                                    <div>
                                      <ErrorMessage
                                        name="countrycode"
                                        component="span"
                                        className="error"
                                        style={{ color: "red" }}
                                      />
                                    </div>
                                  </div>
                                  <div className="mb-5">
                                    <div className="input-box form-floating">
                                      <Field
                                        type="text"
                                        name="stateProvince"
                                        id="stateProvince"
                                        placeholder="State Province"
                                        className="form-control"
                                      />
                                      <label className="ps-0 pb-0">
                                        State Province{" "}
                                      </label>
                                      <div
                                        className={
                                          errors.stateProvince &&
                                          touched.stateProvince
                                            ? "input-error"
                                            : null
                                        }
                                      >
                                        <i className="bi bi-check-lg"></i>
                                        <ErrorMessage
                                          name="stateProvince"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mb-5">
                                    <div className="input-box form-floating">
                                      <Field
                                        type="text"
                                        name="cityTown"
                                        id="cityTown"
                                        placeholder="City Town"
                                        className="form-control"
                                      />
                                      <label className="ps-0 pb-0">
                                        City Town{" "}
                                      </label>
                                      <div
                                        className={
                                          errors.cityTown && touched.cityTown
                                            ? "input-error"
                                            : null
                                        }
                                      >
                                        <i className="bi bi-check-lg"></i>
                                        <ErrorMessage
                                          name="cityTown"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                  <h6>Address</h6>
                                  <div className="mb-5">
                                    <div className="input-box form-floating">
                                      <Field
                                        type="text"
                                        name="postalCode"
                                        id="postalCode"
                                        placeholder="Postal code"
                                        className="form-control"
                                      />
                                      <label className="ps-0 pb-0">
                                        Postalcode{" "}
                                      </label>
                                      <div
                                        className={
                                          errors.postalCode &&
                                          touched.postalCode
                                            ? "input-error"
                                            : null
                                        }
                                      >
                                        <i className="bi bi-check-lg"></i>
                                        <ErrorMessage
                                          name="postalCode"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mb-5">
                                    <div className="input-box form-floating">
                                      <Field
                                        type="text"
                                        name="addressLine1"
                                        id="addressLine1"
                                        placeholder="Address Line1"
                                        className="form-control"
                                      />
                                      <label className="ps-0 pb-0">
                                        AddressLine1{" "}
                                      </label>
                                      <div
                                        className={
                                          errors.addressLine1 &&
                                          touched.addressLine1
                                            ? "input-error"
                                            : null
                                        }
                                      >
                                        <i className="bi bi-check-lg"></i>
                                        <ErrorMessage
                                          name="addressLine1"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mb-5">
                                    <div className="input-box form-floating">
                                      <Field
                                        type="text"
                                        name="addressLine2"
                                        id="addressLine2"
                                        placeholder="Address Line2"
                                        className="form-control"
                                      />
                                      <label className="ps-0 pb-0">
                                        AddressLine2{" "}
                                      </label>
                                      <div
                                        className={
                                          errors.addressLine2 &&
                                          touched.addressLine2
                                            ? "input-error"
                                            : null
                                        }
                                      >
                                        <i className="bi bi-check-lg"></i>
                                        <ErrorMessage
                                          name="addressLine2"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <h4>Primary Contact Information</h4>
                              <div className="row mb-xl-5">
                                <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                  <h6>Primary Contact</h6>
                                  <div className="mb-5">
                                    <div className="input-box form-floating">
                                      <Field
                                        type="text"
                                        name="firstname"
                                        id="firstname"
                                        placeholder="First Name"
                                        className="form-control"
                                      />
                                      <label className="ps-0 pb-0">
                                        First Name
                                      </label>
                                      <div
                                        className={
                                          errors.firstname && touched.firstname
                                            ? "input-error"
                                            : null
                                        }
                                      >
                                        <i className="bi bi-check-lg"></i>
                                        <ErrorMessage
                                          name="firstname"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mb-5">
                                    <div className="input-box form-floating">
                                      <Field
                                        type="text"
                                        name="secondFirstname"
                                        id="secondFirstname"
                                        placeholder="Second First name"
                                        className="form-control"
                                      />
                                      <label className="ps-0 pb-0">
                                        Second First Name
                                      </label>
                                      <div
                                        className={
                                          errors.secondFirstname &&
                                          touched.secondFirstname
                                            ? "input-error"
                                            : null
                                        }
                                      >
                                        <i className="bi bi-check-lg"></i>
                                        <ErrorMessage
                                          name="secondFirstname"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mb-5">
                                    <div className="input-box form-floating">
                                      <Field
                                        type="text"
                                        name="lastname"
                                        id="lastname"
                                        placeholder="Last Name"
                                        className="form-control"
                                      />
                                      <label className="ps-0 pb-0">
                                        Last Name
                                      </label>
                                      <div
                                        className={
                                          errors.lastname && touched.lastname
                                            ? "input-error"
                                            : null
                                        }
                                      >
                                        <i className="bi bi-check-lg"></i>
                                        <ErrorMessage
                                          name="lastname"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mb-5">
                                    <div className="input-box form-floating">
                                      <Field
                                        type="text"
                                        name="secondLastname"
                                        id="secondLastname"
                                        placeholder="Second Last Name"
                                        className="form-control"
                                      />
                                      <label className="ps-0 pb-0">
                                        Second Last Name
                                      </label>
                                      <div
                                        className={
                                          errors.secondLastname &&
                                          touched.secondLastname
                                            ? "input-error"
                                            : null
                                        }
                                      >
                                        <i className="bi bi-check-lg"></i>
                                        <ErrorMessage
                                          name="secondLastname"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                  <h6>Contact Information</h6>
                                  <div className="mb-5">
                                    <label>Dialing Code</label>
                                    <Field
                                      as="select"
                                      className="form-select form-control p-2"
                                      id="mobileDialingcode"
                                      name="mobileDialingcode"
                                      onChange={(e) => {
                                        // console.log(e.target.value, "Dialing Code");
                                        setFieldValue(
                                          "mobileDialingcode",
                                          e.target.value
                                        );
                                      }}
                                      onBlur={formik.handleBlur}
                                    >
                                      {dailingCode &&
                                        dailingCode.map((value, index) => {
                                          return (
                                              <option
                                                value={value.keycode}
                                                key={index}
                                              >
                                                {value.displayname}
                                              </option>
                                          );
                                        })}
                                    </Field>
                                    <div>
                                      <ErrorMessage
                                        name="mobileDialingcode"
                                        component="span"
                                        className="error"
                                        style={{ color: "red" }}
                                      />
                                    </div>
                                  </div>
                                  <div className="mb-5">
                                    <div className="input-box form-floating">
                                      <Field
                                        type="text"
                                        name="mobilePhone"
                                        id="mobilePhone"
                                        placeholder="Phone Number"
                                        className="form-control"
                                      />
                                      <label className="ps-0 pb-0">
                                        Phone Number
                                      </label>
                                      <div
                                        className={
                                          errors.mobilePhone &&
                                          touched.mobilePhone
                                            ? "input-error"
                                            : null
                                        }
                                      >
                                        <i className="bi bi-check-lg"></i>
                                        <ErrorMessage
                                          name="mobilePhone"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="mb-5">
                                    <div className="input-box form-floating">
                                      <Field
                                        type="text"
                                        name="email"
                                        id="email"
                                        placeholder="Email Address"
                                        className="form-control"
                                      />
                                      <label className="ps-0 pb-0">
                                        Email Address{" "}
                                      </label>
                                      <i className="bi bi-check-lg"></i>
                                      <div
                                        className={
                                          errors.email && touched.email
                                            ? "input-error"
                                            : null
                                        }
                                      >
                                        <ErrorMessage
                                          name="email"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                  <h6>Primary Contact Account Information</h6>
                                  <div className="mb-5">
                                    <div className="input-box form-floating">
                                      <Field
                                        type="text"
                                        name="jobTitle"
                                        id="jobTitle"
                                        placeholder="Job Title"
                                        className="form-control"
                                      />
                                      <label className="ps-0 pb-0">
                                        Job Title
                                      </label>
                                      <div
                                        className={
                                          errors.jobTitle && touched.jobTitle
                                            ? "input-error"
                                            : null
                                        }
                                      >
                                        <i className="bi bi-check-lg"></i>
                                        <ErrorMessage
                                          name="jobTitle"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mb-5">
                                    <div className="input-box form-floating">
                                      <Field
                                        type="text"
                                        name="username"
                                        id="username"
                                        placeholder="Username"
                                        className="form-control"
                                      />
                                      <label className="ps-0 pb-0">
                                        Username
                                      </label>
                                      <div
                                        className={
                                          errors.username && touched.username
                                            ? "input-error"
                                            : null
                                        }
                                      >
                                        <i className="bi bi-check-lg"></i>
                                        <ErrorMessage
                                          name="username"
                                          component="span"
                                          className="error"
                                          style={{ color: "red" }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5">
                                <button
                                  type="button"
                                  className="btn cancel-btn mb-3 mb-md-0 me-4"
                                >
                                  CANCEL
                                </button>
                                <button
                                  type="submit"
                                  className="btn next-btn mb-3 mb-md-0"
                                >
                                  NEXT
                                </button>
                              </div>
                            </Form>
                          </div>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div className="row">
                {/*<h5 className="mb-3">*/}
                {/*    Upload a Limit Rules File in the proper*/}
                {/*    file format for batched processing*/}
                {/*</h5>*/}
                <div className="col-sm-6 col-xl-4">
                  <div className="mb-5">
                    <label className="mb-3">Batch Upload</label>
                    <div className="input-box">
                      <div className="input-group custom-file-button">
                        <input
                          type="file"
                          className="form-control"
                          id="inputGroupFile"
                          onChange={(e) => {
                            setBranchData(e.target.files[0]);
                          }}
                        />
                        <label className="input-group-text" htmlFor="inputGroupFile">
                          BROWSE
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-11">
                <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                  {/* <button
                type="button"
                className="btn cancel-btn mb-3 mb-md-0 me-4"
                onClick={previous}
              >
                PREVIOUS
              </button> */}
                  <button
                    type="submit"
                    className="btn next-btn mb-3 mb-md-0"
                    onClick={closeModal}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="contact"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <div className="row">
                <h5 className="mb-3">
                  Upload a pipe delimited ("|") file with the following fields
                  in sequential order. The first row is the field names header
                  and is ignored.
                </h5>
                <div className="wallet-balances-box m-0">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Field Name</th>
                          <th>Field Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/*<tr>*/}
                        {/*    <td>First Name</td>*/}
                        {/*    <td>*/}
                        {/*        The first name of the user{" "}*/}
                        {/*    </td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>Middle Name</td>*/}
                        {/*    <td>*/}
                        {/*        The middle name of the user{" "}*/}
                        {/*    </td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>Email</td>*/}
                        {/*    <td>The email address </td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td>Password</td>*/}
                        {/*    <td>The login password </td>*/}
                        {/*</tr>*/}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CreateIssuer;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPrivateId, showLoader } from "../../../../action";
import { decrypytdata } from "../../../../API/walletkey";
import { createWallet } from "../../../../API/Wallets";
import { success } from "../../../../Components/alert";
// import PrivateModal from "../../../../Components/PrivateModal";
 

function ModifyReview({ walletData,peopleData,entityData,cleandata,previous }) {

  // const [privateModal, setPrivateModal] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  let APPID = "";
  let APIKEY = "";
  const dispatch = useDispatch();

  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedIssuer = await createWallet(APPID, APIKEY,walletData );
      dispatch(showLoader(false));
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
      // debugger;
      const jsonData = JSON.parse(devryptedIssuer);
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
      // dispatch(setPrivateId(""));
      // debugger;
    }
    // setPrivateModal(false);
  };
  useEffect(() => {
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);
  return (
    <>
      {/* {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
      )} */}
      <div className="row">
        <div className="col-xxl-11">
          <div className="review-wallet review-new-token">
            <div className="container-fluid p-0 ">
              <div className="row m-0">
                <div className="col-md-12 mt-5">
                  <div className="review-wallet-box ms-0">
                    <div className="row">
                      <div className="col-xxl-12">
                        <div className="review-wallet-info-box mb-5">
                          <div className="row">
                            <div className="col-12">
                              <div className="heading">
                                <h3>Wallet Information</h3>
                                <i className="bi bi-pencil-square"></i>
                              </div>
                              <hr className="mb-4" />
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                              <h6>Basic Information</h6>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>First Name</h4>
                                  <p>{peopleData.firstname}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Middle Name</h4>
                                  <p>{peopleData.secondFirstname}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Last Name</h4>
                                  <p>{peopleData.lastname}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Second Last Name</h4>
                                  <p>{peopleData.secondLastname}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Business Name</h4>
                                  <p> </p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Industry</h4>
                                  <p></p>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                              <h6>Home Address</h6>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Country</h4>
                                  <p>{peopleData.birthCountrycode}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>State</h4>
                                  <p>{entityData.stateProvince}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>City</h4>
                                  <p>{entityData.cityTown}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Address Line 1</h4>
                                  <p>{entityData.addressLine1}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Address Line 2</h4>
                                  <p>{entityData.addressLine2}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Postal Code</h4>
                                  <p>{entityData.postalcode}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                              <h6>Identification</h6>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Date of Birth</h4>
                                  <p>{peopleData.dob}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Government Birth Number</h4>
                                  <p>{peopleData.birthGovernmentNumber}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Passport Country</h4>
                                  <p>{peopleData.passportCountrycode}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Passport Number</h4>
                                  <p>{peopleData.passportNumber}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Driver's License Number</h4>
                                  <p>{peopleData.driversLicenseNumber}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Other Government ID Number</h4>
                                  <p>{peopleData.otherIDNumber}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                              <h6>Contact Information</h6>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Email Address</h4>
                                  <p>{peopleData.tertiaryEmail}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Mobile Dialing Country Code</h4>
                                  <p>{peopleData.tertiaryDialingcode}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Mobile Number</h4>
                                  <p>{peopleData.tertiaryPhoneNumber}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-lg-4 col-xl-3">
                              <h6>Wallet Information</h6>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Primary Currency</h4>
                                  <p>{walletData.currencycodeList}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Overdraft Protection Enabled</h4>
                                  <p>{walletData.overdraftProtection}</p>
                                </div>
                              </div>
                              <div className="wallet-list">
                                <i className="bi bi-check"></i>
                                <div>
                                  <h4>Overdraft Limit</h4>
                                  <p>{walletData.overdraftLimit}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-11 p-0">
          <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
            <button
              type="button"
              className="btn cancel-btn mb-3 mb-md-0 me-4"
              onClick={() => {
                cleandata();
                previous();
                // dispatch(setPrivateId(""));
              }}
            >
              Previous
            </button>
            <button type="button" className="btn cancel-btn mb-3 mb-md-0 me-4">
              CANCEL
            </button>
            <button
              type="button"
              className="btn next-btn mb-3 mb-md-0"
              onClick={() => {
                // setPrivateModal(true);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModifyReview;


// import React, { useState, useEffect } from "react";
// import { setPrivateId } from "../../../../../action/index";
// import { useLocation } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { createWallet } from "../../../../../api/Wallets";
// import { decrypytdata } from "../../../../../api/walletKey";
// import PrivateModal from "./../../../../../Components/PrivateModal";
// import { success } from "./../../../../../Components/alert";

// function Review({ wallet, previous, cleandata }) {
//   let { pathname } = useLocation();
//   const [privateModal, setPrivateModal] = useState(false);
//   const publicKeys = useSelector((state) => state.keyReducer);
//   let APPID = "";
//   let APIKEY = "";
//   const dispatch = useDispatch();

//   const closeModal = async () => {
//     if (publicKeys.publicKey) {
//       APPID = publicKeys.publicKey.publicAddress;
//     }
//     if (publicKeys.privateKey) {
//       APIKEY = publicKeys.privateKey.PrivateWalletAddress;
//     }
//     if (APPID && APIKEY) {
//       console.log(pathname, "pathnamepathname");

//       const encryptedIssuer = await createWallet(APPID, APIKEY, wallet);
//       const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
//       // debugger;
//       const jsonData = JSON.parse(devryptedIssuer);
//       if (jsonData.returnMessage) {
//         success(jsonData.returnMessage);
//       }
//       dispatch(setPrivateId(""));
//       // debugger;
//     }
//     setPrivateModal(false);
//   };
//   useEffect(() => {
//     closeModal();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [publicKeys]);
//   return (
//     <>
//       {privateModal && (
//         <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
//       )}
//       <div className="row">
//         <div className="col-xxl-11">
//           <div className="review-wallet review-new-token">
//             <div className="container-fluid p-0 ">
//               <div className="row m-0">
//                 <div className="col-md-12 mt-5">
//                   <div className="review-wallet-box ms-0">
//                     <div className="row">
//                       <div className="col-xxl-12">
//                         <div className="review-wallet-info-box mb-5">
//                           <div className="row">
//                             <div className="col-12">
//                               <div className="heading">
//                                 <h3>Wallet Information</h3>
//                                 <i className="bi bi-pencil-square"></i>
//                               </div>
//                               <hr className="mb-4" />
//                             </div>
//                             <div className="col-sm-6 col-lg-4 col-xl-3">
//                               <h6>Basic Information</h6>
//                               <div className="wallet-list">
//                                 <i className="bi bi-check"></i>
//                                 <div>
//                                   <h4>First Name</h4>
//                                   <p>Thomas</p>
//                                 </div>
//                               </div>
//                               <div className="wallet-list">
//                                 <i className="bi bi-check"></i>
//                                 <div>
//                                   <h4>Middle Name</h4>
//                                   <p>User</p>
//                                 </div>
//                               </div>
//                               <div className="wallet-list">
//                                 <i className="bi bi-check"></i>
//                                 <div>
//                                   <h4>Last Name</h4>
//                                   <p>User</p>
//                                 </div>
//                               </div>
//                               <div className="wallet-list">
//                                 <i className="bi bi-check"></i>
//                                 <div>
//                                   <h4>Second Last Name</h4>
//                                   <p>Token</p>
//                                 </div>
//                               </div>
//                               <div className="wallet-list">
//                                 <i className="bi bi-check"></i>
//                                 <div>
//                                   <h4>Business Name</h4>
//                                   <p>Tokenn</p>
//                                 </div>
//                               </div>
//                               <div className="wallet-list">
//                                 <i className="bi bi-check"></i>
//                                 <div>
//                                   <h4>Industry</h4>
//                                   <p>USD</p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="col-sm-6 col-lg-4 col-xl-3">
//                               <h6>Home Address</h6>
//                               <div className="wallet-list">
//                                 <i className="bi bi-check"></i>
//                                 <div>
//                                   <h4>Country</h4>
//                                   <p>User</p>
//                                 </div>
//                               </div>
//                               <div className="wallet-list">
//                                 <i className="bi bi-check"></i>
//                                 <div>
//                                   <h4>State</h4>
//                                   <p>State</p>
//                                 </div>
//                               </div>
//                               <div className="wallet-list">
//                                 <i className="bi bi-check"></i>
//                                 <div>
//                                   <h4>City</h4>
//                                   <p>City</p>
//                                 </div>
//                               </div>
//                               <div className="wallet-list">
//                                 <i className="bi bi-check"></i>
//                                 <div>
//                                   <h4>Address Line 1</h4>
//                                   <p>Address Line 1</p>
//                                 </div>
//                               </div>
//                               <div className="wallet-list">
//                                 <i className="bi bi-check"></i>
//                                 <div>
//                                   <h4>Address Line 2</h4>
//                                   <p>Address Line 2</p>
//                                 </div>
//                               </div>
//                               <div className="wallet-list">
//                                 <i className="bi bi-check"></i>
//                                 <div>
//                                   <h4>Postal Code</h4>
//                                   <p>Postal Code</p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="col-sm-6 col-lg-4 col-xl-3">
//                               <h6>Identification</h6>
//                               <div className="wallet-list">
//                                 <i className="bi bi-check"></i>
//                                 <div>
//                                   <h4>Date of Birth</h4>
//                                   <p>31-05-2002</p>
//                                 </div>
//                               </div>
//                               <div className="wallet-list">
//                                 <i className="bi bi-check"></i>
//                                 <div>
//                                   <h4>Government Birth Number</h4>
//                                   <p>20-04-2000</p>
//                                 </div>
//                               </div>
//                               <div className="wallet-list">
//                                 <i className="bi bi-check"></i>
//                                 <div>
//                                   <h4>Passport Country</h4>
//                                   <p>Passport Country</p>
//                                 </div>
//                               </div>
//                               <div className="wallet-list">
//                                 <i className="bi bi-check"></i>
//                                 <div>
//                                   <h4>Passport Number</h4>
//                                   <p>Passport Number</p>
//                                 </div>
//                               </div>
//                               <div className="wallet-list">
//                                 <i className="bi bi-check"></i>
//                                 <div>
//                                   <h4>Driver's License Number</h4>
//                                   <p>Driver's License Number</p>
//                                 </div>
//                               </div>
//                               <div className="wallet-list">
//                                 <i className="bi bi-check"></i>
//                                 <div>
//                                   <h4>Other Government ID Number</h4>
//                                   <p>9925585</p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="col-sm-6 col-lg-4 col-xl-3">
//                               <h6>Contact Information</h6>
//                               <div className="wallet-list">
//                                 <i className="bi bi-check"></i>
//                                 <div>
//                                   <h4>Email Address</h4>
//                                   <p>Email Address</p>
//                                 </div>
//                               </div>
//                               <div className="wallet-list">
//                                 <i className="bi bi-check"></i>
//                                 <div>
//                                   <h4>Mobile Dialing Country Code</h4>
//                                   <p>0236</p>
//                                 </div>
//                               </div>
//                               <div className="wallet-list">
//                                 <i className="bi bi-check"></i>
//                                 <div>
//                                   <h4>Mobile Number</h4>
//                                   <p>9638527410</p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="col-sm-6 col-lg-4 col-xl-3">
//                               <h6>Wallet Information</h6>
//                               <div className="wallet-list">
//                                 <i className="bi bi-check"></i>
//                                 <div>
//                                   <h4>Primary Currency</h4>
//                                   <p>Primary Currency</p>
//                                 </div>
//                               </div>
//                               <div className="wallet-list">
//                                 <i className="bi bi-check"></i>
//                                 <div>
//                                   <h4>Overdraft Protection Enabled</h4>
//                                   <p>On</p>
//                                 </div>
//                               </div>
//                               <div className="wallet-list">
//                                 <i className="bi bi-check"></i>
//                                 <div>
//                                   <h4>Overdraft Limit</h4>
//                                   <p>85225</p>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="row">
//         <div className="col-md-11 p-0">
//           <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
//             <button
//               type="button"
//               className="btn cancel-btn mb-3 mb-md-0 me-4"
//               onClick={() => {
//                 cleandata();
//                 dispatch(setPrivateId(""));
//                 previous();
//               }}
//             >
//               Previous
//             </button>
//             <button type="button" className="btn cancel-btn mb-3 mb-md-0 me-4">
//               CANCEL
//             </button>
//             <button
//               type="button"
//               className="btn next-btn mb-3 mb-md-0"
//               onClick={() => {
//                 setPrivateModal(true);
//               }}
//             >
//               Submit
//             </button>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Review;

import axios from "axios";
import { WEB_WALLET } from "../Constants/Config";

export async function LogInAPI(data) {
  // console.log("🚀 ~ file: LogIn.js ~ line 5 ~ LogInAPI ~ data", data);
  const login = await axios
    .post(WEB_WALLET + "/user/Login", data)
    .then((res) => {
      // console.log(res, "ResponseData=====");
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return login;
}

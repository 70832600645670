import React, { useEffect } from "react";
import { useState } from "react";
import CreateBranch from "./CreateBranch";
import ReviewBranch from './ReviewBranch';
function CreateBranchs() {
  const [step, setstep] = useState(1);
  const [branchData, setbranchData] = useState(1);
  useEffect(() => {
    setstep(1)
  },[]);
  const setdata = (data) => {
    setbranchData(data)
  };
  const cleandata=()=>{
    setbranchData(1)
  }

    const renderstep = (step) => {
        switch (step) {
          case 1:
            return <CreateBranch nextstep={nextstep} previous={previous} setdata={setdata} branchData={branchData} cleandata={cleandata}/>
          case 2:
            return <ReviewBranch nextstep={nextstep} previous={previous} branchData={branchData} cleandata={cleandata}/>
          default:
            return false;
        }
    }
    const nextstep = () => {
      if (step < 2) {
        setstep(step + 1);
      }
    };
    const previous = () => {
      if (step > 1) {
        setstep(step - 1);
      }
    }
return( <>
        {renderstep(step)}
    </>
  );
}

export default CreateBranchs;

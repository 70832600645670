import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";

import {useDispatch} from 'react-redux'

function Originator({ nextstep, setdata }) {
  const dispatch = useDispatch();

  const initialValues = {
    branchcode: "",
    agentRoleKeycode: "",
    uuid: "",
    username: "",
    firstname: "",
    secondFirstname: "",
    lastname: "",
    secondLastname: "",
    jobTitle: "",
    imageFN: "",
    email: "",
    mobileDialingcode: "",
    mobilePhone: "",
  };

  return (
    <>
      {/* <Header /> */}
        <div className="ledger-information create-issuer-information">
          <div className="row ms-lg-4 ms-xl-5 m-0">
            <div className="col-12">
              <div className="main-heading">
                <h3>Agents</h3>
                <h6>Modify Agents</h6>
                <p>This function modifies an agent?s profile information.</p>
              </div>
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                // validationSchema={validationSchema}
                onSubmit={(values) => {
                  // debugger;
                  // CreateIssuersApi(values);
                  setdata(values);
                  nextstep();

                  /* console.log("values====", values); */
                }}
              >
                {(formik) => {
                  const { errors, touched, setFieldValue } = formik;
                  return (
                    <div className="wallet-information-box m-0">
                      <Form className="wallet-information-form">
                        <div className="row mb-0">
                          <h4>Primary Contact Information</h4>
                          <div className="row mb-xl-5">
                            <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                              <h6>Primary Contact</h6>
                              <div className="mb-5">
                                <label>First Name</label>
                                <div className="input-box">
                                  <Field
                                    type="text"
                                    name="firstname"
                                    id="firstname"
                                    placeholder="First Name"
                                    className="form-control"
                                  />
                                  <div
                                    className={
                                      errors.firstname && touched.firstname
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="firstName"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-5">
                                <label>Second First Name</label>
                                <div className="input-box">
                                  <Field
                                    type="text"
                                    name="secondFirstname"
                                    id="secondFirstname"
                                    placeholder="Second First name"
                                    className="form-control"
                                  />
                                  <div
                                    className={
                                      errors.secondFirstname &&
                                      touched.secondFirstname
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="secondFirstname"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-5">
                                <label>Last Name</label>
                                <div className="input-box">
                                  <Field
                                    type="text"
                                    name="lastname"
                                    id="lastname"
                                    placeholder="Last Name"
                                    className="form-control"
                                  />
                                  <div
                                    className={
                                      errors.lastname && touched.lastname
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="lastName"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-5">
                                <label>Second Last Name</label>
                                <div className="input-box">
                                  <Field
                                    type="text"
                                    name="secondLastname"
                                    id="secondLastname"
                                    placeholder="Second Last Name"
                                    className="form-control"
                                  />
                                  <div
                                    className={
                                      errors.secondLastname &&
                                      touched.secondLastname
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="secondLastname"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                              <h6>Contact Information</h6>
                              <div className="mb-5">
                                <label>Dialing Code</label>
                                <Field
                                  as="select"
                                  className="form-select form-control"
                                  id="mobileDialingcode"
                                  name="mobileDialingcode"
                                  onChange={(e) => {
                                    // console.log(e.target.value, "Dialing Code");
                                    setFieldValue(
                                      "mobileDialingcode",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                >
                                  {/* {dailingCode &&
                                    dailingCode.map((value, index) => {
                                      return (
                                        <>
                                          <option
                                            value={value.keycode}
                                            key={index}
                                          >
                                            {value.displayname}
                                          </option>
                                        </>
                                      );
                                    })} */}
                                </Field>
                                <div>
                                  <ErrorMessage
                                    name="mobileDialingcode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                              <div className="mb-5">
                                <label>Phone Number</label>
                                <div className="input-box">
                                  <Field
                                    type="text"
                                    name="mobilePhone"
                                    id="mobilePhone"
                                    placeholder="Phone Number"
                                    className="form-control"
                                  />
                                  <div
                                    className={
                                      errors.mobilePhone && touched.mobilePhone
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="mobilePhone"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="mb-5">
                                <label>Email Address </label>
                                <div className="input-box">
                                  <Field
                                    type="text"
                                    name="email"
                                    id="email"
                                    placeholder="Email Address"
                                    className="form-control"
                                  />
                                  <i className="bi bi-check-lg"></i>
                                  <div
                                    className={
                                      errors.email && touched.email
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <ErrorMessage
                                      name="email"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                              <h6>Primary Contact Account Information</h6>
                              <div className="mb-5">
                                <label>Issuer/Branch code</label>
                                <div className="input-box">
                                  <Field
                                    type="text"
                                    name="branchcode"
                                    id="branchcode"
                                    placeholder="Issuer/Branch code"
                                    className="form-control"
                                  />
                                  <div
                                    className={
                                      errors.branchcode && touched.branchcode
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="branchcode"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-5">
                                <label>Job Title</label>
                                <div className="input-box">
                                  <Field
                                    type="text"
                                    name="jobTitle"
                                    id="jobTitle"
                                    placeholder="Job Title"
                                    className="form-control"
                                  />
                                  <div
                                    className={
                                      errors.jobTitle && touched.jobTitle
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="jobTitle"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-5">
                                <label>Username</label>
                                <div className="input-box">
                                  <Field
                                    type="text"
                                    name="username"
                                    id="username"
                                    placeholder="Username"
                                    className="form-control"
                                  />
                                  <div
                                    className={
                                      errors.username && touched.username
                                        ? "input-error"
                                        : null
                                    }
                                  >
                                    <i className="bi bi-check-lg"></i>
                                    <ErrorMessage
                                      name="username"
                                      component="span"
                                      className="error"
                                      style={{ color: "red" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-5">
                                <label>User Role</label>
                                <Field
                                  as="select"
                                  className="form-select form-control"
                                  id="agentRoleKeycode"
                                  name="agentRoleKeycode"
                                  onChange={(e) => {
                                    // console.log(e.target.value, "Dialing Code");
                                    setFieldValue(
                                      "agentRoleKeycode",
                                      e.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                >
                                  {/* {AgentTypedata &&
                                    AgentTypedata.map((value, index) => {
                                      return (
                                        <>
                                          <option
                                            value={value.keycode}
                                            key={index}
                                          >
                                            {value.displayName}
                                          </option>
                                        </>
                                      );
                                    })} */}
                                </Field>
                                <div>
                                  <ErrorMessage
                                    name="mobileDialingcode"
                                    component="span"
                                    className="error"
                                    style={{ color: "red" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5">
                          <button
                            type="button"
                            className="btn cancel-btn mb-3 mb-md-0 me-4"
                          >
                            CANCEL
                          </button>
                          <button
                            type="submit"
                            className="btn next-btn mb-3 mb-md-0"
                          >
                            NEXT
                          </button>
                        </div>
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
    </>
  );
}

export default Originator;

//import ReactHelmet from "../../Components/Utilities/ReactHelmet";
// import { useState } from "react";
import { LogInAPI } from "../../API/LogIn";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { decrypytdata, validatePublicKey } from "../../API/walletkey";
import { useNavigate, Navigate } from "react-router-dom";
import { DecryptionString, ADMIN_URL } from "../../Constants/Config";

import axios from "axios";
import { useDispatch } from "react-redux";
import { setPublicId, setPrivateId, setMenus, setUserType } from "../../action";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userData, SetUserData] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const initialValues = {
    email: "",
    password: "",
  };

  const LogInHandler = async (values) => {
    /* console.log(values); */

    const LogInData = await LogInAPI(values);
    /* console.log(
      "🚀 ~ file: Login.js ~ line 28 ~ LogInHandler ~ LogInData",
      LogInData
    ); */
    SetUserData(LogInData);

    // Gonna be used for decrypting the data
    const devryptedToken = await decrypytdata(LogInData.data, DecryptionString);

    const jsonData = JSON.parse(devryptedToken);

    /* console.log(
      "🚀 ~ file: Login.js ~ line 40 ~ LogInHandler ~ jsonData",
      jsonData
    ); */
    const logInDataValues = jsonData?.CommandResponse[0];

    // const { data: allMenus } = await axios.get(ADMIN_URL + "/security/GetNavigation");

    // const publicKeyValidationResponse = await validatePublicKey(logInDataValues.PublicAddress)

    // const urls = []

    // allMenus.forEach(item => {
    //   const walletType = item.walletClassList.split(',')

    //   if (walletType.includes(publicKeyValidationResponse.walletClassKeycode)) {
    //     urls.push(item.webURL.replace(/\/webwallet/, 'controlpanel'))
    //   }
    // })

    // window.localStorage.setItem("UserLogIn", JSON.stringify(logInDataValues));
    sessionStorage.setItem("UserLogIn", JSON.stringify(logInDataValues));

    dispatch(setUserType(logInDataValues.UserRoleKeycode));
    // dispatch(setMenus(urls))
    dispatch(setPublicId({ publicAddress: logInDataValues.PublicAddress }));
    dispatch(
      setPrivateId({ PrivateWalletAddress: logInDataValues.PrivateKey })
    );
    /* console.log("Dfdsknfdkjnfdzknklnz====", logInDataValues); */
    // CommandResponse[0].UserRoleKeycode
    // SetcommandResponseData(jsonData.CommandResponse[0]);

    if (logInDataValues.UserRoleKeycode === "PORTAL-SA") {
      navigate("/system-administrator");
    } else if (logInDataValues.UserRoleKeycode === "BANK-SA") {
      navigate("/bank-administrator");
    } else if (logInDataValues.UserRoleKeycode === "ISSUER-SA") {
      navigate("/issuer-administrator");
    } else if (logInDataValues.UserRoleKeycode === "CRYPTO-SA") {
      navigate("/crypto-administrator");
    } else {
      navigate("/issuer-administrator");
    }
  };

  // useEffect(() => {
  //   if (
  //     commandResponseData &&
  //     commandResponseData.UserRoleKeycode === "PORTAL-SA"
  //   ) {
  //     window.localStorage.setItem(
  //       "UserLogIn",
  //       JSON.stringify(commandResponseData)
  //     );
  //   }
  // }, [commandResponseData]);

  const userFromStorage = sessionStorage.getItem("UserLogIn");

  if (userFromStorage) {
    const userData = JSON.parse(userFromStorage);

    if (userData.UserRoleKeycode === "PORTAL-SA") {
      return <Navigate to="/system-administrator" replace={true} />;
    } else if (userData.UserRoleKeycode === "BANK-SA") {
      return <Navigate to="/bank-administrator" replace={true} />;
    } else if (userData.UserRoleKeycode === "ISSUER-SA") {
      return <Navigate to="/issuer-administrator" replace={true} />;
    } else if (userData.UserRoleKeycode === "CRYPTO-SA") {
      return <Navigate to="/crypto-administrator" replace={true} />;
    } else {
      return <Navigate to="/issuer-administrator" replace={true} />;
    }
  }

  return (
    <>
      {/*<ReactHelmet title="Login" description="" />
      <div className="transfer-rates-page">
                <div className="transfer-rates">
                    <div className="row transfer-rates-page">
                        <div className="col-xxl-11 ">
                            <div className="main-head">
                                <i className="bi bi-arrow-left"></i>
                              <button><a href="/register">Login</a></button>
                            </div>
                     
                        </div>
                    </div>
                </div>
            </div>*/}
      <div className="container-fluid">
        <div className="login-page">
          <div className="row align-items-center">
            <div className="col-md-7 col-lg-auto p-0">
              <div className="login-img">
                <img
                  src="../images/dl.beatsnoop 1.png"
                  className="img-fluid"
                  alt="login-img"
                />
              </div>
            </div>
            <div className="col-md-5 col-lg-4">
              <Formik
                initialValues={initialValues}
                // validationSchema={validationSchema}
                // enableReinitialize={true}
                onSubmit={async (values, actions) => {
                  await LogInHandler(values);
                  actions.setSubmitting(false);
                }}
              >
                {(formik) => {
                  return (
                    <>
                      <div className="login-box">
                        <div className="login-text">
                          <img src="images/U-1.png" alt="logo" />
                          <h3>Welcome Back</h3>
                          <Form>
                            <div className="mb-3 mb-md-5">
                              <label className="form-label">
                                Email Address
                              </label>
                              <div className="password-eye">
                                <Field
                                  type="text"
                                  className="form-control"
                                  id="email"
                                  name="email"
                                  //   onChange={(e) => {
                                  //     SetUserData({ email: e.target.value });
                                  //   }}
                                />
                                {/* <img src="../icons/x.png" alt="CroodsChart" className="eye-img" /> */}
                              </div>
                              <div style={{ color: "red" }}>
                                <ErrorMessage
                                  name="email"
                                  component="span"
                                  className="error"
                                />
                              </div>

                              {/* <p>Email Address is required</p> */}
                            </div>
                            <div className="mb-3">
                              <label className="form-label">Password</label>
                              <div className="password-eye">
                                <Field
                                  type={showPassword ? "text" : "password"}
                                  className="pe-5 form-control"
                                  id="password"
                                  name="password"
                                  //   onChange={(e) => {
                                  //     SetUserData({ password: e.target.value });
                                  //   }}
                                />
                                {showPassword ? (
                                  <i
                                    className="bi bi-eye-slash eye-img"
                                    onClick={() => setShowPassword(false)}
                                  ></i>
                                ) : (
                                  <i
                                    className="bi bi-eye eye-img"
                                    onClick={() => setShowPassword(true)}
                                  ></i>
                                )}
                                {/* <img
                                  src="../icons/eye.png"
                                  alt="CroodsChart"
                                  className="eye-img"
                                  onClick={() => setShowPassword(!showPassword)}
                                /> */}
                              </div>
                              <div style={{ color: "red" }}>
                                <ErrorMessage
                                  name="password"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              {/* {jsonData && jsonData.ReturnMessage === "Invalid Password" ? (
                                <p>Invalid password </p> 
                              ): ""} */}
                              {userData && userData.status !== 200 ? (
                                <p>Invalid password </p>
                              ) : (
                                " "
                              )}
                            </div>
                            <div className="form-check">
                              <div className="">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="Check"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="Check"
                                >
                                  Remember me
                                </label>
                              </div>
                              <a href="/register">Forgot Password</a>
                            </div>
                            {/* <button
                              type="submit"
                              className="btn"
                              //   onClick={() => {
                              //     // LogInHandler(userData);
                              //   }}
                            > */}
                            {/* <a href="/register">SIGN IN</a> */}
                            {/* SIGN IN
                            </button> */}
                            <button
                              className="btn-sound btn btn-primary"
                              type="submit"
                              disabled={formik.isSubmitting}
                            >
                              {formik.isSubmitting ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                <span>SIGN IN</span>
                              )}

                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </button>
                          </Form>
                        </div>
                      </div>
                    </>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

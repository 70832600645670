import { WEB_WALLET_URL } from "../Constants/Config.js";
import http from "./httpService.js";
import { hmacencrypt } from "./walletkey";

export async function GetBalance(publickey, privateKey , data) {
  // let values = {
  //   // sourcePublicAddress: publickey,
  //   LedgerBalanceTypecode: "GOOD",
  //   ledgerTypecode: "ECASH",
  //   // ledgercode: "",
  // };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/money/GetBalances",
    // values,
    data,
    publickey,
    privateKey
  );
  const response = await http.post("/money/GetBalances", data,{
    headers: {
      Authorization: token,
    },
  });
  return response;
}

export async function GetActivityApi(publickey, privateKey) {
  let values = {
    sourcePublicAddress: publickey,
    // transfercode: "",
    // ledgercode: "",
  };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/money/GetActivity",
    values,
    publickey,
    privateKey
  );
  const response = await http.post("/money/GetActivity", values, {
    headers: {
      Authorization: token,
    },
  });
  return response;
}

export async function suspendBranch(publickey, privateKey, data) {
  // let values = {
  //   sourcePublicAddress: publickey,
  // };
  const values = {
    sourcePublicAddress: publickey,
    issuercode: data,
  };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/issuer/SuspendBranch",
    values,
    publickey,
    privateKey
  );

  const response = await http.post("/issuer/SuspendBranch", values, {
    headers: {
      Authorization: token,
    },
  });
  return response;
}

export async function insertBranch(publickey, privateKey, Issuerdata) {
  // debugger;
  // let values = {
  //   ...Issuerdata,
  //   publicWalletAddress: publickey,
  //   privateKey: privateKey,
  //   // addressLine3: null,
  //   // neighborhood: null,
  //   groupID: 12,
  //   issuerID: 0,
  //   walletID: 0,
  //   // parentIssuercode: "",
  //   // issuerTypecode: "",
  //   // citycode: "",
  //   // userRoleKeycode: "",
  // };
  // let values = {
  //   sourcePublicAddress: publickey,
  //   Issuerdata,

  // };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/issuer/CreateBranch",

    Issuerdata,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/issuer/CreateBranch",
    // {
    //   ...values,
    //   publicWalletAddress: publickey,
    //   privateKey: privateKey,
    //   addressLine3: null,
    //   neighborhood: null,
    //   groupID: 0,
    //   issuerID: 0,
    //   walletID: 0,
    //   parentIssuercode: "",
    //   issuerTypecode: "",
    //   citycode: "",
    //   userRoleKeycode: "",
    // },
    Issuerdata,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function modifyBranch(publickey, privateKey, Issuerdata) {
  // debugger;
  let values = {
    ...Issuerdata,
    publicWalletAddress: publickey,
    privateKey: privateKey,
    // addressLine3: null,
    // neighborhood: null,
    groupID: 12,
    issuerID: 0,
    walletID: 0,
    // parentIssuercode: "",
    // issuerTypecode: "",
    // citycode: "",
    // userRoleKeycode: "",
  };
  // let values = {
  //   sourcePublicAddress: publickey,
  //   Issuerdata,

  // };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/issuer/ModifyBranch",

    values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/issuer/ModifyBranch",
    // {
    //   ...values,
    //   publicWalletAddress: publickey,
    //   privateKey: privateKey,
    //   addressLine3: null,
    //   neighborhood: null,
    //   groupID: 0,
    //   issuerID: 0,
    //   walletID: 0,
    //   parentIssuercode: "",
    //   issuerTypecode: "",
    //   citycode: "",
    //   userRoleKeycode: "",
    // },
    values,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

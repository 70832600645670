import React, { useEffect, useState } from "react";
import { getWallet } from "../../../API/Wallets";
import { useDispatch, useSelector } from "react-redux";
import { decrypytdata } from "../../../API/walletkey";
import { Walletscolumns } from "../../../Components/GridHeaders";
import Grid from "../../../Components/Grid";
import { success } from "../../../Components/alert";
import { Link } from "react-router-dom";
import { showLoader } from "../../../action";
function WalletsBalances() {
  // const [privateModal, setPrivateModal] = useState(false);
  const [rows, setRows] = React.useState([]);
  const publicKeys = useSelector((state) => state.keyReducer);
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  let APPID = "";
  let APIKEY = "";

  const fetchdata = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedWallets = await getWallet(APPID, APIKEY);
      dispatch(showLoader(false));
      const Wallets = await decrypytdata(encryptedWallets.data, APIKEY);
      let walletData = JSON.parse(JSON.parse(Wallets).CommandResponse);
      /* console.log(
        "🚀 ~ file: WalletsBalances.js ~ line 30 ~ fetchdata ~ walletData",
        walletData
      ); */

      /* console.log('Wallets', walletData); */

      if (walletData.returnMessage) {
        success(walletData.returnMessage);
      }
      if (walletData) {
        let dataArr = [];
        walletData?.forEach((dataMain, index) => {
          // console.log("dataMain -->", dataMain);
          dataArr.push({
            ...dataMain,
            rowid: dataMain.RowID,
          });
        });
        setRows(dataArr);
      }
    }
  };

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
        fetchdata();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);
  return (
    <>
      {isValidPublicAddress && (
          <div className="row ms-lg-4 ms-xl-5  m-0">
            <div className="col-12">
              <div className="main-heading">
                <h3> Wallets</h3>
                <h6>Get Wallets</h6>
                <p>
                  This function provides the digital currency balance in a
                  specified ledger. If no ledger address is specified, it
                  returns all the digital currency account balances associated
                  with a wallet.
                </p>
              </div>
              <div className="row">
                <div className="col-xxl-10">
                  <div className="wallet-balances-box m-0">
                    <div className="d-md-flex justify-content-between">
                      <div className="search-box d-md-flex">
                        <div className="input-group mb-3"></div>
                      </div>
                      <Link to={`/controlpanel/createwallet`}>
                        <button
                          type="button"
                          className="btn btn-primary "
                          style={{ marginBottom: "10px" }}
                        >
                          + ADD Wallets
                        </button>
                      </Link>
                    </div>
                    <div style={{ height: 800, width: "100%" }}>
                      <Grid row={rows} coloums={Walletscolumns} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      )}
    </>
  );
}
export default WalletsBalances;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPrivateId } from "../../../../action";
import { importIssuerBatch } from "../../../../API/Issures";
import { decrypytdata } from "../../../../API/walletkey";
import { success } from "../../../../Components/alert";

const BatchUpload = () => {
  const [issuerData, setIssuerData] = useState({});

  const { publicKey: { publicAddress }, privateKey: { PrivateWalletAddress } } = useSelector((state) => state.keyReducer);
  let APPID = "";
  let APIKEY = "";
  const dispatch = useDispatch();

  const image = issuerData;
  const formData = new FormData();
  formData.append("File", image);

  const closeModal = async () => {
    if (publicAddress) {
      APPID = publicAddress;
    }
    if (PrivateWalletAddress) {
      APIKEY = PrivateWalletAddress;
      /* console.log(
        "🚀 ~ file: ReviewIssuer.js ~ line 29 ~ closeModal ~ APIKEY",
        APIKEY
      ); */
    }
    if (APPID && APIKEY) {
      /* console.log(
        "🚀 ~ file: ReviewIssuer.js ~ line 24 ~ closeModal ~ APPID",
        APPID
      ); */

      const encryptedIssuer = await importIssuerBatch(APPID, APIKEY, formData);
      /* console.log("🚀 ~ file: BatchUpload.js ~ line 37 ~ closeModal ~ encryptedIssuer", encryptedIssuer) */
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);
/*       console.log("🚀 ~ file: BatchUpload.js ~ line 39 ~ closeModal ~ devryptedIssuer", devryptedIssuer) */
      // debugger;
      const jsonData = JSON.parse(devryptedIssuer);
/*       console.log(
        "🚀 ~ file: ReviewIssuer.js ~ line 42 ~ closeModal ~ jsonData",
        jsonData
      ); */
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
      /* dispatch(setPrivateId("")); */
      // debugger;
    }
    // setPrivateModal(false);
  };

  return (
    <>
      <div className="row">
        {/*<h5 className="mb-3">*/}
        {/*    Upload a Limit Rules File in the proper*/}
        {/*    file format for batched processing*/}
        {/*</h5>*/}
        <div className="col-sm-6 col-xl-4">
          <div className="mb-5">
            <label className="mb-3">Batch Upload</label>
            <div className="input-box">
              <div className="input-group custom-file-button">
                <input
                  type="file"
                  name="image"
                  className="form-control"
                  id="inputGroupFile"
                  onChange={(e) => {
                    setIssuerData(e.target.files[0]);
                  }}
                />
                <label className="input-group-text" htmlFor="inputGroupFile">
                  BROWSE
                </label>
              </div>
            </div>
          </div>
          <div className="col-xxl-11">
            <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
              {/* <button
                type="button"
                className="btn cancel-btn mb-3 mb-md-0 me-4"
                onClick={previous}
              >
                PREVIOUS
              </button> */}
              <button
                type="submit"
                className="btn next-btn mb-3 mb-md-0"
                onClick={closeModal}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BatchUpload;

import React from "react";

const Success = ({ resultDatas }) => {
  /* console.log(
    "🚀 ~ file: Success.js ~ line 4 ~ Success ~ resultDatas",
    resultDatas
  ); */
  const commandResponseData = resultDatas.resultReducer.data?.CommandResponse
    ? JSON.parse(resultDatas.resultReducer.data?.CommandResponse)
    : "";
  /* console.log(
    "🚀 ~ file: Success.js~line 11 ~ Success ~ commandResponseData",
    commandResponseData
  ); */
  const removeArr = [
    "PublicAddress",
    "BlockID",
    "Datetimestamp",
    "Command",
    "CommandType",
    "Status",
    "TxHash",
    "NetworkFee",
    "Objectcode",
    "Objectname",
  ];

  const initialValues = {
    Transaction: resultDatas.resultReducer.data.TransactionHash,
    NodeNetwork: resultDatas.EncryptDataReducer.data.config.baseURL,
    EndPoint: resultDatas.EncryptDataReducer.data.config.url,
    NetworkResponseCode: resultDatas.EncryptDataReducer.data.status,
    NetworkResponseMessage: resultDatas.resultReducer.data?.ReturnMessage,
    CreationDate: resultDatas.resultReducer.data?.CreationDate,
    ElapsedTime: resultDatas.resultReducer.data?.ElapsedTime,
    PublicAddress: resultDatas.keyReducer.publicKey.publicAddress
      ? resultDatas.keyReducer.publicKey.publicAddress
      : "NULL",
    PrivateKey: resultDatas.keyReducer.privateKey.PrivateWalletAddress
      ? resultDatas.keyReducer.privateKey.PrivateWalletAddress
      : "NULL",
    ReturnMessage: resultDatas.resultReducer.data.ReturnMessage,
    ReturnKeycode: resultDatas.resultReducer.data.ReturnKeycode,
    ReturnCode: resultDatas.resultReducer.data.Returncode,
  };

  return (
          <div className="col-xl-11">
      <div className="review-wallet-box deposit-funds-tabs">
        <h6 className="my-5">Transaction Result</h6>
            <div className="review-wallet-info-box mb-5">
              <div className="row g-0">
                <div className="col-12">
                  <div className="heading">
                    <div className="money-heading">
                      <h3>
                        Transaction
                        {initialValues?.Transaction && (
                          <p>
                            {/* {resultData.length > 0 &&
                                       resultData[0].TxHash} */}
                            {initialValues?.Transaction}
                          </p>
                        )}
                      </h3>
                    </div>
                    {/* <i className="bi bi-pencil-square"></i> */}
                  </div>
                  <hr className="mb-4" />
                </div>
                <div className="col-12">
                  {initialValues?.NodeNetwork && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Node Network
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                            {/* {resultData.length > 0 &&
                                       encryptData.data.config.baseURL} */}
                            {initialValues?.NodeNetwork}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  {initialValues?.EndPoint && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>End Point
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                            {/* {resultData.length > 0 &&
                                       encryptData.data.config.url} */}
                            {initialValues?.EndPoint}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  {initialValues?.NetworkResponseCode && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Network Response
                            Code
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                            {/* {resultData.length > 0 &&
                                       encryptData.data.status} */}
                            {initialValues?.NetworkResponseCode}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  {initialValues?.NetworkResponseMessage && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Network Response
                            Message
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                            {/* {resultData.length > 0 &&
                                       selectData.data.returnMessage} */}
                            {initialValues?.NetworkResponseMessage}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {initialValues?.CreationDate && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Creation Date
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                            {/* {resultDatas.EncryptDataReducer.data?.data.Returncode} */}
                            {initialValues?.CreationDate}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {initialValues?.ElapsedTime && (
                    <div className="row mb-2">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Elapsed Time
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                            {/* {resultDatas.EncryptDataReducer.data?.data.Returncode} */}
                            {initialValues?.ElapsedTime}
                          </p>
                        </div>
                      </div>
                      <hr className="mt-4" />
                    </div>
                  )}

                  {commandResponseData && commandResponseData?.Command && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Command
                          </h3>
                        </div>
                      </div>
                      <div className=" col-md">
                        <div className="money-transaction-text">
                          <p>
                            {/* {resultData.length > 0 &&
                                       resultData[0].Command} */}
                            {commandResponseData?.Command}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  {commandResponseData && commandResponseData?.CommandType && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>
                            Command Types
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <h6>
                            <span></span>
                            {/* {resultData.length > 0 &&
                                       resultData[0].lk1[0].CommandType} */}
                            {/* {resultDatas.resultReducer.data.transactionHash} */}
                            {commandResponseData.CommandType}
                          </h6>
                        </div>
                      </div>
                    </div>
                  )}

                  {commandResponseData && commandResponseData?.PublicAddress && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Public Address
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                            {/* {resultData.length > 0 &&
                                       resultData[0].Datetimestamp} */}
                            {commandResponseData?.PublicAddress}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  {commandResponseData && commandResponseData?.Objectcode && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Object Code
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                            {/* {resultData.length > 0 &&
                                       resultData[0].lk1[0].o[0].Objectcode} */}
                            {/* {resultDatas.resultReducer.data.transactionHash} */}
                            {commandResponseData?.Objectcode}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  {commandResponseData && commandResponseData?.Objectname && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Object Name
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                            {/* {resultData.length > 0 &&
                                       resultData[0].lk1[0].o[0].Objectname} */}
                            {/* {resultDatas.resultReducer.data.transactionHash} */}
                            {commandResponseData?.Objectname}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-12">
                  {/* {initialValues?.PublicAddress && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Public Address
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                         
                          </p>
                        </div>
                      </div>
                    </div>
                  )} */}
                  {/* {initialValues?.PublicAddress
                              ? initialValues?.PublicAddress
                              : "NULL"} */}
                  {/* {initialValues?.PrivateKey && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Private Key
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                           
                          </p>
                        </div>
                      </div>
                    </div>
                  )} */}
                  {/* {initialValues?.PrivateKey
                    ? initialValues?.PrivateKey
                    : "NULL"} */}
                  {initialValues?.ReturnMessage && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Return Message
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                            {/* {resultData.length > 0 &&
                                       selectData.data.returnMessage} */}
                            {/* {resultDatas.resultReducer.data.transactionHash} */}
                            {initialValues?.ReturnMessage}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  {initialValues?.ReturnKeycode && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Return Keycode
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                            {/* {resultData.length > 0 &&
                                       selectData.data.returnKeycode} */}
                            {/* {resultDatas.resultReducer.data.transactionHash} */}
                            {initialValues?.ReturnKeycode}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  {initialValues?.ReturnCode >= 0 && (
                    <div className="row mb-2">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Return Code
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                            {/* {resultData.length > 0 &&
                                       selectData.data.returnKeycode} */}
                            {/* {resultDatas.resultReducer.data.transactionHash} */}
                            {initialValues?.ReturnCode}
                          </p>
                        </div>
                      </div>
                      <hr className="mt-4" />
                    </div>
                  )}
                </div>
                <div className="col-12">
                  {commandResponseData && commandResponseData?.TxHash && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>TxHash
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                            {/* {resultData.length > 0 &&
                                       resultData[0].TxHash} */}
                            {commandResponseData?.TxHash}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  {commandResponseData && commandResponseData?.Status && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb- mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>
                            TxReceipt Status
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                            {/* {resultData.length > 0 &&
                                       resultData[0].lk1[0].o[0].lk2[0].Status} */}
                            {commandResponseData?.Status}
                          </p>
                        </div>
                      </div>
                      {/* <hr className="mt-4" /> */}
                    </div>
                  )}

                  {commandResponseData && commandResponseData?.BlockID && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Block
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                            {/* {resultData.length > 0 &&
                                       resultData[0].BlockID} */}
                            {commandResponseData?.BlockID}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  {commandResponseData && commandResponseData?.Datetimestamp && (
                    <div className="row mb-4">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i>Time Stamp
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <p>
                            {/* {resultData.length > 0 &&
                                       resultData[0].Datetimestamp} */}
                            {commandResponseData?.Datetimestamp}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                  {commandResponseData && commandResponseData?.NetworkFee >= 0 && (
                    <div className="row mb-3">
                      <div className="col-md-4 mb-3 mb-md-0">
                        <div className="money-transaction-icon">
                          <h3>
                            <i className="bi bi-question-lg"></i> Network Fee
                          </h3>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="money-transaction-text">
                          <div className="d-flex">
                            <p className="brown-p pe-2">Ü</p>
                            {/* {resultData.length > 0 &&
                                       resultData[0].NetworkFee} */}
                            {/* {commandResponseData[0].NetworkFee} */}
                            {commandResponseData.NetworkFee.toFixed(6)}
                            {/* {commandResponseData.NetworkFee} */}
                          </div>
                        </div>
                      </div>
                      <hr className="mt-4" />
                    </div>
                  )}
                </div>

                {/* {console.log(
                  Object.keys(commandResponseData),
                  "===-=-==-=-=-=-=-=-"
                )}
                {console.log(
                  Object.keys(commandResponseData).forEach((key) =>
                    console.log(
                      key,
                      commandResponseData[key],
                      "------------------------------------"
                    )
                  ),
                  "===-=-==-=-=-=-=-=-=---------------[["
                )} */}
                {/* {console.log(
                  Object.keys(commandResponseData),
                  "===-=-==-=-=-=-=-=-"
                )} */}
                {/* console.log(key , "this is key") */}

                {/* {Object.keys(commandResponseData)
                  .filter((data) => !removeArr.includes(data))
                  .forEach((key) => {
                    console.log(
                      key,
                      "=",
                      commandResponseData[key],
                      "---------------------------------"
                    );
                  })} */}

                {Object.keys(commandResponseData)
                  .filter((data) => !removeArr.includes(data))
                  .map((key, index) => {
                    return (
                        <div key={index} className="col-12">
                          <div className="row mb-4">
                            <div className="col-md-4 mb-3 mb-md-0">
                              <div className="money-transaction-icon">
                                <h3>
                                  <i className="bi bi-question-lg"></i>
                                  {key.split(/(?=[A-Z])/).join(" ")
                                    ? key.split(/(?=[A-Z])/).join(" ")
                                    : key}
                                </h3>
                              </div>
                            </div>
                            <div className="col-md">
                              <div className="money-transaction-text">
                                <p>
                                  {/* {resultData.length > 0 &&
                                       resultData[0].lk1[0].o[0].lk2[0].Status} */}
                                  {commandResponseData[key]}
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* <hr className="mb-4" /> */}
                        </div>
                    );
                  })}
              </div>
            </div>
          </div>
      </div>
      
  );
};

export default Success;

import React, { useEffect, useState } from "react";
// import PrivateModal from "../../../Components/PrivateModal";
import { getAgents } from "../../../API/Agents";
import { useSelector } from "react-redux";
import { decrypytdata } from "../../../API/walletkey";
import { Agentscolumns } from "../../../Components/GridHeaders";
import Grid from "../../../Components/Grid";
import { Link } from "react-router-dom";

import { useDispatch } from 'react-redux'
import { showLoader } from '../../../action/index'

function AgentsBalances() {
  const dispatch = useDispatch()

  const [privateModal, setPrivateModal] = useState(false);
  const [rows, setRows] = React.useState([]);
  const publicKeys = useSelector((state) => state.keyReducer);
  let APPID = "";
  let APIKEY = "";
  const closeModal = () => {
    setPrivateModal(false);
  };
  const fetchdata = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      try {
        dispatch(showLoader(true))

        const encryptedAgent = await getAgents(APPID, APIKEY);
        const Agent = await decrypytdata(encryptedAgent.data, APIKEY);
        /* console.log("🚀 ~ file: AgentsBalances.js ~ line 28 ~ fetchdata ~ Agent", Agent) */
        let data = JSON.parse(JSON.parse(Agent).CommandResponse);
        /* console.log(
          "🚀 ~ file: AgentsBalances.js ~ line 28 ~ fetchdata ~ data",
          data
        ); */

        /* console.log('Agents', data); */

        if (data) {
          let dataArr = [];
          data?.forEach((dataMain, index) => {
            // multiple data
            dataArr.push({ ...dataMain, rowid: index });
            // console.log(dataMain, "dataMain")
          });
          setRows(dataArr);
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(showLoader(false))
      }
    }
  };

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (!publicKeys?.privateKey?.PrivateWalletAddress) setPrivateModal(true);
    }
    fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys, privateModal]);

  return (
    <>
      {/* {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
      )} */}
        <div className="row ms-lg-4 ms-xl-5 m-0">
          <div className="col-12">
            <div className="main-heading">
              <h3> Agents</h3>
              <h6>Get Agents</h6>
              <p>
                {" "}
                This function lists all the agents associated with an issuer
                wallet.
              </p>
            </div>
            <div className="row">
              <div className="col-xxl-10">
                <div className="wallet-balances-box m-0">
                  <div className="d-md-flex justify-content-between">
                    <div className="search-box d-md-flex">
                      <div className="input-group mb-3"></div>
                    </div>
                    <Link to={`/controlpanel/createagent`}>
                      <button
                        type="button"
                        className="btn btn-primary "
                        style={{ marginBottom: "10px" }}
                      >
                        + ADD Agents
                      </button>
                    </Link>
                  </div>
                  <div style={{ height: 800, width: "100%" }}>
                    <Grid row={rows} coloums={Agentscolumns} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* </div> */}
    </>
  );
}

export default AgentsBalances;

import React from "react";
import { Link, useNavigate } from "react-router-dom";
//import { Link } from "react-router-dom";
// import il from "../../../assets/logos/il.png";

function Header() {
  const navigate = useNavigate();

  const userData = JSON.parse(sessionStorage.getItem('UserLogIn'))

  const ClearUserData = () => {
    sessionStorage?.removeItem("UserLogIn");
  };

  return (
    <>
      <div className="header">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
              <img src={userData?.LogoFN} alt="logo" className="logo-img" />
              <div className="logo-text">
                <h3>{userData?.Groupname}</h3>
                <p>
                  Central Bank Digital Currency (CBDC) Administration System
                </p>
              </div>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <Link to="/system-administrator">
                  <li className="nav-item">
                    <p className="nav-link active" aria-current="page" href="/">
                      Home
                    </p>
                  </li>
                </Link>

                <li
                  className="nav-item"
                  onClick={() => {
                    ClearUserData();
                    navigate("/");
                  }}
                >
                  <Link className="nav-link active" aria-current="page" to="/">
                    Logout
                  </Link>
                </li>
                {/*<li className="nav-item">*/}
                {/*    <div class="dropdown">*/}
                {/*        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">*/}
                {/*            Your Name*/}
                {/*        </button>*/}
                {/*        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">*/}
                {/*            <li>*/}
                {/*                <a class="dropdown-item" href="/">Profile</a>*/}
                {/*            </li>*/}
                {/*            <li>*/}
                {/*                <a class="dropdown-item" href="/">Logout</a>*/}
                {/*            </li>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</li>*/}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default Header;

import { Link } from "react-router-dom";
import { GridActionsCellItem } from "@mui/x-data-grid";

import '../styles/GridHeaders.css'

export const Issuercolumns = [
  {
    field: "Countrycode",
    headerName: "Country",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Objectcode",
    headerName: "Issuer Code",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "TransactionType",
    headerName: "Issuer Type",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Displayname",
    headerName: "Issuer",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },

  {
    field: "Status",
    headerName: "Status",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <div className="table-icons">
            <img src="/icons/Actions-pin.png" alt="Wallets-icon-img" size={10} />
          </div>
        }
        label="Pin"
      // onClick={alert("delete")}
      />,

      <GridActionsCellItem
        icon={
          <div className="table-icons">
            <img
              src="/icons/Actions-user.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </div>
        }
        label="Users"
      // onClick={alert("delete")}
      />,

      <GridActionsCellItem
        icon={
          <div className="table-icons">
            <Link to={`/controlpanel/modifyissuer?id=${params.row.Issuercode}`} state={{ ...params.row }}>
              <img
                src="/icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Edit"
      // onClick={alert("delete")}
      />,

      <GridActionsCellItem
        icon={
          <div className="table-icons">
            <Link to={`/controlpanel/suspendissuer?id=${params.row.Issuercode}`} state={{ ...params.row }}>
              <img
                src="/icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Delete"
      // onClick={alert("delete")}
      />,
    ],
  },
];

export const Limitscolumns = [
  {
    field: "Displayname",
    headerName: "Settlement Service Provider",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "TokenSymbol",
    headerName: "Symbol",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "SSPTypecode",
    headerName: "SSP Type",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Objectcode",
    headerName: "SSP Code",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  // {
  //   field: "Status",
  //   headerName: "Status",
  //   flex: 1,
  //   type: "string",
  //   align: "right",
  //   headerAlign: "right",
  // },
  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        className="icons-action"
        icon={
          <Link to={`/webwallet/modifylimit?id=${params.row.Objectcode}`}>
            {/* <Link to={`/webwallet/modifytoken`}> */}

            <img
              src="../../icons/Actions-edit.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </Link>
        }
        label="Edit"
      // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        className="icons-action"
        icon={
          <Link to={`/webwallet/suspendlimit?id=${params.row.Objectcode}`}>
            <img
              src="../../icons/Actions-trash.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </Link>
        }
        label="Delete"
      // onClick={alert("delete")}
      />,
    ],
  },
];

export const Branchescolumns = [
  {
    field: "Countrycode",
    headerName: "Country",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Objectcode",
    headerName: "Branch Code",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "TransactionTypecode",
    headerName: "Branch Type",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Displayname",
    headerName: "Branch",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Status",
    headerName: "Status",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <div className="action-icons">
            <Link to={`/controlpanel/modifybranch?id=${params.row.Displayname}`} state={{ ...params.row }}>
              <img
                src="../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Edit"
      // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="action-icons">
            <Link to={`/controlpanel/suspendbranch?id=${params.row.Objectcode}`} state={{ ...params.row }}>
              <img
                src="../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Delete"
      // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="action-icons">
            {/* <Link to={{ pathname: "/controlpanel/suspendissuer" }}> */}
            <img
              src="../icons/actions-agent.png"
              alt="Wallets-icon-img"
              size={10}
            />
            {/* </Link> */}
          </div>
        }
        label="Users"
      // onClick={alert("delete")}
      />,
    ],
  },
];

export const Agentscolumns = [
  {
    field: "Objectcode",
    headerName: "User ID",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "TransactionTypecode",
    headerName: "User Type",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Displayname",
    headerName: "User",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Countrycode",
    headerName: "Country",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Status",
    headerName: "Status",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <div className="action-icons">
            <Link to={`/controlpanel/modifyagent?id=${params.row.Objectcode}`} state={{ ...params.row }} >
              <img
                src="../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Delete"
      // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="action-icons">
            <Link to={`/controlpanel/suspendagent?id=${params.row.Objectcode}`} state={{...params.row}}>
              <img
                src="../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Action1"
      // onClick={alert("delete")}
      />,
    ],
  },
];

export const Ledgerscolumns = [
  {
    field: "PublicAddress",
    headerName: "Public Address",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "TokenSymbol",
    headerName: "Token Symbol",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "TransactionType",
    headerName: "Ledger Type",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Displayname",
    headerName: "Ledger",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Status",
    headerName: "Status",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <Link to={`/controlpanel/modifyledger?id=${params.row.Ledgercode}`} state={{...params.row}}>
            <div className="action-icons">
              <img
                src="../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Edit"
      // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="action-icons">
            <Link to={`/controlpanel/suspendledger?id=${params.row.Ledgercode}`} state={{...params.row}}>
              <img
                src="../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Delete"
      // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="action-icons">
            <Link to={`/controlpanel/ledgerbalance}`} state={{...params.row}}>
              <img
                src="../icons/Actions-1.1.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Action1"
      // onClick={alert("delete")}
      />,
    ],
  },
];

export const Tokenscolumns = [
  {
    field: "PublicAddress",
    headerName: "Public Address",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Currencycode",
    headerName: "Currency",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Objectcode",
    headerName: "Token Symbol",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "TransactionTypecode",
    headerName: "Token Type",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Displayname",
    headerName: "Token",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <Link to={`/controlpanel/modifytoken?id=${params.row.TokenSymbol}`}>
            {/* <Link to={`/controlpanel/modifytoken`}> */}
            <div className="action-icons">
              <img
                src="../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Delete"
      // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/controlpanel/suspendtoken?id=${params.row.TokenSymbol}`} state={{...params.row}}>
            <div tooltip="Suspend" flow="down" className="action-icons">
              <img
                src="../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action1"
      // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/controlpanel/pause?id=${params.row.TokenSymbol}`}>
            <div tooltip="Pause" flow="down" className="action-icons">
              <img
                src="../icons/Actions-10.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action2"
      // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/controlpanel/unpause?id=${params.row.TokenSymbol}`}>
            <div tooltip="Unpause" flow="down" className="action-icons">
              <img
                src="../icons/Action-18.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Edit"
      // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/controlpanel/mint?id=${params.row.TokenSymbol}`}>
            <div tooltip="Mint" flow="down" className="action-icons">
              <img
                src="../icons/Actions-4.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action3"
      // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/controlpanel/burn?id=${params.row.TokenSymbol}`}>
            <div tooltip="Burn" flow="down" className="action-icons">
              <img
                src="../icons/Actions-5.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action4"
      // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/controlpanel/redeem?id=${params.row.TokenSymbol}`}>
            <div tooltip="Redeem" flow="down" className="action-icons">
              <img
                src="../icons/Actions-6.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action5"
      // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/controlpanel/swap?id=${params.row.TokenSymbol}`}>
            <div tooltip="Swap" flow="down" className="action-icons">
              <img
                src="../icons/Actions-7.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action6"
      // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/controlpanel/tokensupply?id=${params.row.TokenSymbol}`}>
            <div tooltip="Supply" flow="down" className="action-icons">
              <img
                src="../icons/Action-15.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action7"
      // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/controlpanel/tokenprice?id=${params.row.TokenSymbol}`}>
            <div tooltip="Price" flow="down" className="action-icons">
              <img
                src="../icons/Action-16.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Action8"
      // onClick={alert("delete")}
      />,
    ],
  },
];

export const Walletscolumns = [
  {
    field: "WalletTypecode",
    headerName: " Wallet Class",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "WalletType",
    headerName: " Wallet Type",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "PublicAddress",
    headerName: "Public Address",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Countrycode",
    headerName: "Country",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Status",
    headerName: "Status",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <div className="action-icons">
            <Link to={`/controlpanel/modifywallet?id=${params.row.RowID}`}>
              <img
                src="../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Edit"
      // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="action-icons">
            <Link
              to={`/controlpanel/suspendwallet?id=${params.row.PublicAddress}`}
              state={{...params.row}}
            >
              <img
                src="../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Delete"
      // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="action-icons">
            <Link to={`/controlpanel/pausewallet?id=${params.row.PublicAddress}`}>
              <img
                src="../icons/Actions-10.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Action1"
      // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="action-icons">
            <Link
              to={`/controlpanel/unpausewallet?id=${params.row.PublicAddress}`}
            >
              <img
                src="../icons/Action-18.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Action2"
      // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="action-icons">
            <Link to={`/controlpanel/attachwallet`}>
              <img
                src="../icons/Actions-pin.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Action3"
      // onClick={alert("delete")}
      />,
    ],
  },
];

export const Peoplecolumns = [
  {
    field: "Countrycode",
    headerName: "Country Code",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Displayname",
    headerName: "Display Name",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "DOB",
    headerName: "DOB",
    type: "string",
    flex: 1,
    align: "right",
    headerAlign: "right",
  },

  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <div className="action-icons">
            <img
              src="../icons/Actions-pin.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </div>
        }
        label="Pin"
      // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="action-icons">
            <img
              src="../icons/Actions-user.png"
              alt="Wallets-icon-img"
              size={10}
            />
          </div>
        }
        label="Users"
      // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="action-icons">
            <Link to={`/controlpanel/modifypeople?id=${params.row.Objectcode}`}>
              <img
                src="../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Edit"
      // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <div className="action-icons">
            <Link to={`/controlpanel/suspendpeople?id=${params.row.UUID}`}>
              <img
                src="../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </Link>
          </div>
        }
        label="Delete"
      />,
    ],
  },
];

export const Entitycolumns = [
  {
    field: "PublicAddress",
    headerName: "Public Address",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Displayname",
    headerName: "Displayname",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "TransactionType",
    headerName: "Transaction Type",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Website",
    headerName: "Website",
    flex: 1,
    type: "string",
    align: "right",
    headerAlign: "right",
  },
  {
    field: "Status",
    headerName: "Status",
    type: "string",
    flex: 1,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "Actions",
    type: "actions",
    headerName: "Actions",
    flex: 1,
    getActions: (params) => [
      <GridActionsCellItem
        icon={
          <Link to={`/controlpanel/modifyentity?id=${params.row.Objectcode}`}>
            <div className="action-icons">
              <img
                src="../icons/Actions-edit.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Edit"
      // onClick={alert("delete")}
      />,
      <GridActionsCellItem
        icon={
          <Link to={`/controlpanel/suspendentity?id=${params.row.Objectcode}`} state={{...params.row}}>
            <div className="action-icons">
              <img
                src="../icons/Actions-trash.png"
                alt="Wallets-icon-img"
                size={10}
              />
            </div>
          </Link>
        }
        label="Delete"
      // onClick={alert("delete")}
      />,
    ],
  },
];
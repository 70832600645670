import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getDropdownvalues } from "../../../../API/dropdown";
import { GetTokenSymbols } from "../../../../API/LedgerAPI";
// import { CreateLedgerApi } from "../../../../api/LedgerAPI";
function CreateLedger({ nextstep, setdata }) {
    let { pathname } = useLocation();
    const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
    const [ledgerTypedropdown, setLedgerTypedropdown] = useState([]);
    const [interestratedropdown, setInterestrateropdown] = useState([]);
    const [TokenSymbol, setTokenSymbol] = useState([]);
    const publicKeys = useSelector((state) => state.keyReducer);
    const fetchdropdwon = async () => {
        let dropdownvalues = await getDropdownvalues("LKLedgerType");
        setLedgerTypedropdown(dropdownvalues.commandDataset);
        let interestratedropdownvalues = await getDropdownvalues("LKInterestType");
        setInterestrateropdown(interestratedropdownvalues.commandDataset);
        let tokenSymboldropdownvalues = await GetTokenSymbols("GetTokenSymbols");
        setTokenSymbol(tokenSymboldropdownvalues);
    };
    useEffect(() => {
        if (publicKeys?.publicKey?.publicAddress) {
            setisValidPublicAddress(true);
            fetchdropdwon();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicKeys]);
    const initialValues = {
        // sourcePublicAddress: "9c9823fb-008e-4037-b73b-13c5cc303b9b",
        ledgerTypeKeycode: "",
        tokenSymbol: "",
        ledgercode: "",
        ledger: "",
        displayname: "",
        minimumDeposit: 0,  
        minimumBalance: 0,
        maximumDeposit: 0,
        maximumBalance: 0,
        interestTypecode: "",
        annualInterestRate: 0,
        compoundPeriodInDays: 0,
        graceDaysForDeployment: 0,
        minimumSubscriptionDays: 0,
        monthlyFee: 0,
        belowMinimumDefaultFee: 0,
        overdraftProtection: false,
        overdraftMaximumAmount: 0,
        overdraftFee: 0,

        UserDeposit: false,
        UserWithdraw: false,
        UserSendMoney: false,
        UserReceiveMoney: false,
        OwnerDeposit: false,
        OwnerWithdraw: false,
        OwnerSendMoney: false,
        OwnerReceiveMoney: false,

        // userDirectDepositAllowed: false,
        // userDirectWithdrawAllowed: false,
        // userTransferDepositAllowed: false,
        // userTransferWithdrawAllowed: false,
        // ownerDirectDepositAllowed: false,
        // ownerDirectWithdrawAllowed: false,
        // ownerTransferDepositAllowed: false,
        // ownerTransferWithdrawAllowed: false

    };
    // eslint-disable-next-line no-unused-vars
    const validationSchema = Yup.object({
        ledger: Yup.string().required("Ledger Name is required!"),
        displayname: Yup.string().required("Display Name is required!"),
        ledgerTypeKeycode: Yup.string().required("Ledger Type is required!"),
        ledgercode: Yup.string().required("Ledger Code is required!"),
        // TokenSystem: Yup.string().required("Token System is required!"),
        currencycode: Yup.string().required("Currency Code is required!"),
        overdraftMaximumAmount: Yup.string().required(
            "Maximum Overdraft Amount is required!"
        ),
        overdraftFee: Yup.string().required("Overdraft Free is required!"),
        minimumDeposit: Yup.string().required("Minimum Deposit is required!"),
        maximumDeposit: Yup.string().required("Maximum Deposit is required!"),
        minimumBalance: Yup.string().required("Minimum Balance is required!"),
        maximumBalance: Yup.string().required("Maximum Balance is required!"),
        interestTypecode: Yup.string().required(
            "Annualized Interest Rate Type is required!"
        ),
        annualInterestRate: Yup.string().required(
            "Annualized Interest Rate is required!"
        ),
        compoundPeriodInDays: Yup.string().required(
            "Compound Interest Period is required!"
        ),
        graceDaysForDeployment: Yup.string().required(
            "Grace Days for Interest Accrual is required!"
        ),
        minimumSubscriptionDays: Yup.string().required(
            "Minimum Subscription Days is required!"
        ),
        monthlyFee: Yup.string().required("Monthly Account Fee is required!"),
        belowMinimumDefaultFee: Yup.string().required(
            "Minimum Balance Default Fee is required!"
        ),
    });
    return (
        <>
            {isValidPublicAddress && (
                <div className="col-md  ms-lg-4 ms-xl-5 ">
                           
                    <div className="main-heading">
                        <h3>Ledger</h3>
                        <h6>
                            {pathname === "/controlpanel/modifyledger"
                                ? "Modify Ledger"
                                : "Create Ledger"}
                        </h6>
                        <p>
                            This function enables the creation of any type of asset or liability ledger for transferring digital currencies.

                        </p>
                    </div>
                    <div className="transfer-rates-page">
                        <div className="transfer-rates">
                            <ul className="nav nav-tabs justify-content-around" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"><h3 className="m-0 ">Single </h3></button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false"><h3 className="m-0">Batch </h3></button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false"><h3 className="m-0">File Format</h3></button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="tab-content mt-4" id="myTabContent">
                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                            <div className="container-fluid p-0">
                                <div className="row">
                                    <div className="col-12">
                                        <Formik
                                            initialValues={initialValues}
                                            // validationSchema={validationSchema}
                                            onSubmit={(values) => {
                                                setdata(values);
                                                //   CreateLedgerApi(values);
                                                // console.log(values);
                                                nextstep();
                                            }}
                                        >
                                            {(formik) => {
                                                const { setFieldValue } = formik;
                                                return (
                                                    <div className="wallet-information-box m-0">
                                                        <h4>Ledger Information</h4>
                                                        <Form className="wallet-information-form">
                                                            <div className="row mb-xl-5">
                                                                <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                                    <h6>Basic Information</h6>
                                                                    <div className="mb-5">
                                                                        <div className="input-box form-floating">
                                                                            <Field
                                                                                type="text"
                                                                                name="ledger"
                                                                                id="ledger"
                                                                                placeholder="Ledger Name"
                                                                                className="form-control"
                                                                            />
                                                                            <label className="ps-0 pb-0">Ledger Name</label>
                                                                            <i className="bi bi-check-lg"></i>
                                                                        </div>
                                                                        <div style={{ color: "red" }}>
                                                                            <ErrorMessage
                                                                                name="ledger"
                                                                                component="span"
                                                                                className="error"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-5">
                                                                        <div className="input-box form-floating">
                                                                            <Field
                                                                                type="text"
                                                                                name="displayname"
                                                                                id="displayname"
                                                                                placeholder="Display Name"
                                                                                className="form-control"
                                                                                value={formik.values.displayname}
                                                                            />
                                                                            <label className="ps-0 pb-0">Display Name</label>
                                                                            <i className="bi bi-check-lg"></i>
                                                                        </div>
                                                                        <div style={{ color: "red" }}>
                                                                            <ErrorMessage
                                                                                name="displayname"
                                                                                component="span"
                                                                                className="error"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-5">
                                                                        <label>Ledger Type</label>
                                                                        <Field
                                                                            as="select"
                                                                            className="form-select form-control p-2"
                                                                            id="ledgerTypeKeycode"
                                                                            onChange={(e) => {
                                                                                setFieldValue(
                                                                                    "ledgerTypeKeycode",
                                                                                    e.target.value
                                                                                );
                                                                                // console.log("🚀 ~ file: CreateLedger.js ~ line 192 ~ CreateLedger ~ e.target.value", e.target.value)
                                                                            }}
                                                                            onBlur={formik.handleBlur}
                                                                            value={formik.values.ledgerTypeKeycode}
                                                                        >
                                                                            {ledgerTypedropdown &&
                                                                                ledgerTypedropdown.map((value, index) => {
                                                                                    return (
                                                                                            <option
                                                                                                value={value.Keycode}
                                                                                                key={index}
                                                                                            >
                                                                                                {value.Displayname}
                                                                                            </option>
                                                                                    );
                                                                                })}
                                                                        </Field>
                                                                        <div style={{ color: "red" }}>
                                                                            <ErrorMessage
                                                                                name="ledgerTypeKeycode"
                                                                                component="span"
                                                                                className="error"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-5">
                                                                        <div className="input-box form-floating">
                                                                            <Field
                                                                                type="text"
                                                                                name="ledgercode"
                                                                                id="ledgercode"
                                                                                placeholder="Ledger Code"
                                                                                className="form-control"
                                                                                value={formik.values.ledgercode}
                                                                            />
                                                                            <label className="ps-0 pb-0">Ledger Code</label>
                                                                            <i className="bi bi-check-lg"></i>
                                                                        </div>
                                                                        <div style={{ color: "red" }}>
                                                                            <ErrorMessage
                                                                                name="ledgercode"
                                                                                component="span"
                                                                                className="error"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="mb-5">
                                                                        <label>Token Symbol</label>
                                                                        <Field
                                                                            as="select"
                                                                            className="form-select form-control p-2"
                                                                            id="tokenSymbol"
                                                                            onChange={(e) => {
                                                                                setFieldValue("tokenSymbol", e.target.value);
                                                                            }}
                                                                            onBlur={formik.handleBlur}
                                                                            value={formik.values.tokenSymbol}
                                                                        >
                                                                            {TokenSymbol &&
                                                                                TokenSymbol.map((value, index) => {
                                                                                    return (
                                                                                            <option
                                                                                                value={value.tokenSymbol}
                                                                                                key={index}
                                                                                            >
                                                                                                {value.displayname}
                                                                                            </option>
                                                                                    );
                                                                                })}
                                                                        </Field>
                                                                        <div style={{ color: "red" }}>
                                                                            <ErrorMessage
                                                                                name="TokenSystem"
                                                                                component="span"
                                                                                className="error"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    {/* <div className="mb-5">
                              <label>Currency code </label>
                              <Field
                                as="select"
                                className="form-select form-control"
                                id="CurrencycodeList"
                                // disabled={true}
                                onChange={(e) => {
                                  setFieldValue("CurrencycodeList", e.target.value);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.CurrencycodeList}
                              >
                                {TokenSymbol &&
                                  TokenSymbol.map((value, index) => {
                                    return (
                                      <>
                                        <option
                                          value={value.tokenSymbol}
                                          key={index}
                                        >
                                          {value.displayname}
                                        </option> 
                                      </>
                                    );
                                  })}
                              </Field>
                              <div style={{ color: "red" }}>
                                <ErrorMessage
                                  name="CurrencycodeList"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div> */}
                                                                </div>
                                                                <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                                    <h6>Range</h6>
                                                                    <div className="mb-5">
                                                                        <div className="input-box form-floating">
                                                                            <Field
                                                                                type="number"
                                                                                name="minimumDeposit"
                                                                                id="minimumDeposit"
                                                                                placeholder="Minimum Deposit"
                                                                                className="form-control"
                                                                                value={formik.values.minimumDeposit}
                                                                            />
                                                                            <label className="ps-0 pb-0">Minimum Deposit</label>
                                                                            <i className="bi bi-check-lg"></i>
                                                                        </div>
                                                                        <div style={{ color: "red" }}>
                                                                            <ErrorMessage
                                                                                name="minimumDeposit"
                                                                                component="span"
                                                                                className="error"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-5">
                                                                        <div className="input-box form-floating">
                                                                            <Field
                                                                                type="number"
                                                                                name="maximumDeposit"
                                                                                id="maximumDeposit"
                                                                                placeholder="Maximum Deposit"
                                                                                className="form-control"
                                                                                value={formik.values.maximumDeposit}
                                                                            />
                                                                            <label className="ps-0 pb-0">Maximum Deposit</label>
                                                                            <i className="bi bi-check-lg"></i>
                                                                        </div>
                                                                        <div style={{ color: "red" }}>
                                                                            <ErrorMessage
                                                                                name="maximumDeposit"
                                                                                component="span"
                                                                                className="error"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-5">
                                                                        <div className="input-box form-floating">
                                                                            <Field
                                                                                type="number"
                                                                                name="minimumBalance"
                                                                                id="minimumBalance"
                                                                                placeholder="Minimum Balance"
                                                                                className="form-control"
                                                                                value={formik.values.minimumBalance}
                                                                            />
                                                                            <label className="ps-0 pb-0">Minimum Balance </label>
                                                                            <i className="bi bi-check-lg"></i>
                                                                        </div>
                                                                        <div style={{ color: "red" }}>
                                                                            <ErrorMessage
                                                                                name="minimumBalance"
                                                                                component="span"
                                                                                className="error"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-5">
                                                                        <div className="input-box form-floating">
                                                                            <Field
                                                                                type="number"
                                                                                name="maximumBalance"
                                                                                id="maximumBalance"
                                                                                placeholder="Maximum Balance"
                                                                                className="form-control"
                                                                                value={formik.values.maximumBalance}
                                                                            />
                                                                            <label className="ps-0 pb-0">Maximum Balance </label>
                                                                            <i className="bi bi-check-lg"></i>
                                                                        </div>
                                                                        <div style={{ color: "red" }}>
                                                                            <ErrorMessage
                                                                                name="maximumBalance"
                                                                                component="span"
                                                                                className="error"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                                    <h6>Interest Rate</h6>
                                                                    <div className="mb-5">
                                                                        <label>Annualized Interest Rate Type</label>
                                                                        <div className="input-box">
                                                                            <Field
                                                                                as="select"
                                                                                name="interestTypecode"
                                                                                id="interestTypecode"
                                                                                placeholder="Annualized Interest Rate Type"
                                                                                className="form-select form-control p-2"
                                                                                onChange={(e) => {
                                                                                    setFieldValue(
                                                                                        "interestTypecode",
                                                                                        e.target.value
                                                                                    );
                                                                                }}
                                                                                onBlur={formik.handleBlur}
                                                                                value={formik.values.interestTypecode}
                                                                            >
                                                                                {interestratedropdown &&
                                                                                    interestratedropdown.map((value, index) => {
                                                                                        return (
                                                                                                <option
                                                                                                    value={value.Keycode}
                                                                                                    key={index}
                                                                                                >
                                                                                                    {value.Displayname}
                                                                                                </option>
                                                                                        );
                                                                                    })}
                                                                            </Field>

                                                                        </div>
                                                                        <div style={{ color: "red" }}>
                                                                            <ErrorMessage
                                                                                name="interestTypecode"
                                                                                component="span"
                                                                                className="error"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-5">
                                                                        <div className="input-box form-floating">
                                                                            <Field
                                                                                type="number"
                                                                                name="annualInterestRate"
                                                                                id="annualInterestRate"
                                                                                placeholder="Annualized Interest Rate"
                                                                                className="form-control"
                                                                                value={formik.values.annualInterestRate}
                                                                            />
                                                                            <label className="ps-0 pb-0">Annualized Interest Rate</label>
                                                                            <i className="bi bi-check-lg"></i>
                                                                        </div>
                                                                        <div style={{ color: "red" }}>
                                                                            <ErrorMessage
                                                                                name="annualInterestRate"
                                                                                component="span"
                                                                                className="error"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-5">
                                                                        <div className="input-box form-floating">
                                                                            <Field
                                                                                type="number"
                                                                                name="compoundPeriodInDays"
                                                                                id="compoundPeriodInDays"
                                                                                placeholder="Compound Interest Periodin Days"
                                                                                className="form-control"
                                                                                value={formik.values.compoundPeriodInDays}
                                                                            />
                                                                            <label className="ps-0 pb-0">Compound Interest Period in Days </label>
                                                                            <i className="bi bi-check-lg"></i>
                                                                        </div>
                                                                        <div style={{ color: "red" }}>
                                                                            <ErrorMessage
                                                                                name="compoundPeriodInDays"
                                                                                component="span"
                                                                                className="error"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-5">
                                                                        <div className="input-box form-floating">
                                                                            <Field
                                                                                type="number"
                                                                                name="graceDaysForDeployment"
                                                                                id="graceDaysForDeployment"
                                                                                placeholder="Grace Days for Interest Accrual"
                                                                                className="form-control"
                                                                                value={formik.values.graceDaysForDeployment}
                                                                            />
                                                                            <label className="ps-0 pb-0">Grace Days for Interest Accrual </label>
                                                                            <i className="bi bi-check-lg"></i>
                                                                        </div>
                                                                        <div style={{ color: "red" }}>
                                                                            <ErrorMessage
                                                                                name="graceDaysForDeployment"
                                                                                component="span"
                                                                                className="error"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                                    <h6>Subscription & Fee</h6>
                                                                    <div className="mb-5">
                                                                        <div className="input-box form-floating">
                                                                            <Field
                                                                                type="number"
                                                                                name="minimumSubscriptionDays"
                                                                                id="minimumSubscriptionDays"
                                                                                placeholder="Minimum Subscription Days"
                                                                                className="form-control"
                                                                            />
                                                                            <label className="ps-0 pb-0">Minimum Subscription Days</label>
                                                                            <i className="bi bi-check-lg"></i>
                                                                            <div style={{ color: "red" }}>
                                                                                <ErrorMessage
                                                                                    name="minimumSubscriptionDays"
                                                                                    component="span"
                                                                                    className="error"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-5">
                                                                        <div className="input-box form-floating">
                                                                            <Field
                                                                                type="number"
                                                                                name="monthlyFee"
                                                                                id="monthlyFee"
                                                                                placeholder="Monthly Account Fee"
                                                                                className="form-control"
                                                                                value={formik.values.monthlyFee}
                                                                            />
                                                                            <label className="ps-0 pb-0">Monthly Account Fee</label>
                                                                            <i className="bi bi-check-lg"></i>
                                                                        </div>
                                                                        <div style={{ color: "red" }}>
                                                                            <ErrorMessage
                                                                                name="monthlyFee"
                                                                                component="span"
                                                                                className="error"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-5">
                                                                        <div className="input-box form-floating">
                                                                            <Field
                                                                                type="number"
                                                                                name="belowMinimumDefaultFee"
                                                                                id="belowMinimumDefaultFee"
                                                                                placeholder="Minimum Balance Default Fee"
                                                                                className="form-control"
                                                                                value={formik.values.belowMinimumDefaultFee}
                                                                            />
                                                                            <label className="ps-0 pb-0">Minimum Balance Default Fee </label>
                                                                            <i className="bi bi-check-lg"></i>
                                                                        </div>
                                                                        <div style={{ color: "red" }}>
                                                                            <ErrorMessage
                                                                                name="belowMinimumDefaultFee"
                                                                                component="span"
                                                                                className="error"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                                    <h6>Overdraft</h6>
                                                                    <div className="mb-5">
                                                                        <label>Overdraft Protection Enabled</label>
                                                                        <div className="form-check form-switch">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id="flexSwitchCheckChecked"
                                                                                onChange={(e) => {
                                                                                    setFieldValue(
                                                                                        "overdraftProtection",
                                                                                        e.target.checked
                                                                                    );
                                                                                }}
                                                                                value={formik.values.overdraftProtection}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-5">
                                                                        <div className="input-box form-floating">
                                                                            <Field
                                                                                type="number"
                                                                                name="overdraftMaximumAmount"
                                                                                id="overdraftMaximumAmount"
                                                                                placeholder="Maximum Overdraft Amount"
                                                                                className="form-control"
                                                                                value={formik.values.overdraftMaximumAmount}
                                                                            />
                                                                            <label className="ps-0 pb-0">Maximum Overdraft Amount</label>
                                                                            <i className="bi bi-check-lg"></i>
                                                                        </div>
                                                                        <div style={{ color: "red" }}>
                                                                            <ErrorMessage
                                                                                name="overdraftMaximumAmount"
                                                                                component="span"
                                                                                className="error"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-5">
                                                                        <div className="input-box form-floating">
                                                                            <Field
                                                                                type="number"
                                                                                name="overdraftFee"
                                                                                id="overdraftFee"
                                                                                placeholder="Overdraft Fee"
                                                                                className="form-control"
                                                                                value={formik.values.overdraftFee}
                                                                            />
                                                                            <label className="ps-0 pb-0">Overdraft Fee </label>
                                                                            <i className="bi bi-check-lg"></i>
                                                                        </div>
                                                                        <div style={{ color: "red" }}>
                                                                            <ErrorMessage
                                                                                name="overdraftFee"
                                                                                component="span"
                                                                                className="error"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                                    <h6>User Action Settings</h6>
                                                                    <div className="mb-5">
                                                                        <label>Deposits Allowed</label>
                                                                        <div className="form-check form-switch">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id="flexSwitchCheckChecked"
                                                                                onChange={(e) => {
                                                                                    setFieldValue(
                                                                                        "UserDeposit",
                                                                                        e.target.checked
                                                                                    );
                                                                                }}
                                                                                value={formik.values.UserDeposit}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-5">
                                                                        <label> Withdrawals Allowed</label>
                                                                        <div className="form-check form-switch">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id="flexSwitchCheckChecked"
                                                                                onChange={(e) => {
                                                                                    setFieldValue(
                                                                                        "UserWithdraw",
                                                                                        e.target.checked
                                                                                    );
                                                                                }}
                                                                                value={
                                                                                    formik.values.UserWithdraw
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-5">
                                                                        <label>Send Money Allowed</label>
                                                                        <div className="form-check form-switch">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id="flexSwitchCheckChecked"
                                                                                onChange={(e) => {
                                                                                    setFieldValue(
                                                                                        "UserSendMoney",
                                                                                        e.target.checked
                                                                                    );
                                                                                }}
                                                                                value={
                                                                                    formik.values.UserSendMoney
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-5">
                                                                        <label>Receive Money Allowed</label>
                                                                        <div className="form-check form-switch">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id="flexSwitchCheckChecked"
                                                                                onChange={(e) => {
                                                                                    setFieldValue(
                                                                                        "UserReceiveMoney",
                                                                                        e.target.checked
                                                                                    );
                                                                                }}
                                                                                value={
                                                                                    formik.values.UserReceiveMoney
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                                                    <h6>Owner Action Settings</h6>
                                                                    <div className="mb-5">
                                                                        <label>Deposits Allowed</label>
                                                                        <div className="form-check form-switch">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id="flexSwitchCheckChecked"
                                                                                onChange={(e) => {
                                                                                    setFieldValue(
                                                                                        "OwnerDeposit",
                                                                                        e.target.checked
                                                                                    );
                                                                                }}
                                                                                value={
                                                                                    formik.values.OwnerDeposit
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-5">
                                                                        <label>Withdrawals Allowed</label>
                                                                        <div className="form-check form-switch">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id="flexSwitchCheckChecked"
                                                                                onChange={(e) => {
                                                                                    setFieldValue(
                                                                                        "OwnerWithdraw",
                                                                                        e.target.checked
                                                                                    );
                                                                                }}
                                                                                value={
                                                                                    formik.values.OwnerWithdraw
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-5">
                                                                        <label>Send Money Allowed</label>
                                                                        <div className="form-check form-switch">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id="flexSwitchCheckChecked"
                                                                                onChange={(e) => {
                                                                                    setFieldValue(
                                                                                        "OwnerSendMoney",
                                                                                        e.target.checked
                                                                                    );
                                                                                }}
                                                                                value={
                                                                                    formik.values.OwnerSendMoney
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="mb-5">
                                                                        <label>Receive Money Allowed</label>
                                                                        <div className="form-check form-switch">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                id="flexSwitchCheckChecked"
                                                                                onChange={(e) => {
                                                                                    setFieldValue(
                                                                                        "OwnerReceiveMoney",
                                                                                        e.target.checked
                                                                                    );
                                                                                }}
                                                                                value={
                                                                                    formik.values.OwnerReceiveMoney
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5">
                                                                <button
                                                                    type="button"
                                                                    className="btn cancel-btn mb-3 mb-md-0 me-4"
                                                                >
                                                                    CANCEL
                                                                </button>
                                                                <button
                                                                    type="submit"
                                                                    className="btn next-btn mb-3 mb-md-0"
                                                                // onClick={nextstep}
                                                                >
                                                                    NEXT
                                                                </button>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                );
                                            }}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <div className="row">
                                {/*<h5 className="mb-3">*/}
                                {/*    Upload a Limit Rules File in the proper*/}
                                {/*    file format for batched processing*/}
                                {/*</h5>*/}
                                <div className="col-sm-6 col-xl-4">
                                    <div className="mb-5">
                                        <label className="mb-3">
                                            Batch Upload
                                        </label>
                                        <div className="input-box">
                                            <div className="input-group custom-file-button">
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    id="inputGroupFile"
                                                />
                                                <label
                                                    className="input-group-text"
                                                    htmlFor="inputGroupFile"
                                                >
                                                    BROWSE
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                            <div className="row">
                                <h5 className="mb-3">
                                    Upload a pipe delimited ("|") file with the following fields in sequential order. The first row is the field names header and is ignored.
                                </h5>
                                <div className="wallet-balances-box m-0">
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Field Name</th>
                                                    <th>Field Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/*<tr>*/}
                                                {/*    <td>First Name</td>*/}
                                                {/*    <td>*/}
                                                {/*        The first name of the user{" "}*/}
                                                {/*    </td>*/}
                                                {/*</tr>*/}
                                                {/*<tr>*/}
                                                {/*    <td>Middle Name</td>*/}
                                                {/*    <td>*/}
                                                {/*        The middle name of the user{" "}*/}
                                                {/*    </td>*/}
                                                {/*</tr>*/}
                                                {/*<tr>*/}
                                                {/*    <td>Email</td>*/}
                                                {/*    <td>The email address </td>*/}
                                                {/*</tr>*/}
                                                {/*<tr>*/}
                                                {/*    <td>Password</td>*/}
                                                {/*    <td>The login password </td>*/}
                                                {/*</tr>*/}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default CreateLedger;

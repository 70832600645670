import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDropdownvalues } from "../../../../../API/dropdown";
import { getTokenBySymbol } from "../../../../../API/Token";
import { decrypytdata } from "../../../../../API/walletkey";
import * as Yup from "yup";
// import PrivateModal from "../../../../../Components/PrivateModal";

function Originator({ nextstep, setdata }) {
  const [isValidPublicAddress, setisValidPublicAddress] = useState(false);
  const [tokenClassdropdown, setTokenClassdropdown] = useState([]);
  const [Supplydropdown, setSupplydropdown] = useState([]);
  const [tokenTypedropdown, setTokenTypedropdown] = useState([]);
  const publicKeys = useSelector((state) => state.keyReducer);
  const [tokenData, settokensData] = useState([]);
  // const [tokensData, settokensData] = useState([]);

  let APPID = "";
  let APIKEY = "";
  const fetchdata = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }

    if (APPID && APIKEY) {
      const encryptedLedger = await getTokenBySymbol(APPID, APIKEY);
      const tokens = await decrypytdata(encryptedLedger.data, APIKEY);
      // setRows(JSON.parse(tokens));

      let data = JSON.parse(JSON.parse(tokens).CommandResponse);

      if (data) {
        let dataArr = [];
        data?.forEach((dataMain, index) => {
          // multiple data
          dataArr.push({ ...dataMain, rowid: index });
        });
        const dataTokens = Object.assign({}, ...dataArr);
        settokensData(dataTokens);
      }
    }
  };

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      setisValidPublicAddress(true);
    }
    fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);

  const fetchdropdwon = async () => {
    let LKTokenClass = await getDropdownvalues("LKTokenClass");
    setTokenClassdropdown(LKTokenClass.commandDataset);
    let LKSupplyType = await getDropdownvalues("LKSupplyType");
    setSupplydropdown(LKSupplyType.commandDataset);
    let LKTokenType = await getDropdownvalues("LKTokenType");
    setTokenTypedropdown(LKTokenType.commandDataset);
  };
  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      setisValidPublicAddress(true);
      fetchdropdwon();
    }
  }, [publicKeys]);

  let initialValues = {
    tokenClassKeycode: "",
    tokenTypeKeycode: "",
    tokenSymbol: "",
    displayname: "",
    maximumSupply: 0,
    initialSupply: 0,
    token: "",
    // currencycodeList: "",
    supplyTypecode: "",
    issuedSupply: 0,
    circulatingSupply: 0,
    frozenSupply:0,
    decimalPlaces: 0,
    description: "",
    countrycode:"",
    // imageFN: "",
  };

  const validationSchema = Yup.object({
    tokenSymbol: Yup.string().required("Token Symbol Should be 5 Character"),
    // displayname: Yup.string().required("Display Name and Token Name should be same!"),
    token: Yup.string().required("Display Name and Token Name should be same!"),
    tokenClassKeycode: Yup.string().required("Token Class  is required!"),
    supplyTypecode: Yup.string().required("Supply Type is required!"),
    tokenTypeKeycode: Yup.string().required("Token Type is required!"),
  });
  return (
    <>
      {/* {privateModal && (
                <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
            )} */}
      {isValidPublicAddress && (
        <div className="row">
          <div className="col-12">
            <div className="originating-account link-wallet-box create-page ms-0">
              <h3>Token</h3>
              <h5 className="mt-0">
                {/* {pathname === "/controlpanel/modifytoken"
                  ? "Modify Token"
                  : "Create Token"} */}
              </h5>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                  setdata(values);
                  /* console.log(
                    "🚀 ~ file: Originator.js ~ line 121 ~ Originator ~ values",
                    values
                  ); */
                  //   CreateLedgerApi(values);
                  nextstep();
                }}
              >
                {(formik) => {
                  const { setFieldValue } = formik;
                  return (
                    <div className="wallet-information-box  m-0">
                      <Form className="wallet-information-form">
                        <div className="row mb-xl-5">
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Basic Information</h6>
                            <div className="mb-5">
                              <label>Token Class</label>
                              <Field
                                as="select"
                                id="tokenClassKeycode"
                                name="tokenClassKeycode"
                                className="form-select form-control p-2"
                                onChange={(e) => {
                                  setFieldValue(
                                    "tokenClassKeycode",
                                    e.target.value
                                  );
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.tokenClassKeycode}
                              >
                                {tokenClassdropdown &&
                                  tokenClassdropdown.map((value, index) => {
                                    return (
                                        <option
                                          value={value.Keycode}
                                          key={index}
                                        >
                                          {value.Displayname}
                                        </option>
                                    );
                                  })}
                              </Field>
                              <div style={{ color: "red" }}>
                                <ErrorMessage
                                  name="tokenClassKeycode"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Token Type</label>
                              <Field
                                as="select"
                                id="tokenTypeKeycode"
                                className="form-select form-control p-2"
                                name="tokenTypeKeycode"
                                onChange={(e) => {
                                  setFieldValue(
                                    "tokenTypeKeycode",
                                    e.target.value
                                  );
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.tokenTypeKeycode}
                              >
                                {tokenTypedropdown &&
                                  tokenTypedropdown.map((value, index) => {
                                    return (
                                        <option
                                          value={value.Keycode}
                                          key={index}
                                        >
                                          {value.Displayname}
                                        </option>
                                    );
                                  })}
                              </Field>
                              <div style={{ color: "red" }}>
                              <ErrorMessage
                                name="tokenTypeKeycode"
                                component="span"
                                className="error"
                              />
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="tokenSymbol"
                                  id="tokenSymbol"
                                  placeholder="Token Symbol"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Token Symbol
                                </label>
                                <i className="bi bi-check-lg"></i>
                                <div style={{ color: "red" }}>
                                  <ErrorMessage
                                    name="tokenSymbol"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="token"
                                  id="token"
                                  placeholder="Token"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">Token Name</label>
                                <i className="bi bi-check-lg"></i>
                                <div style={{ color: "red" }}>
                                <ErrorMessage
                                  name="token"
                                  component="span"
                                  className="error"
                                />
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="text"
                                  name="displayname"
                                  id="displayname"
                                  placeholder="Display Name"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Display Name (Alias)
                                </label>
                                <i className="bi bi-check-lg"></i>
                                <ErrorMessage
                                  name="displayname"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                            <h6>Supply </h6>
                            <div className="mb-5">
                              <label>Supply Type</label>
                              <Field
                                as="select"
                                id="supplyTypecode"
                                name="supplyTypecode"
                                onChange={(e) => {
                                  setFieldValue(
                                    "supplyTypecode",
                                    e.target.value
                                  );
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.supplyTypecode}
                                className="form-select form-control p-2"
                              >
                                {Supplydropdown &&
                                  Supplydropdown.map((value, index) => {
                                    return (
                                        <option
                                          value={value.Keycode}
                                          key={index}
                                        >
                                          {value.Displayname}
                                        </option>
                                    );
                                  })}
                              </Field>
                              <div style={{ color: "red" }}>
                                <ErrorMessage
                                  name="supplyTypecode"
                                  component="span"
                                  className="error"
                                />
                                </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="number"
                                  name="maximumSupply"
                                  id="maximumSupply"
                                  placeholder="Maximum Supply"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Maximum Supply
                                </label>
                                <i className="bi bi-check-lg"></i>
                                <ErrorMessage
                                  name="maximumSupply"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="input-box form-floating">
                                <Field
                                  type="number"
                                  name="initialSupply"
                                  id="initialSupply"
                                  placeholder="Initial Supply"
                                  className="form-control"
                                />
                                <label className="ps-0 pb-0">
                                  Initial Supply
                                </label>
                                <i className="bi bi-check-lg"></i>
                                <ErrorMessage
                                  name="initialSupply"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="mb-5">
                              <label>Decimal Place</label>
                              <Field
                                as="select"
                                type="number"
                                id="decimalPlaces"
                                name="decimalPlaces"
                                onBlur={formik.handleBlur}
                                value={formik.values.decimalPlaces}
                                className="form-select form-control p-2"
                                onChange={(e) => {
                                  setFieldValue(
                                    "decimalPlaces",
                                    e.target.value
                                  );
                                }}
                              >
                                <option defaultValue >--Select--</option>
                                <option value={1}>Two</option>
                                <option value={2}>Three</option>
                                <option value={3}>Four</option>
                                <option value={4}>Five</option>
                                <option value={5}>six</option>
                              </Field>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end me-0 me-md-4 me-xxl-5">
                          <button
                            type="button"
                            className="btn cancel-btn mb-3 mb-md-0 me-4"
                          >
                            PREVIOUS
                          </button>
                          <button
                            type="submit"
                            className="btn next-btn mb-3 mb-md-0"
                            // onClick={nextstep}
                          >
                            NEXT
                          </button>
                        </div>
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Originator;

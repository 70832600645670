import { Link } from "react-router-dom";

function MasterDataManagement() {
  return (
    <>
      <div className="system-administration py-5">
        <div className="container-fluid">
          <div className="row justify-content-start">
            <div className="col-12">
              <h3>Master Data Management</h3>
            </div>
            <div className="col mb-4 system-administration-box">
              <div className="">
                <Link to="/controlpanel/getissuers">
                  <img src="../icons/fsps.png" className="icons" alt="icons" />
                  <h5>Issuers</h5>
                </Link>
              </div>
            </div>
            <div className="col mb-4  system-administration-box">
              <div className="">
                <Link to="/controlpanel/gettokens">
                  <img
                    src="../icons/currencies.png"
                    className="icons"
                    alt="icons"
                  />
                  <h5>Currencies</h5>
                </Link>
              </div>
            </div>
            <div className="col mb-4  system-administration-box">
              <div className="">
                <Link to="/controlpanel/getledgers">
                  <img
                    src="../icons/ledgers.png"
                    className="icons"
                    alt="icons"
                  />
                  <h5>Ledgers</h5>
                </Link>
              </div>
            </div>
            <div className="col mb-4 system-administration-box">
              <div className="">
                <Link to="/controlpanel/getbranches">
                  <img
                    src="../icons/branches.png"
                    className="icons"
                    alt="icons"
                  />
                  <h5>Branches</h5>
                </Link>
              </div>
            </div>
            <div className="col mb-4   system-administration-box">
              <div className="">
                <Link to="/controlpanel/getagents">
                  <img
                    src="../icons/agents.png"
                    className="icons"
                    alt="icons"
                  />
                  <h5>Agents</h5>
                </Link>
              </div>
            </div>
            <div className="col mb-4 system-administration-box">
              <div className="">
                <Link to="/controlpanel/getpeople">
                  <img
                    src="../icons/people-wallets.png"
                    className="icons"
                    alt="icons"
                  />
                  <h5>People</h5>
                </Link>
              </div>
            </div>
            <div className="col mb-4 system-administration-box">
              <div className="">
                <Link to="/controlpanel/getentities">
                  <img
                    src="../icons/merchant-wallets.png"
                    className="icons"
                    alt="icons"
                  />
                  <h5>Merchant</h5>
                </Link>
              </div>
            </div>
            <div className="col mb-4 system-administration-box">
              <div className="">
                <Link to="/controlpanel/getwallets">
                  <img
                    src="../icons/bank-wallets.png"
                    className="icons"
                    alt="icons"
                  />
                  <h5>Bank Wallets</h5>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MasterDataManagement;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setPrivateId, setResponseAPI, setResult } from "../../../../action";
import { CreateAgentApi } from "../../../../API/Agents";
import { decrypytdata } from "../../../../API/walletkey";
import { success } from "../../../../Components/alert";
// import PrivateModal from "../../../../Components/PrivateModal";

function Review({ AgentData, previous }) {
  /* console.log(
    "🚀 ~ file: ReviewAgents.js ~ line 11 ~ ReviewBranch ~ AgentData",
    AgentData
  ); */
  let { pathname } = useLocation();
  // const [privateModal, setPrivateModal] = useState(false);
  const publicKeys = useSelector((state) => state.keyReducer);
  let APPID = "";
  let APIKEY = "";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const closeModal = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      const encryptedIssuer = await CreateAgentApi(APPID, APIKEY, AgentData);
      const devryptedIssuer = await decrypytdata(encryptedIssuer.data, APIKEY);
      // const jsonData = JSON.parse(devryptedIssuer);
      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        // dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      }
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);
      }
      // dispatch(setPrivateId(""));
    }
    // setPrivateModal(false);
  };

  return (
    <>
      {/* {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
      )} */}
        <div className="container-fluid p-0 ">
          <div className="row ms-lg-4 ms-xl-5">
            <div className="col-12">
              <div className="main-heading">
                <h3>Agents</h3>
                <h6>
                  {pathname === "/controlpanel/modifyagent"
                    ? "Modify Agents"
                    : "Create Agents"}
                </h6>
                <p>
                  This function associates a financial or bank officer with a
                  branch.
                </p>
              </div>
              <div className="review-wallet-box m-0">
                <div className="row">
                  <div className="col-xxl-11">
                    <div className="review-wallet-info-box mb-5">
                      <div className="row">
                        <div className="col-12">
                          <div className="heading">
                            <h3>Primary Contact Information</h3>
                            <i className="bi bi-pencil-square"></i>
                          </div>
                          <hr className="mb-4" />
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3">
                          <h6>Primary Contact</h6>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>First Name</h4>
                              <p>{AgentData.firstname}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Middle Name</h4>
                              <p>{AgentData.secondFirstname}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Last Name</h4>
                              <p>{AgentData.lastname}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Second Last Name</h4>
                              <p>{AgentData.secondLastname}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3">
                          <h6>Contact Information </h6>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Dialing Code</h4>
                              <p>{AgentData.mobileDialingcode}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Phone Number</h4>
                              <p>{AgentData.mobilePhone}</p>
                            </div>
                          </div>
                          {/* <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Extension</h4>
                              <p>{AgentData.firstname}</p>
                            </div>
                          </div> */}
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Email Address</h4>
                              <p>{AgentData.email}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xl-3">
                          <h6>Primary Contact Account Information</h6>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Job Title</h4>
                              <p>{AgentData.jobTitle}</p>
                            </div>
                          </div>
                          <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Username</h4>
                              <p>{AgentData.username}</p>
                            </div>
                          </div>
                          {/* <div className="wallet-list">
                            <i className="bi bi-check"></i>
                            <div>
                              <h4>Password</h4>
                              <p>Password</p>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="review-wallet-box-btn d-flex justify-content-center justify-content-md-end mt-5">
                      <button
                        type="button"
                        className="btn cancel-btn mb-3 mb-md-0 me-4"
                        onClick={() => {
                          previous();
                        }}
                      >
                        CANCEL
                      </button>
                      <button
                        type="button"
                        className="btn next-btn mb-3 mb-md-0"
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default Review;

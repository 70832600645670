import React, { useEffect, useState } from "react";
// import PrivateModal from "../../../Components/PrivateModal";
import { getToken } from "../../../API/Token";
import { useDispatch, useSelector } from "react-redux";
import { decrypytdata } from "../../../API/walletkey";
import { Tokenscolumns } from "../../../Components/GridHeaders";
import Grid from "../../../Components/Grid";
import { Link } from "react-router-dom";
import { showLoader } from "../../../action";

function TokensBalances() {
  const [privateModal, setPrivateModal] = useState(false);
  const [rows, setRows] = React.useState([]);
  const publicKeys = useSelector((state) => state.keyReducer);
  const dispatch = useDispatch()
  let APPID = "";
  let APIKEY = "";
  const closeModal = () => {
    setPrivateModal(false);
  };
  const fetchdata = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      const encryptedLedger = await getToken(APPID, APIKEY);
      dispatch(showLoader(false));
      const tokens = await decrypytdata(encryptedLedger.data, APIKEY);
      /* console.log("🚀 ~ file: TokensBalances.js ~ line 30 ~ fetchdata ~ tokens", tokens) */
      // const data = JSON.parse(JSON.parse(tokens).CommandResponse);
      // const data = JSON.parse(tokens);
      let data = JSON.parse(JSON.parse(tokens).CommandResponse);
      /* console.log("🚀 ~ file: TokensBalances.js ~ line 33 ~ fetchdata ~ data", data) */

      /* console.log('Tokens', data); */

      if (data) {
        let dataArr = [];
        data?.forEach((dataMain, index) => {
          // multiple data
          dataArr.push({ ...dataMain, rowid: index });
        });
        setRows(dataArr);
      }
    }
  };

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (!publicKeys?.privateKey?.PrivateWalletAddress) setPrivateModal(true);
    }
    fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys, privateModal]);
  return (
    <>
      {/* {privateModal && (
        <PrivateModal modalpriate={privateModal} closeModal={closeModal} />
      )} */}
        <div className="row ms-lg-4 ms-xl-5 m-0">
          <div className="col-12">
            <div className="main-heading">
              <h3> Tokens</h3>
              <h6>Get Tokens</h6>
              <p>
                {" "}
                This function lists all the tokens associated with a wallet.
              </p>
            </div>
            <div className="row">
              <div className="col-xxl-10">
                <div className="wallet-balances-box">
                  <div className="d-md-flex justify-content-between">
                    <div className="search-box d-md-flex">
                      <div className="input-group mb-3"></div>
                    </div>
                    <Link to={`/controlpanel/createtoken`}>
                      <button
                        type="button"
                        className="btn btn-primary "
                        style={{ marginBottom: "10px" }}
                      >
                        + ADD Tokens
                      </button>
                    </Link>
                  </div>
                  <div style={{ height: 800, width: "100%" }}>
                    <Grid row={rows} coloums={Tokenscolumns} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default TokensBalances;

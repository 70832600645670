/* import { decrypytdata as decryptData } from '../API/walletkey'
import { DecryptionString as decryptionString } from '../Constants/Config'

const getKeys = () => {
  let decryptedData = null
  const loginData = JSON.parse(localStorage.getItem('UserLogIn'))

  if (loginData) {
    decryptedData = decryptData(loginData, decryptionString)
  }

  console.log(JSON.parse(decryptedData));
  
  const { PrivateKey, PublicAddress } = JSON.parse(decryptedData).CommandResponse[0]

  return { PrivateKey, PublicAddress }
}

const keys = getKeys()
*/
let publicKey;
let privateKey;
let userType;

const userDataFromStorage = sessionStorage.getItem("UserLogIn");

if (userDataFromStorage) {
  const { PublicAddress, PrivateKey, UserRoleKeycode } =
    JSON.parse(userDataFromStorage);

  publicKey = { publicAddress: PublicAddress };
  privateKey = { PrivateWalletAddress: PrivateKey };
  userType = UserRoleKeycode;
}

let intialState = {
  publicKey: publicKey || '',
  privateKey: privateKey || ''
};

const keyReducer = (state = intialState, action) => {
  switch (action.type) {
    case "SET_PUBLIC_ID":
      return { ...state, publicKey: action.payload };
    case "SET_PRIVATE_ID":
      return { ...state, privateKey: action.payload };
    case "SET_MENU":
      return { ...state, menu: action.payload };

    default:
      return state;
  }
};

export default keyReducer;

const value = {
  data: "",
};
export const resultReducer = (state = value, action) => {
  switch (action.type) {
    case "SET_RESULT_DATA":
      return { ...state, data: action.payload };

    default:
      return state;
  }
};

const values = {
  data: "",
  loading: false,

};
export const EncryptDataReducer = (state = values, action) => {
  switch (action.type) {
    case "SET_RESPONSE_DATA":
      return { ...state, data: action.payload };

    default:
      return state;
  }
};

export const ShowLoader = (state = values, action) => {
  switch (action.type) {
    case "SHOW_LOADER":
      return { ...state, loading: action.payload };

    default:
      return state;
  }
};

export const userTypeReducer = (state = userType || null, action) => {
  switch (action.type) {
    case 'SET_USER_TYPE':
      return action.payload
    default:
      return state
  }
}

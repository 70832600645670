import Box from "@mui/material/Box";
import { DataGrid, GridLinkOperator } from "@mui/x-data-grid";
import GridStyle from "./GridStyle";
import QuickSearchToolbar from "./Searchbar";

function Grid({ row, coloums }) {
  return (
    <>
      <Box
        sx={{
          height: 400,
          width: 1,
          "& .odd": {
            bgcolor: "#f9f8f8",
          },
        }}
      >
        <DataGrid
          sx={{
            "& .MuiDataGrid-virtualScroller": {
              overflowX: "visible !important",
            },
            "& .MuiDataGrid-cell:last-child": {
              overflow: "visible !important",
              maxWidth: "500px !important",
            },
            "& .MuiDataGrid-columnHeader:last-child": {
              width: "500px !important",
              maxWidth: "438px !important",
            },
          }}
          columns={coloums}
          rows={row}
          getRowId={(row) => row.rowid}
          initialState={{
            filter: {
              filterModel: {
                items: [],
                quickFilterLogicOperator: GridLinkOperator.Or,
              },
            },
          }}
          components={{
            Toolbar: QuickSearchToolbar,
            Row: GridStyle,
          }}
        />
      </Box>
    </>
  );
}

export default Grid;

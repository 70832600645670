import { WEB_WALLET_URL } from "../Constants/Config";
import http from "./httpService.js";
import { hmacencrypt } from "./walletkey";

export async function getBranches(publickey, privateKey) {
  let values = {
    sourcePublicAddress: publickey,
  };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/issuer/GetBranches",
    values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/issuer/GetBranches",
    { sourcePublicAddress: publickey },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function suspendBranch(publickey, privateKey, data) {
  // let values = {
  //   sourcePublicAddress: publickey,
  // };
  // const values = {
  //   sourcePublicAddress: publickey,
  //   issuercode: data,
  // };
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/issuer/SuspendBranch",
    data,
    publickey,
    privateKey
  );

  const response = await http.post("/issuer/SuspendBranch", data, {
    headers: {
      Authorization: token,
    },
  });
  return response;
}

export async function insertBranch(publickey, privateKey, Issuerdata) {
  // debugger;
  // let values = {
  //   ...Issuerdata,
  //   publicWalletAddress: publickey,
  //   privateKey: privateKey,
  //   // addressLine3: null,
  //   // neighborhood: null,
  //   groupID: 12,
  //   issuerID: 0,
  //   walletID: 0,
  //   // parentIssuercode: "",
  //   // issuerTypecode: "",
  //   // citycode: "",
  //   // userRoleKeycode: "",
  // };
  // let values = {
  //   sourcePublicAddress: publickey,
  //   Issuerdata,

  // };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/issuer/CreateBranch",

    Issuerdata,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/issuer/CreateBranch",
    // {
    //   ...values,
    //   publicWalletAddress: publickey,
    //   privateKey: privateKey,
    //   addressLine3: null,
    //   neighborhood: null,
    //   groupID: 0,
    //   issuerID: 0,
    //   walletID: 0,
    //   parentIssuercode: "",
    //   issuerTypecode: "",
    //   citycode: "",
    //   userRoleKeycode: "",
    // },
    Issuerdata,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

export async function modifyBranch(publickey, privateKey, Issuerdata) {
  /* console.log(
    "🚀 ~ file: branches.js ~ line 109 ~ modifyBranch ~ Issuerdata",
    Issuerdata
  ); */
  // debugger;
  // let values = {

  // };
  // let values = {
  //   sourcePublicAddress: publickey,
  //   Issuerdata,

  // };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/issuer/ModifyBranch",

    Issuerdata,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/issuer/ModifyBranch",

    Issuerdata,
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}
export async function importBranchBatch(publickey, privateKey, issuerData) {
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/issuer/ImportBranchBatch",
    issuerData,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/issuer/ImportBranchBatch",
    issuerData,

    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// import Header from "../../../Components/Header";
// import Sidebar from "../../../Components/Sidebar";
import { issuresSuspend } from "../../../API/Issures";
import { decrypytdata } from "../../../API/walletkey";
import { success } from "../../../Components/alert";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { setPrivateId, setResponseAPI, setResult, showLoader } from "../../../action";

import Header from '../../../Views/admin/SysAdmin/Header'
import SuspendIssuerConfirm from '../../../Components/SuspendConfirm'

function IssuresSuspend() {
  const publicKeys = useSelector((state) => state.keyReducer);

  const [isValidPublicAddress, setisValidPublicAddress] = useState(false); // const [privateModal, setPrivateModal] = useState(false);
  const [data, setdata] = useState("");
  const location = useLocation();
  const [popUp, setPopUp] = useState(false);

  const initialValues = {
    issuercode: location.search.replace("?id=", ""),
    sourcePublicAddress: ''
  };

  let APPID = "";
  let APIKEY = "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const suspendData = async () => {
    if (publicKeys.publicKey) {
      APPID = publicKeys.publicKey.publicAddress;
    }
    if (publicKeys.privateKey) {
      APIKEY = publicKeys.privateKey.PrivateWalletAddress;
    }
    if (APPID && APIKEY) {
      dispatch(showLoader(true));
      // debugger;
      const encryptedIssuer = await issuresSuspend(APPID, APIKEY, data);
      const devryptedIssuer = await decrypytdata(encryptedIssuer?.data, APIKEY);

      let jsonData;
      if (devryptedIssuer) {
        jsonData = JSON.parse(devryptedIssuer);
        dispatch(setPrivateId(""));
        dispatch(setResult(jsonData));
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      } else {
        dispatch(setPrivateId(""));
        // dispatch(setResult(jsonData))
        dispatch(setResponseAPI(encryptedIssuer));
        navigate("/result");
      }
      if (jsonData.returnMessage) {
        success(jsonData.returnMessage);

        // console.log("🚀 ~ file: Review.js ~ line 33 ~ closeModal ~ jsonData", jsonData)
      }
      // debugger;
    }
    dispatch(showLoader(false));
  };

  useEffect(() => {
    if (publicKeys?.publicKey?.publicAddress) {
      if (publicKeys?.privateKey?.PrivateWalletAddress) {
        setisValidPublicAddress(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKeys]);
  return (
    <>
          {isValidPublicAddress && (
            <div className="col-md-9 col-lg-9 col-xxl-10">
            <div className="row ms-lg-4 ms-xl-5">
              <div className="col-12">
                  <div className="main-heading">
                    <h3>Issuer</h3>
                    <h6>Suspend Issuer</h6>
                    <p>
                      This function suspends an issuer's access to the Unicoin
                      network.
                    </p>
                  </div>
                  <div className="wallet-information-box m-0">
                    <div className="suspend-text wallet-information-form">
                      <Formik
                        initialValues={initialValues}
                        // validationSchema={validationSchema}
                        onSubmit={(values) => {
                          // console.log("values", values);
                          // nextstep();
                          // debugger;
                          // CreateIssuersApi(values);
                          setdata(values);
                          // suspendData();
                        }}
                      >
                        {(formik) => {
                          const { errors, touched } = formik;
                          return (
                            <div className="">
                              <h4>Issuer Information</h4>

                              <Form className="wallet-information-form">
                                <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                  {/* <label>Issuer Code</label> */}
                                  <div className="input-box form-floating mb-5">
                                    <Field
                                      type="text"
                                      name="issuercode"
                                      id="issuercode"
                                      placeholder="Issuer Code"
                                      className="form-control"
                                    />
                                    <label className="ps-0 pb-0">
                                      Issuer Code
                                    </label>
                                    <div
                                      className={
                                        errors.issuercode && touched.issuercode
                                          ? "input-error"
                                          : null
                                      }
                                    >
                                      <ErrorMessage
                                        name="issuercode"
                                        component="span"
                                        className="error"
                                        style={{ color: "red" }}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                  {/* <label>Issuer Code</label> */}
                                  <div className="input-box form-floating mb-5">
                                    <Field
                                      type="text"
                                      name="sourcePublicAddress"
                                      id="sourcePublicAddress"
                                      placeholder="Public Wallet Address"
                                      className="form-control"
                                    />
                                    <label className="ps-0 pb-0">
                                      Public Wallet Address
                                    </label>
                                    <div
                                      className={
                                        errors.sourcePublicAddress && touched.sourcePublicAddress
                                          ? "input-error"
                                          : null
                                      }
                                    >
                                      <ErrorMessage
                                        name="sourcePublicAddress"
                                        component="span"
                                        className="error"
                                        style={{ color: "red" }}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex me-0 me-md-4 me-xxl-5">
                                  <button
                                    type="button"
                                    className="btn cancel-btn mb-3 mb-md-0 me-4"
                                  >
                                    CANCEL
                                  </button>
                                  <button
                                    onClick={() => {
                                      // suspendData();
                                      setPopUp(true);
                                    }}
                                    type="submit"
                                    className="btn next-btn mb-3 mb-md-0"
                                  >
                                    SUSPEND
                                  </button>
                                </div>
                                {/* {popUp && (
                                  <TokenConfirm
                                    heading={"Suspend Issuer"}
                                    body={data}
                                    suspendData={suspendData}
                                    setPopUp={setPopUp}
                                  />
                                )} */}
                              </Form>
                            </div>
                          );
                        }}
                      </Formik>
                      {/* <div className="row">
                                    <div className="col-sm-6 col-xl-4 col-xxl-3 mb-4 mb-xl-0">
                                        <div className="mb-5">
                                            <div className="input-box">
                                                <input type="text" placeholder="Public Wallet Address" className="form-control" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex me-0 me-md-4 me-xxl-5">
                                    <button type="button" className="btn cancel-btn mb-3 mb-md-0 me-4">CANCEL</button>
                                    <button onClick={() => { suspendData(); setPrivateModal(true); }} type="submit" className="btn next-btn mb-3 mb-md-0">SUSPEND</button>
                                </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

      {popUp && (
        <SuspendIssuerConfirm
          heading={"Suspend Issuer"}
          body={{ uniqueCode: data.issuercode, sourcePublicAddress: data.sourcePublicAddress }}
          suspendData={suspendData}
          setPopUp={setPopUp}
        />
      )}
    </>
  );
}

export default IssuresSuspend;

import React from "react";
import { useSelector } from "react-redux";
import Header from '../Views/admin/SysAdmin/Header'
import Footer from '../Views/admin/SysAdmin/Footer'
import Failure from "./Failure";
import Success from "./Success";

function Result() {
  const select = useSelector((state) => state);
  /* console.log("🚀 ~ file: Result.js ~ line 7 ~ Result ~ select", select) */

  // const temp = () => {
  //   console.log(select.resultReducer, "result");
  // };
  // useEffect(() => {
  //   temp();
  //   // eslint-disable-next-line
  // }, [select]);

  /* console.log("hello", select) */



  return (
    <>
      <Header />

      <div className="mt-3 container-fluid">
        <div className="row">
          {/* <div className="col-md-3 col-lg-3 col-xxl-2 p-0">
            <Sidebar />
          </div> */}
          {select.EncryptDataReducer.data.status === 200 && (
            <Success resultDatas={select} />
          )}
          {(select.EncryptDataReducer.data.status !== 200) && (
            <Failure resultDatas={select} />
          )}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Result;

import React from "react";
//import { Link } from "react-router-dom";
import { Link } from "react-router-dom";
function MoneyServices() {
  return (
    <>
      <div className="system-administration py-5">
        <div className="container-fluid">
          <div className="row justify-content-start">
            <div className="col-12">
              <h3>Money Services</h3>
            </div>
            <div className="col mb-4  system-administration-box">
              <Link to="/money-transfer-demo">
                <div className="">
                  <div>
                    <img
                      src="../icons/money-transfer.png"
                      className="icons"
                      alt="icons"
                    />
                    <h5>Money Transfer</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col mb-4  system-administration-box">
              <div className="">
                <a href="/">
                  <img src="../icons/mint.png" className="icons" alt="icons" />
                  <h5>Mint CBDC</h5>
                </a>
              </div>
            </div>
            <div className="col mb-4 system-administration-box">
              <div className="">
                <a href="/">
                  <img src="../icons/burn.png" className="icons" alt="icons" />
                  <h5>Burn CBDC</h5>
                </a>
              </div>
            </div>

            <div className="col mb-4 system-administration-box">
              <div className="">
                <Link to="/controlpanel/deposit">
                  <img src="../icons/deposit-3.png" className="icons" alt="icons" />
                  <h5>Deposit</h5>
                </Link>
              </div>
            </div>

            <div className="col mb-4 system-administration-box">
              <div className="">
                <Link to="/controlpanel/withdraw">
                  <img src="../icons/withdraw-3.png" className="icons" alt="icons" />
                  <h5>Withdraw</h5>
                </Link>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default MoneyServices;

export const setPublicId = (publickey) => {
  return {
    type: "SET_PUBLIC_ID",
    payload: publickey,
  };
};

export const setPrivateId = (privatekey) => {
  return {
    type: "SET_PRIVATE_ID",
    payload: privatekey,
  };
};

export const setMenus = (payload) => {
  return {
    type: "SET_MENU",
    payload: payload,
  };
};

export const setResult = (payload) => {
  // console.log(payload,"dhfjas");
  return {
    type: "SET_RESULT_DATA",
    payload: payload,
  };
};

export const setUserType = (payload) => ({ type: 'SET_USER_TYPE', payload })

export const setResponseAPI = (payload) => {
/*   console.log("🚀 ~ file: index.js ~ line 31 ~ setResponseAPI ~ payload", payload) */
  // console.log(payload,"payload data actions");
  return {
    type: "SET_RESPONSE_DATA",
    payload: payload,
  };
};

// export const setErrorAction = (payload) => {
//   return {
//     type: "SET_ERROR_DATA",
//     payload: payload,
//   };
// };

export const showLoader = (payload) => {
  return {
    type: "SHOW_LOADER",
    payload: payload,
  };
};
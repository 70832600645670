import { WEB_WALLET_URL } from "../Constants/Config.js";
import http from "./httpService.js";
import { hmacencrypt } from "./walletkey";

export async function getPeople(publickey, privateKey) {
  let values = {
    sourcePublicAddress: publickey,
  };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/issuer/GetIssuers",
    values,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/issuer/GetIssuers",
    { sourcePublicAddress: publickey },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}
export async function CreatePeople(publickey, privateKey, People) {
  // debugger;
  // let values = {
  //   sourcePublicAddress: publickey,
  //   People,
  // };

  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/kyc/CreatePeople",
    People,
    publickey,
    privateKey
  );
  const response = await http.post("/kyc/CreatePeople", People, {
    headers: {
      Authorization: token,
    },
  });
  return response;
}

export async function suspendPeople(publickey, privateKey) {}
export async function ImportPeopleBatch(publickey, privateKey, issuerData) {
  const token = await hmacencrypt(
    "POST",
    WEB_WALLET_URL + "/kyc/ImportPeopleBatch",
    issuerData,
    publickey,
    privateKey
  );
  const response = await http.post(
    "/kyc/ImportPeopleBatch",
    issuerData,

    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
}
